<template>
    <v-container style="padding: .5px!important;" fluid class="py-0" :class="miniVariant == true ? 'pl-9' : ''">
        <v-row>
            <LoadingMe
            :isLoading="showLoadingTxn"
            style="padding: 0"
            :fullPage="false"
            :myLoading="true" />
            <!-- Check out-->
            <!-- Cash -->
            <v-dialog
                v-model="dialogCash"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template>
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogCash = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("cash_receipt") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogCash = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">  
                            <v-col sm="12" cols="12" class="py-0">
                                <PrintReceipt :printObj="printObj" />
                            </v-col>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="12" class="px-0">

                                            <v-btn color="primary" class="float-right white--text text-capitalize"
                                                @click="saveTxn" style="width: 100%;">
                                                {{ $t('pay') }}
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!--Card-->
            <v-dialog
                v-model="dialogCard"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="cancel()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("card") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="cancel()"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">  
                            <v-row>    
                                <v-col sm="12" cols="12" class="py-0">
                                    <v-col sm="12" cols="12" class=" function_content pa-3 pb-0">
                                        <v-row>
                                            <v-col sm="6" cols="6" class="pt-0 text-center">
                                                <v-card
                                                    class="mx-auto"
                                                    max-width="465"
                                                    outlined
                                                    @click="cardPayment('masterCard')"
                                                >
                                                    <v-list-item three-line>
                                                        <v-list-item-content class="pk-3">
                                                            <v-row>
                                                                <v-col sm="12" cols="12" class="">
                                                                    <img
                                                                        class="img-1"
                                                                        src="@/assets/images/bank/mastercard.png"
                                                                        width="80%"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </v-col>
                                            <v-col sm="6" cols="6" class="pt-0 text-center">
                                                <v-card
                                                    class="mx-auto"
                                                    max-width="465"
                                                    outlined
                                                    @click="cardPayment('visaCard')"
                                                >
                                                    <v-list-item three-line>
                                                        <v-list-item-content class="pk-3">
                                                            <v-row>
                                                                <v-col sm="12" cols="12" class="">
                                                                    <img
                                                                        class="img-1"
                                                                        src="@/assets/images/bank/visa.png"
                                                                        width="80%"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </v-col>
                                            <v-col sm="6" cols="6" class="pt-0 text-center">
                                                <v-card
                                                    class="mx-auto"
                                                    max-width="465"
                                                    outlined
                                                    @click="cardPayment('unionPay')"
                                                >
                                                    <v-list-item three-line>
                                                        <v-list-item-content class="pk-3">
                                                            <v-row>
                                                                <v-col sm="12" cols="12" class="">
                                                                    <img
                                                                        class="img-1"
                                                                        src="@/assets/images/bank/union-pay.png"
                                                                        width="80%"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </v-col>
                                            <v-col sm="6" cols="6" class="pt-0 text-center">
                                                <v-card
                                                    class="mx-auto"
                                                    max-width="465"
                                                    outlined
                                                    @click="cardPayment('dinersClub')"
                                                >
                                                    <v-list-item three-line>
                                                        <v-list-item-content class="pk-3">
                                                            <v-row>
                                                                <v-col sm="12" cols="12" class="">
                                                                    <img
                                                                        class="img-1"
                                                                        src="@/assets/images/bank/diners-club.png"
                                                                        width="80%"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </v-col>
                                            
                                            
                                        </v-row>
                                    </v-col>
                                </v-col>
                                <v-col sm="12" cols="12" class="py-0">
                                    <PrintReceipt :printObj="printObj" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- cardPayment -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogCardPayment"
                        persistent
                        max-width="350px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("card_payment") }}</v-card-title>
                                <v-icon
                                    @click="dialogCardPayment = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col  sm="12" cols="12" class="pb-0">
                                        <v-select
                                        :items="banks"
                                        :label="$t('select_banks')"
                                        outlined
                                        v-model="cardPay.name"
                                        append-icon="mdi-account"
                                        item-value="uuid"
                                        item-text="name"
                                        required
                                        ></v-select>
                                    </v-col>
                                    <v-col  sm="6" cols="6" class="pt-1 pr-0">
                                        <label class="label">{{ $t("expire_date") }}</label>
                                        <app-datepicker
                                            :initialDate="cardPay.expireDate"
                                            @emitDate="cardPay.expireDate = $event"/>
                                    </v-col>
                                    <v-col  sm="6" cols="6" class="pt-1">
                                        <label class="label">{{ $t("last_4_digit") }}</label>
                                        <v-text-field
                                            required
                                            outlined
                                            v-model="cardPay.digit"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                    
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="secondary" class="float-right white--text text-capitalize"
                                        @click="enterCardPay">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Bank -->
            <v-dialog
                v-model="dialogBank"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="cancel()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("bank") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="cancel()"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                        <v-card color="white" class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">  
                               
                                    <v-col sm="12" cols="12" class=" function_content pa-3 pa-0">
                                        <v-row  style="width: 100%;">
                                            <v-col v-for="b in bankWallets" v-bind:key="b.uid" sm="6" cols="6">
                                                <v-card outlined dense class="px-3 no_border" color="white"  @click="selectBank(b)">

                                                    {{b.bank.name}}
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="12" class="px-0" >
                                            <v-btn color="primary" class="float-right white--text text-capitalize"
                                                @click="dialogBank = false" style="width: 100%;">
                                                {{ $t('pay') }}
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- bank-info -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="infoBank"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("bank_info") }}</v-card-title>
                                <v-icon
                                    @click="infoBank = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color=""
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col cols="12"  md="12" class="pt-0">
                                        <v-row>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14">
                                                                            {{ $t("bank_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{activePayBank.bankName}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14">
                                                                            {{ $t("account_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{activePayBank.accountName}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14">
                                                                        {{ $t("account_num") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{activePayBank.accountNumber}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14">
                                                                        {{ $t("reference_num") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right px-0 pt-2">
                                                                        <v-text-field
                                                                            required
                                                                            outlined
                                                                            v-model="activePayBank.refNum"
                                                                            type="number"
                                                                            ></v-text-field>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="infoBank = false">
                                        {{ $t('skip') }}
                                    </v-btn>

                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterBank">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!--KHQR-->
            <v-dialog
                v-model="dialogQrcode"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="cancel()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("KHQR") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="cancel()"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                            
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">           
                            <v-col sm="12" cols="12" class="pt-0 d-flex">
                                
                                <v-card
                                    class="ma-auto text-center"
                                    max-width="400"
                                    outlined
                                >
                                    <v-list-item three-line>
                                        <v-list-item-content class="pk-3">
                                            <v-row>
                                                <h2 class="mt-4 font_14" style="width: 100%;">scan QR code to pay</h2>
                                                <v-col sm="12" cols="12" class="">
                                                    <img
                                                        class="img-1"
                                                        src="@/assets/images/bank/qr-code.png"
                                                        width="60%"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col sm="12" cols="12" class="py-0">
                                <PrintReceipt :printObj="printObj" />
                            </v-col>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Print Invoice -->
            <v-dialog
                v-model="dialogPrint"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("cash_receipt") }}</v-card-title>
                                <v-icon
                                    @click="dialogPrint = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="7" class="border_right_lightgrey">
                                        </v-col>
                                        <v-col cols="5">
                                            <v-btn color="secondary" class="float-right white--text text-capitalize"
                                                @click="printHtml">
                                                {{ $t('print') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Serving Dialog-->
            <v-dialog
                v-model="dialogServingLine"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogServingLine = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("serving") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogServingLine = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <v-row>
                                            <v-col v-for="s in servingLines" v-bind:key="s.pk" sm="12" cols="12" class="">
                                                <div class="d-flex flex-column" style="height: 100%;border: 1px solid #ccc;border-radius: 5px;">
                                                    <v-simple-table fixed-header style="height: 100%;"  class="flex-1" >
                                                        <template v-slot:default>
                                                            <v-card
                                                                class="mx-auto"
                                                                max-width="400"
                                                            >
                                                                <v-list-item two-line>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle style="background: #ccc;padding: 5px;text-align: center;font-weight: bold;border-radius: 5px;">{{s.saleUnit}}</v-list-item-subtitle>
                                                                        
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-card-text>
                                                                    <div>
                                                                        {{s.hour}}
                                                                    </div>
                                                                </v-card-text>
                                                                <v-card-text>
                                                                <v-row align="center">
                                                                    <v-col
                                                                    class="text-h2"
                                                                    cols="12"
                                                                    >
                                                                    {{s.total}} {{baseRate.symbol}}
                                                                    </v-col>
                                                                </v-row>
                                                                </v-card-text>
                                                                <v-divider></v-divider>
                                                                <v-card-text>
                                                                    <v-chip-group
                                                                        active-class="deep-purple accent-4 white--text"
                                                                        column
                                                                    >
                                                                        <v-chip @click="serveCheckOut(s)">
                                                                            {{ $t('check_out') }}
                                                                        </v-chip>
                                                                        <v-chip>
                                                                            {{ $t('print_bill') }}
                                                                        </v-chip>
                                                                        <v-chip>
                                                                            {{ $t('split_item') }}
                                                                        </v-chip>
                                                                        <v-chip>
                                                                            {{ $t('merge_item') }}
                                                                        </v-chip>
                                                                    </v-chip-group>
                                                                </v-card-text>
                                                                
                                                            </v-card>
                                                        </template>
                                                    </v-simple-table>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Pre Order-->
            <v-dialog
                v-model="dialogPreOrder"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogPreOrder = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("pre_order") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogPreOrder = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="py-0">
                                    <v-row   >
                                        <PreOrder :preorders="preorders" />
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Invoice -->
            <v-dialog
                v-model="dialogInvoice"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                             @click="dialogInvoice = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("invoice") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                             @click="dialogInvoice = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <v-row   >
                                            <!-- <Invoice :invoices="invoices" /> -->
                                            <v-row>
                                                <v-text-field
                                                v-model="invSearchText"
                                                outlined
                                                :placeholder="$t('Name or Phone...')"
                                                clearable
                                                />
                                                <v-btn color="primary" icon @click="searchInv" class="btn_search ml-2" style="width: 5%;">
                                                    <v-icon style="font-size: 32px;color: #fff;">search</v-icon>
                                                </v-btn>
                                            </v-row>
                                            <v-row >
                                                <kendo-datasource
                                                    ref="gridInvoice"
                                                    :type="'JSON'"
                                                    :data="invoiceLines"
                                                    :server-paging="false"/>
                                                <kendo-grid
                                                    id="gridInvoice" class="grid-function"
                                                    :data-source-ref="'gridInvoice'"
                                                    :editable="false"
                                                    :groupable="false"
                                                    :column-menu="true"
                                                    :noRecords="true"
                                                    :sortable="true"
                                                    :excel-filterable="true"
                                                    :excel-all-pages="true"
                                                    :scrollable-virtual="true">
                                                    <kendo-grid-column
                                                        :title="$t('no.')"
                                                        :width="40"
                                                        :template="invRowNumber"
                                                        :column-menu="false"
                                                        :headerAttributes="{style: 'background-color: #EDF1F5;',class: 'text-products px-1' }"
                                                        :attributes="{ style: 'text-align: products'}"
                                                    />
                                                    <kendo-grid-column
                                                        :field="'customer'"
                                                        :title="$t('name')"
                                                        :width="150"
                                                        :template="'#= customer.name#'"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'phoneNumber'"
                                                        :title="$t('phone')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'txnNumber'"
                                                        :title="$t('number')"
                                                        :width="150"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'issuedDate'"
                                                        :title="$t('issued_date')"
                                                        :width="150"
                                                        :template="'#=kendo.toString(new Date(issuedDate), `dd-MM-yyyy`)#'"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'total'"
                                                        :title="$t('amount')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :command="{
                                                            text: cashReceiptText,
                                                            click: cashReceipt,
                                                            className: 'btn-plus isEditable',
                                                        }"
                                                        :title="$t('action')"
                                                        :width="150"
                                                        :headerAttributes="{
                                                        style: 'text-align: left; background-color: #EDF1F5',
                                                        }"
                                                    />
                                                </kendo-grid>
                                            </v-row>
                                        </v-row>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Order Txn-->
            <v-dialog
                v-model="dialogOrderTxn"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("orders") }}</v-card-title>
                                <v-icon
                                    @click="dialogOrderTxn = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <v-row   >
                                            <!-- <Invoice :invoices="invoices" /> -->
                                            <v-row>
                                                <v-text-field
                                                    v-model="orderSearchText"
                                                    outlined
                                                    :placeholder="$t('Name or Phone...')"
                                                    clearable
                                                />
                                                <v-btn icon @click="searchInv">
                                                    <v-icon>search</v-icon>
                                                </v-btn>
                                            </v-row>
                                            <v-row >
                                                <kendo-datasource
                                                    ref="gridOrderTxn"
                                                    :type="'JSON'"
                                                    :data="orderLines"
                                                    :server-paging="false"/>
                                                <kendo-grid
                                                    id="gridOrderTxn" class="grid-function"
                                                    :data-source-ref="'gridOrderTxn'"
                                                    :editable="false"
                                                    :groupable="false"
                                                    :column-menu="true"
                                                    :noRecords="true"
                                                    :sortable="true"
                                                    :excel-filterable="true"
                                                    :excel-all-pages="true"
                                                    :scrollable-virtual="true">
                                                    <kendo-grid-column
                                                        :title="$t('no.')"
                                                        :width="40"
                                                        :template="ordRowNumber"
                                                        :column-menu="false"
                                                        :headerAttributes="{style: 'background-color: #EDF1F5;',class: 'text-products px-1' }"
                                                        :attributes="{ style: 'text-align: products'}"
                                                    />
                                                    <kendo-grid-column
                                                        :field="'customer'"
                                                        :title="$t('name')"
                                                        :width="150"
                                                        :template="'#= customer.name#'"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'phoneNumber'"
                                                        :title="$t('phone')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'txnNumber'"
                                                        :title="$t('number')"
                                                        :width="150"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'issuedDate'"
                                                        :title="$t('issued_date')"
                                                        :width="150"
                                                        :template="'#=kendo.toString(new Date(issuedDate), `dd-MM-yyyy`)#'"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'total'"
                                                        :title="$t('amount')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :command="{
                                                            text: cashReceiptText,
                                                            click: cashReceipt,
                                                            className: 'btn-plus isEditable',
                                                        }"
                                                        :title="$t('action')"
                                                        :width="150"
                                                        :headerAttributes="{
                                                        style: 'text-align: left; background-color: #EDF1F5',
                                                        }"
                                                    />
                                                </kendo-grid>
                                            </v-row>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Check out Dialog -->
            <v-dialog
                v-model="dialogPayment"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card >
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="closePayment"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("cash_receipt") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="closePayment"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="">
                        <v-card class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pr-0 py-0 border_right_lightgrey">
                                    <v-row class="mx-1" style="height: 100%;">
                                        <!-- cash -->
                                        <v-col sm="6" cols="6" class="pa-2" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        :disabled="!allowPayCash"
                                                        @click="payByCash"
                                                        height="50%"
                                                        class="rounded-0 pay-btn pa-4" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-cash">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("cash") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- card -->
                                        <v-col sm="6" cols="6" class="pa-2" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        :disabled="!allowPayCard"
                                                        @click="payByCard"
                                                        height="50%"
                                                        class="rounded-0 pay-btn pa-4" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-loyalty">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- bank -->
                                        <v-col sm="6" cols="6" class="pa-2" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        :disabled="!allowPayBank"
                                                        @click="payByBank"
                                                        height="50%"
                                                        class="rounded-0 pay-btn pa-4" 
                                                        style="height:100% !important;">

                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-banking">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("bank") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- qrcode -->
                                        <v-col sm="6" cols="6" class="pa-2" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        :disabled="!allowPayKHQR"
                                                        @click="payByKHQR"
                                                        height="50%"
                                                        class="rounded-0 pay-btn pa-4" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-payment">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("KHQR") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- point card -->
                                        <v-col sm="6" cols="6" class="pa-2" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payPointCard"
                                                        height="50%"
                                                        class="rounded-0 pay-btn pa-4" 
                                                        style="height:100% !important;">

                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-banking">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("point_card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- store credit -->
                                        <v-col sm="6" cols="6" class="pa-2" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByKHQR"
                                                        height="50%"
                                                        class="rounded-0 pay-btn pa-4" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-payment">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("store_credit") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- <v-col sm="10" cols="10" class="py-0">
                                    <PrintReceipt :printObj="printObj" />
                                </v-col> -->
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Print Session-->
            <v-dialog
                v-model="dialogPrintSession"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogPrintSession = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("session") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogPrintSession = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pr-0 py-0 border_right_lightgrey">
                                    <v-row class="mx-1" style="height: 100%;">
                                        <PrintSession :session="session"/>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!--Loyalty-->
            <v-col md="6" sm="3" col="3" class="pa-1">
                <template>
                    <v-dialog
                        v-model="dialogLoyalSearch"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                    v-if="pointCardPay"
                                    @click="dialogLoyalSearch = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("loyalty_search") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                    dark
                                    text
                                    v-if="!pointCardPay"
                                    @click="skipLoyalty"
                                    >
                                    {{ $t('skip') }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="6" cols="6" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="lsearchBy('card')"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="text-center">
                                                                <h2  class="font_22">{{$t('search_by')}}</h2>
                                                                <h2  class="font_22">{{$t('card')}}</h2>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                        <v-col sm="6" cols="6" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="lsearchBy('phone')"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="text-center">
                                                                <h2 class="primary--text font_22">{{$t('search_by')}}</h2>
                                                                <h2 class="primary--text font_22">{{$t('phone')}}</h2>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <!-- <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="secondary" class="float-right white--text text-capitalize"
                                        v-if="!pointCardPay"
                                        @click="skipLoyalty">
                                        {{ $t('skip') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions> -->
                        </v-card>
                    </v-dialog>
                </template>
            </v-col>
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogRewardInfo"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("point_earn_info") }}</v-card-title>
                                <v-icon
                                    @click="dialogRewardInfo = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col cols="12"  md="12" class="pt-0">
                                        <v-row>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_owner_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.name}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.cardNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("phone_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.phoneNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("loyalty_program") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.loyaltyName}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("point_balance") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.totalPoint}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("point_earn") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.pointEarn}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="saveReward">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- loyalty search -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogLoyalty"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                    @click="dialogLoyalty = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("loyalty") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                    dark
                                    text
                                    @click="dialogLoyalty = false"
                                    >
                                    {{ $t('skip') }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="9" cols="9" class="pb-0">
                                            <v-text-field
                                            :label="lsearchName == 'card' ? $t('card_number') : $t('phone_number')"
                                            required
                                            outlined
                                            v-model="lcardNumber"
                                            append-icon="mdi-credit-card-settings-outline"
                                            type="text"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="3" cols="3" class="pb-0" >
                                            <v-btn color="primary" class="mr-2 white--text text-capitalize"
                                                @click="searchLoyaltyCard">
                                                {{ $t('search') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogRProgram"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("reward_program") }}</v-card-title>
                                <v-icon
                                    @click="dialogRProgram = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row >
                                    <v-col sm="4" cols="12" class="" v-for="d in activeRPrograms" v-bind:key="d.pk">
                                        <v-card
                                            class="mx-auto"
                                            max-width="465"
                                            outlined
                                            :color="activeReward.pk == d.pk ? 'primary': 'white' "
                                            :class="activeReward.pk == d.pk ? 'white--text': 'black--text' "
                                            @click="selectReward(d)"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{d.name}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="dialogRProgram = false">
                                        {{ $t('skip') }}
                                    </v-btn>
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterReward()">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- phone number-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dailogPhone"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("phone_number") }}</v-card-title>
                                <!-- <v-icon
                                    @click="dialogRProgram = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon> -->
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('phone_number')"
                                        required
                                        outlined
                                        v-model="phoneNumber"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="dialogRProgram = false">
                                        {{ $t('skip') }}
                                    </v-btn> -->
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterPhone()">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- promotion -->
            <v-col md="6" sm="3" col="3" class="pa-1">
                <template>
                    <v-dialog
                        v-model="dialogPromotion"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        </template>
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                     @click="cancelPromotion"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("promotion") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                    dark
                                    text
                                    @click="cancelPromotion"
                                    >
                                    {{ $t('skip') }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="3" cols="6" class="">
                                            <v-select class="mt-1 rounded-0 "
                                                    clearable
                                                    outlined
                                                    :label="$t('code')"
                                            />
                                        </v-col>
                                        <v-col sm="9" cols="6" class="">
                                            <v-text-field
                                                class="mt-1 rounded-0 font_18"
                                                outlined
                                                :label="$t('search')"
                                                append-icon="b-search"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-for="cam in promotions" v-bind:key="cam.id"   sm="6" cols="6" class="">
                                            <v-card
                                                class="mx-auto pa-3"
                                                max-width="500"
                                                outlined
                                                @click="applyPromotion(cam)"
                                            >
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    
                                                                    <td colspan="3" class="text-left  px-1" style="background: #eb0238;">
                                                                        <span class="niradei_heavy font_34 white--text ml-2">
                                                                            {{cam.name}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig  px-2">
                                                                        <span class="niradei_medium font_12">
                                                                            {{ $t("code") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig px-0 ">:</td>
                                                                    <td class="text-right  px-2">
                                                                        <span class="niradei_heavy font_12 dark_grey">
                                                                            {{cam.code}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig  px-2">
                                                                        <span class="niradei_medium font_14">
                                                                        {{ $t("expiry_date") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig px-0">:</td>
                                                                    <td class="text-right  px-2">
                                                                        <span class="niradei_heavy font_14 primary--text">
                                                                            {{cam.isEndDate ? cam.endDate : ''}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-card>
                                        </v-col>
                                        
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </template> 
            </v-col>
            <!-- partner -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogPartner"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("partner") }}</v-card-title>
                                <v-icon
                                    @click="dialogPartner = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <div v-for="p in partners" v-bind:key="p.pk" class="col-4 pa-0">
                                        <v-col sm="12" cols="12" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="selectPartner(p)"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="">
                                                                <img
                                                                    class="img-1"
                                                                    :src="p.img.default.thumb != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + p.img.default.thumb : ''"
                                                                    width="100%"
                                                                />
                                                                <h4 style="text-align: center;">{{p.name}}</h4>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-row>
                            </v-col>
                            <!-- <v-card-actions>
                                <div class="function_footer">
                                        <v-btn color="secondary" class="float-right white--text text-capitalize"
                                        @click="skipPartner">
                                        {{ $t('skip') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions> -->
                        </v-card>
                    </v-dialog>
                </v-row>
            </template> 
            <!-- partner ref number -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogPartnerRef"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("reference_number") }}</v-card-title>
                                <v-icon
                                    @click="dialogPartnerRef = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" style="background-color: antiquewhite;">
                                        <h2 class="font_14">{{$t('partner')}}</h2>
                                        <h2 class="font_18 primary--text">{{activePartner.name}}</h2>

                                    </v-col>
                                    <v-col md="12" cols="12" class="">
                                        <v-text-field
                                        :label="$t('number')"
                                        required
                                        v-model="partnerRefNum"
                                        outlined
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <!-- <v-btn color="secondary" class="white--text text-capitalize mr-2"
                                        @click="skipPartnerRef">
                                        {{ $t('skip') }}
                                    </v-btn> -->
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterPartnerRef">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Sale Unit -->
            <template>
                <v-dialog
                    v-model="dialogSaleUnit"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                    >
                    <template v-slot:activator="{ on, attrs }">
                    </template>
                    <v-card>
                        <v-toolbar
                            dark
                            color="secondary"
                            >
                            <v-btn
                                icon
                                dark
                                @click="skipSaleUnit"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t("sale_unit") }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn
                                dark
                                text
                                @click="skipSaleUnit"
                                >
                                {{ $t('skip') }}
                                </v-btn>
                                <v-btn
                                dark
                                text
                                @click="enterSaleUnit()">
                                
                                {{ $t('enter') }}
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-col v-if="showSaleUnitCate" md="12" col="12" class="function_content pa-3" style="height: 90vh;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row class="d-flex justify-center" v-for="d in saleUnitCates" v-bind:key="d.id">
                                    <v-col sm="4" cols="6" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="500"
                                            outlined
                                            @click="selectCateSaleUnit(d)"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{d.name}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col v-else md="12" col="12" class="function_content pa-3" style="height: 90vh;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row class="d-flex justify-center">
                                    <v-col sm="3" cols="6" class="" v-for="d in saleUnits" v-bind:key="d.id">
                                        <v-card
                                            class="mx-auto"
                                            :color="saleUnit.id == d.id ? 'primary': 'white' "
                                            :class="saleUnit.id == d.id ? 'white--text': 'black--text' "
                                            max-width="500"
                                            outlined
                                            @click="selectSaleUnit(d)"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{d.name}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <!-- <v-card-actions v-show="!showSaleUnitCate">
                            <div class="function_footer text-right">
                                <v-btn color="secondary" class="float-left mr-2 white--text text-capitalize"
                                    @click="backSaleUnit()">
                                    {{ $t('back') }}
                                </v-btn>
                                <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                    @click="skipSaleUnit()">
                                    {{ $t('skip') }}
                                </v-btn>
                                <v-btn color="primary" class="float-right white--text text-capitalize"
                                    @click="enterSaleUnit()">
                                    {{ $t('enter') }}
                                </v-btn>
                            </div>
                            <v-spacer></v-spacer>
                        </v-card-actions> -->
                    </v-card>
                </v-dialog>
            </template> 
            <!-- Sale unit list-->
            <v-dialog
                v-model="dialogSaleUnitList"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogSaleUnitList = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("serving") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogSaleUnitList = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col v-for="s in saleUnitData"  v-bind:key="s.id" class="text-center" sm="6" cols="6">
                                    <v-card
                                        :color="s.status != 1 ? 'primary' : ''"
                                        outlined
                                        dense
                                        class="pa-6  raduis_10 pop no_border pop"
                                        min-height="150"
                                        >
                                        <div style="display: flex;justify-content: space-between; margin-top: 0px;">
                                            <h1
                                            v-if="s.status == 1"
                                            class="font_14 primary--text"
                                            style="text-transform: uppercase;">
                                                {{ $t('available') }}
                                            </h1>
                                            <h1
                                            v-else-if="s.status == 0"
                                            class="font_14 white--text"
                                            style="text-transform: uppercase;">
                                                {{ $t('serving') }}
                                            </h1>
                                            <h1
                                            v-else-if="s.status == 3"
                                            class="font_14 white--text"
                                            style="text-transform: uppercase;">
                                                {{ $t('reserve') }}
                                            </h1>
                                            <h1
                                            v-else
                                            class="font_14 white--text"
                                            style="text-transform: uppercase;">
                                                {{ $t('maintenance') }}
                                            </h1>
                                        </div>
                                        <div style="display: flex;justify-content: space-between; margin-top: 10px;">
                                            <h1
                                            :class="s.status == 1 ? 'font_18 primary--text' : s.status == 0 ? 'font_18 white--text' : 'font_18 secondary--text' "
                                            style="text-transform: uppercase;">
                                            {{ s.name }} 
                                            </h1>
                                            <h3
                                            :class="s.status == 1 ? 'font_12 primary--text' : s.status == 0 ? 'font_12 white--text' : 'font_12 secondary--text' "
                                            style="text-transform: uppercase;">
                                                ({{ s.code }})
                                            </h3>
                                        </div>
                                        <h3
                                            v-if="s.status == 2"
                                            class="font_12 primary--text"
                                            style="text-transform: uppercase;margin-top: 10px; background: #fff; border-radius: 5px;text-align: center;">
                                                ({{ $t('available') }})
                                        </h3>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!--body-->
            <v-col sm="12" cols="12" class="py-0">
                <v-card color="primary" class="pa-0 no_border" elevation="0" style="height: 125px;">
                    <v-row>
                        <v-col md="12" sm="12" cols="12" class="mx-4">
                            <h1 class="text-white font_12 mb-2">welcome to banhji POS</h1>
                            <img
                                class="order_type"
                                src="../../assets/images/banhji-logo-rw.png"
                                style="width: 250px; height: auto;"
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col sm="12" cols="12" class="py-0">
                <v-card color="#f8f8f9" class="pa-0 no_border" elevation="0" style="height: 80vh;">
                    <v-row>
                        <!-- function on left -->
                        <v-col md="6" sm="12" cols="12" class="py-0 ">
                            <div class="" style="height: auto;display: flex; flex-direction: column;">
                                <div sm="12" cols="12" class="pt-0">
                                    <v-row class="px-4">
                                        <v-col sm="12" cols="12" class="py-0"> 
                                            <v-row>
                                                <!-- payments -->
                                                <v-col v-if="!isProcessOrder" md="6" sm="6" cols="12" class="pa-1 px-0" style="padding-bottom: 0!important;">
                                                    <v-row class="mx-0">
                                                 
                                                        <!-- start order -->
                                                        <v-col sm="12" cols="12" class="pa-2" >
                                                            <v-card
                                                                class="no_border"
                                                                color="white"
                                                                theme="dark"
                                                                elevation="2"
                                                                @click="startOrder"
                                                            >
                                                                <div class="d-flex flex-no-wrap justify-space-between">
                                                                    <div>
                                                                        <v-card-title class="niradei_heavy">
                                                                        {{ $t("take_order") }}
                                                                        </v-card-title>

                                                                        <v-card-subtitle>Start your order here</v-card-subtitle>

                                                                    </div>
                                                                    <div class="pa-4">
                                                                        <v-icon
                                                                            style="font-size: 90px;"
                                                                            color="primary"
                                                                            class="b-order_type">
                                                                        </v-icon>

                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                        <!-- serving -->
                                                        <v-col sm="12" cols="12" class="pa-2" >
                                                            <v-card
                                                                class="no_border"
                                                                color="white"
                                                                theme="dark"
                                                                elevation="2"
                                                                @click="showServingTxn"
                                                                style="height: 75px;"
                                                            >
                                                                <div class="d-flex flex-no-wrap justify-space-between">
                                                                    <div class="my-auto">
                                                                        <v-card-title class="niradei_heavy">
                                                                        {{ $t("currently_serving") }}
                                                                        </v-card-title>

                                                                        <!-- <v-card-subtitle>Start your order here</v-card-subtitle> -->

                                                                    </div>
                                                                    <div class="pa-4 my-auto">
                                                                        <h1
                                                                        class="font_24 primary--text"
                                                                        :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                        style="text-transform: uppercase;">{{servings.length}}
                                                                        </h1>

                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                        <!-- check out -->
                                                        <v-col sm="6" cols="6" class="pa-2" >
                                                            <v-card
                                                                color="white"
                                                                theme="dark"
                                                                elevation="2"
                                                                @click="dialogSaleUnitList = true"
                                                                style="height: 100%;"
                                                            >
                                                                <div class="">
                                                                    <div>
                                                                        <v-card-title class="niradei_heavy">
                                                                        {{ $t("sale_unit") }}
                                                                        </v-card-title>

                                                                        <v-card-subtitle>Foster the People</v-card-subtitle>

                                                                    </div>

                                                                    <div class="pa-4" style="display: flex;justify-content: end;bottom: 0;position: absolute;right: 0;">
                                                                            <v-icon
                                                                                style="font-size: 60px;"
                                                                                color="primary"
                                                                                class="b-fund_acc">
                                                                            </v-icon>

                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col sm="6" cols="6" class="pa-2" >
                                                            <div>
                                                                <v-col sm="12" cols="12" class="pa-0" >
                                                                    <v-card
                                                                        color="white"
                                                                        theme="dark"
                                                                        elevation="2"
                                                                        @click="showOrderTxn"
                                                                    >
                                                                        <div class="d-flex flex-no-wrap justify-space-between">
                                                                            <div>
                                                                                <v-card-title class="niradei_regular font_18" style="word-break: break-word;">
                                                                                {{ $t("other_order") }}
                                                                                </v-card-title>

                                                                                <!-- <v-card-subtitle>Foster the People</v-card-subtitle> -->

                                                                            </div>

                                                                            <div class="pa-4">
                                                                                   <h1
                                                                                    class="font_24 primary--text ma-auto"
                                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                                    style="text-transform: uppercase;">{{txnorders.length}}</h1>

                                                                            </div>
                                                                        </div>
                                                                    </v-card>
                                                                </v-col>
                                                                <!-- invoice -->
                                                                <v-col sm="12" cols="12" class="px-0 pb-0" >
                                                                    <v-card
                                                                        color="white"
                                                                        theme="dark"
                                                                        elevation="2"
                                                                        @click="showInvoice"
                                                                    >
                                                                        <div class="d-flex flex-no-wrap justify-space-between">
                                                                            <div>
                                                                                <v-card-title class="niradei_regular font_18" style="word-break: break-word;">
                                                                                {{ $t("invoices") }}
                                                                                </v-card-title>

                                                                                <!-- <v-card-subtitle>Foster the People</v-card-subtitle> -->

                                                                            </div>

                                                                            <div class="pa-4">
                                                                                <h1
                                                                                class="font_24 primary--text"
                                                                                :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                                style="text-transform: uppercase;">{{invoices.length}}</h1>

                                                                            </div>
                                                                        </div>
                                                                    </v-card>
                                                                </v-col>
                                                                <!-- pre order -->
                                                                <v-col sm="12" cols="12" class="px-0 pb-0" >
                                                                    <v-card
                                                                        color="white"
                                                                        theme="dark"
                                                                        elevation="2"
                                                                        @click="showPreOrderTxn"
                                                                    >
                                                                        <div class="d-flex flex-no-wrap justify-space-between">
                                                                            <div>
                                                                                <v-card-title class="niradei_regular font_18" style="word-break: break-word;">
                                                                                {{ $t("pre_order") }}
                                                                                </v-card-title>

                                                                                <!-- <v-card-subtitle>Foster the People</v-card-subtitle> -->

                                                                            </div>

                                                                            <div class="pa-4">
                                                                                 <h1
                                                                                    class="font_24 primary--text"
                                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                                    style="text-transform: uppercase;">{{preorders.length}}</h1>

                                                                            </div>
                                                                        </div>
                                                                    </v-card>
                                                                </v-col>
                                                            </div>
                                                        </v-col>
                                                        <!-- functions -->
                                                        <v-col sm="12" cols="12" class="px-0" >
                                                            <v-card
                                                                class="no_border px-3"
                                                                color="white"
                                                                theme="dark"
                                                                elevation="2"
                                                            >
                                                                <v-card-title class="niradei_medium font_16 px-0">
                                                                {{ $t("functions") }}
                                                                </v-card-title>
                                                                <v-row>
                                                                    <v-col sm="6" cols="6" class="pt-0">
                                                                        <v-card
                                                                            color="grayBg"
                                                                            theme="dark"
                                                                            elevation="2"
                                                                            v-show="func.resetOrder"
                                                                        >
                                                                            <div>
                                                                                <v-card-title class="niradei_medium font_16">
                                                                                {{ $t("product_lookup") }}
                                                                                </v-card-title>
                                                                            </div>
                                                                        </v-card>
                                                                    </v-col>
                                                                    <v-col sm="6" cols="6" class="pt-0">
                                                                        <v-card
                                                                            color="grayBg"
                                                                            theme="dark"
                                                                            elevation="2"
                                                                            @click="showEndSession" 
                                                                        >
                                                                            <div>
                                                                                <v-card-title class="niradei_medium font_16">
                                                                                {{ $t("end_session") }}
                                                                                </v-card-title>
                                                                            </div>
                                                                        </v-card>
                                                                    </v-col>
                                                                    <v-col sm="6" cols="6" class="pt-0">
                                                                        <v-card
                                                                            color="grayBg"
                                                                            theme="dark"
                                                                            elevation="2"
                                                                            @click="pullData()"
                                                                        >
                                                                            <div>
                                                                                <v-card-title class="niradei_medium font_16">
                                                                                {{ $t("pull_data") }}
                                                                                </v-card-title>
                                                                                <v-card-subtitle>As of:{{pullAsOf}}</v-card-subtitle>
                                                                                
                                                                            </div>
                                                                        </v-card>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col v-else md="6" sm="6" cols="12" class="pa-1 px-0" style="padding-bottom: 0!important;">
                                                    <v-row>
                                                        <!-- items list -->
                                                        <v-col md="12" sm="12" cols="12" class="pt-0" style="overflow-y: auto;">
                                                            <v-row>
                                                                <v-col sm="12" cols="12" class="">
                                                                    <v-card flat height="90vh" class="card-item px-0" style=""> 
                                                                        <v-card-text class="pa-0">
                                                                            <v-row class="" style=" margin: auto;">
                                                                                <v-col sm="4" cols="4" class="py-0 px-1">
                                                                                    <v-select class="mt-1 rounded-0 "
                                                                                            clearable
                                                                                            outlined
                                                                                            :label="$t('code')"
                                                                                    />
                                                                                </v-col>
                                                                                <v-col sm="8" cols="8"  class="py-0 px-1">
                                                                                    <v-text-field
                                                                                        class="mt-1 rounded-0 "
                                                                                        outlined
                                                                                        :label="$t('scan_code')"
                                                                                        append-icon="mdi-barcode-scan"
                                                                                    ></v-text-field>
                                                                                </v-col>
                                                                                <LoadingMe
                                                                                    :isLoading="loadingItmBlock"
                                                                                    :fullPage="false"
                                                                                    :myLoading="true" />
                                                                                <!-- item -->
                                                                                <div v-show="showItem" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col md="6" sm="6" xs="6" cols="6" class="" v-for="item in items" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="addItem(item)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 px-1 name-items mb-0" style="height: 50px;line-height: 1.15;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" :style="'background-color: #898c8f;text-align: center;border-radius: 0;' + item.titleColor">
                                                                                                <h2 class="text-white mb-0" style="font-size:18px;"> {{ numberFormat(item.price) }} </h2>
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </div>
                                                                                <!-- category -->
                                                                                <v-row v-show="showCatePage" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col md="6" sm="6" xs="6" cols="6" class="" v-for="item in categories" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="searchItemByCate(item.id)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <!-- group -->
                                                                                <v-row v-show="showGroupPage" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col md="6" sm="6" xs="6" cols="6" class="pa-1" v-for="item in cateGroup" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="searchItemByGroup(item.id)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <!-- sub group -->
                                                                                <v-row v-show="showSupGroupPage" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col md="6" sm="6" xs="6" cols="6" class="pa-1" v-for="item in subGroup" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="searchItemBySubGroup(item.id)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-row>
                                                                        </v-card-text>  
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="3" class="pa-0 py-0">
                                                            <v-btn @click="itemFavorite" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                <!-- <i  class=" b-reward_s" style=""/> -->
                                                                <span class="text-bold letter_spacing">{{$t('favorite')}}</span>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="3" class="pa-0 py-0">
                                                            <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                <!-- <i  class=" b-reward_s" style=""/> -->
                                                                <span class="text-bold letter_spacing">{{$t('categories')}}</span>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="3" class="pa-0 py-0">
                                                            <v-btn   color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                <!-- <i  class=" b-reward_s" style=""/> -->
                                                                <span class="text-bold letter_spacing">{{$t('card')}}</span>
                                                            </v-btn>
                                                        </v-col>
                                                        <!-- <v-col cols="3" class="pa-0 py-0">
                                                            <v-btn  @click="goGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                    <i  class=" b-reward_s" style=""/>
                                                                <span class="text-bold letter_spacing">{{$t('group')}}</span>
                                                            </v-btn>
                                                        </v-col> -->
                                                        <v-col cols="3" class="pa-0 py-0" >
                                                            <v-btn  @click="goSubGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                <!-- <i  class=" b-reward_s" style=""/> -->
                                                                <span class="text-bold letter_spacing">{{$t('catalogs')}}</span>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <!-- Serving Dialog-->
                                                    
                                                    <v-col cols="12" class="pa-0 py-0" >
                                                            <v-btn  @click="viewCard = true" color=primary class="white--text rounded-0 btn-funtion1" style="position: fixed;
                                                            position: fixed;
                                                            bottom: 0;
                                                            right: 0;
                                                            left: 0;
                                                            z-index: 99999;
                                                            border-radius: 0px !important;"
                                                    >
                                                                <!-- <i  class=" b-reward_s" style=""/> -->
                                                                <span class="text-bold letter_spacing">{{$t('view_card')}}</span>
                                                            </v-btn>
                                                        </v-col>
                                                    <v-dialog
                                                        v-model="viewCard"
                                                        fullscreen
                                                        hide-overlay
                                                        transition="dialog-bottom-transition"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            
                                                        </template>
                                                        <v-card>
                                                            <v-toolbar
                                                                dark
                                                                color="secondary"
                                                                >
                                                                <v-btn
                                                                    icon
                                                                    dark
                                                                    @click="viewCard()"
                                                                >
                                                                    <v-icon>mdi-close</v-icon>
                                                                </v-btn>
                                                                <v-toolbar-title>{{ $t("order_list") }}</v-toolbar-title>
                                                                <v-spacer></v-spacer>
                                                                <v-toolbar-items>
                                                                    <v-btn
                                                                    dark
                                                                    text
                                                                    @click="viewCard = false"
                                                                    >
                                                                    {{ $t('skip') }}
                                                                    </v-btn>
                                                                </v-toolbar-items>
                                                            </v-toolbar>
                                                            <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                                                                <div class="" style="height: 98vh;background-color: #ffff;display: flex; flex-direction: column;">
                                                                    <div sm="12" cols="12" style="flex-grow: 1; overflow-y: scroll;overflow-x: hidden;">
                                                                        <v-card color="white" class="px-2 no_border" elevation="0">
                                                                            <v-row>
                                                                                <v-col sm="12" cols="12" class="py-0" style="overflow-y: scroll;">
                                                                                    <template>
                                                                                        <v-simple-table class="attachment_table">
                                                                                            <template v-slot:default>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <!-- <th style="width: 50px"/> -->
                                                                                                        <th class="text-uppercase">{{ $t('description') }}</th>
                                                                                                        <!-- <th class="text-uppercase">{{ $t('uom') }}</th> -->
                                                                                                        <th class="text-uppercase">{{ $t('qty') }}</th>
                                                                                                        <!-- <th class="text-uppercase">{{ $t('price') }}</th> -->
                                                                                                        <!-- <th class="text-uppercase">{{ $t('discount') }}</th> -->
                                                                                                        <th class="text-uppercase">{{ $t('amount') }}</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr 
                                                                                                        v-for="d in lineDS" 
                                                                                                        v-bind:key="d.id"  
                                                                                                        :style="activeLine.id == d.id ? 'background: #eee': '' "
                                                                                                        @click="selectLine(d)">
                                                                                                        <td>{{ d.description }}</td>
                                                                                                        <!-- <td>{{ d.uom.toUOM.name }}</td> -->
                                                                                                        <td>{{ numberFormat(d.qty) }}</td>
                                                                                                        <!-- <td>{{ numberFormat(d.price) }}</td> -->
                                                                                                        <!-- <td>{{ numberFormat(d.discount) }}</td> -->
                                                                                                        <td>{{ numberFormat(d.amount) }}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td rowspan="3" class="primary--text">+ {{$t('add_more_items')}}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </template>
                                                                                        </v-simple-table>
                                                                                    </template>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card>
                                                                    </div>
                                                                    <div sm="12" cols="12" class="pt-0">
                                                                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                                                <v-row>
                                                                                    <v-col sm="12" cols="12" class="py-0">
                                                                                        <v-row>
                                                                                            <v-col md="6" sm="6" cols="12" class="pa-1 px-0">
                                                                                                <v-row class="mx-0">
                                                                                                    <!-- check out -->
                                                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                                                        <template>
                                                                                                            <div justify="center" style="height: 125px;">
                                                                                                                <v-btn 
                                                                                                                    color="primary"
                                                                                                                    dark
                                                                                                                    @click="checkOut"
                                                                                                                    height="50%"
                                                                                                                    class="rounded-0 pay-btn" 
                                                                                                                    style="height:100% !important;">
                                                                                                                    <div style="display: inline-grid;">
                                                                                                                        <v-icon
                                                                                                                            style="font-size: 40px;"
                                                                                                                            color="primary"
                                                                                                                            class="b-cash">
                                                                                                                        </v-icon>
                                                                                                                        <span class="font_13 text-bold mt-2">{{ $t("check_out") }}</span>  
                                                                                                                    </div>
                                                                                                                </v-btn>
                                                                                                            </div>
                                                                                                        </template>
                                                                                                    </v-col>
                                                                                                    <!-- serving -->
                                                                                                    <v-col sm="6" cols="6" class="pa-0" >
                                                                                                        <template>
                                                                                                            <div justify="center" style="height: 123px;">
                                                                                                                <v-btn 
                                                                                                                    color="primary"
                                                                                                                    dark
                                                                                                                    @click="saveServing"
                                                                                                                    height="50%"
                                                                                                                    class="rounded-0 pay-btn" 
                                                                                                                    style="height:100% !important;">
                                                                                                                    <div style="display: inline-grid;">
                                                                                                                        <v-icon
                                                                                                                            style="font-size: 40px;"
                                                                                                                            color="primary"
                                                                                                                            class="b-parksale">
                                                                                                                        </v-icon>
                                                                                                                        <span class="font_13 text-bold mt-2">{{ $t("serving") }}</span>  
                                                                                                                    </div>
                                                                                                                </v-btn>
                                                                                                            </div>
                                                                                                        </template>
                                                                                                    </v-col>
                                                                                                    <!-- partner -->
                                                                                                    <v-col sm="6" cols="6" class="pa-0" >
                                                                                                        <template>
                                                                                                            <div justify="center" style="height: 100%;">
                                                                                                                <v-btn 
                                                                                                                    color="primary"
                                                                                                                    dark
                                                                                                                    @click="payByPartner"
                                                                                                                    height="50%"
                                                                                                                    class="rounded-0 pay-btn" 
                                                                                                                    style="height:100% !important;">
                                                                                                                    <div style="display: inline-grid;">
                                                                                                                        <v-icon
                                                                                                                            style="font-size: 40px;"
                                                                                                                            color="primary"
                                                                                                                            class="b-service">
                                                                                                                        </v-icon>
                                                                                                                        <span class="font_13 text-bold mt-2">{{ $t("partner") }}</span>  
                                                                                                                    </div>
                                                                                                                </v-btn>
                                                                                                            </div>
                                                                                                        </template>
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                </v-row>
                                                                        </v-card>
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-card>
                                                    </v-dialog>
                                                
                                                </v-col>
                                            </v-row> 
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>         
                        </v-col>
                        <!-- pop up-->
                        <!-- Pin pop up -->
                        <div sm="3" cols="12" class="ml-8">
                            <template>
                                <v-row>
                                    <v-dialog 
                                        v-model="pinActivate"
                                        persistent 
                                        max-width="350px">
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <template>
                                                <!-- <v-container> -->
                                                <div id="pin" class="m-auto my-4">
                                                    <v-text-field
                                                        class="mt-4"
                                                        v-model="pin"
                                                        placeholder=""
                                                        :type="'password'"
                                                        :rules="[v => !!v || $t('pin_is_required') ]"
                                                    />
                                                    <h2 v-if="incorrect">{{$t("wrong_pin")}}</h2>
                                                    <div class="numbers">
                                                        <div @click="pinClick(1)" class="number">1</div>
                                                        <div @click="pinClick(2)" class="number">2</div>
                                                        <div @click="pinClick(3)" class="number">3</div>
                                                        <div @click="pinClick(4)" class="number">4</div>
                                                        <div @click="pinClick(5)" class="number">5</div>
                                                        <div @click="pinClick(6)" class="number">6</div>
                                                        <div @click="pinClick(7)" class="number">7</div>
                                                        <div @click="pinClick(8)" class="number">8</div>
                                                        <div @click="pinClick(9)" class="number">9</div>
                                                        <div @click="pinClick('clear')" class="number" style="">{{$t("ac")}}</div>
                                                        <div @click="pinClick(0)" class="number">0</div>
                                                        <div @click="searchPin" class="number" style="background-color: rgb(154 27 46);color: #ffff;">{{$t("go")}}</div>
                                                    </div>
                                                </div>
                                                <!-- </v-container> -->
                                            </template>
                                        </v-form>
                                    </v-dialog>
                                </v-row>
                            </template> 
                        </div>
                        <!-- count guest-->
                        <v-col md="6" v-show="func.countGuest" sm="3" col="3" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="dialogCountGuest"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                dark
                                                @click="skipGuestCount"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("count_guest") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                text
                                                @click="skipGuestCount"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                                <v-btn
                                                dark
                                                text
                                                @click="enterGuestCount">
                                                
                                                {{ $t('enter') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3 d-flex justify-center" style="height: 90vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row>
                                                    <v-col sm="6" cols="12" class="">
                                                        <v-card
                                                            class="mx-auto"
                                                            max-width="500"
                                                            outlined
                                                        >
                                                            <v-list-item three-line>
                                                                <v-list-item-content class="pk-3">
                                                                    <v-row>
                                                                        <v-col sm="12" cols="12" class="text-center">
                                                                            <h2 class="font_22">{{$t('local')}}</h2>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                            <img
                                                                                class="img-1"
                                                                                src="@/assets/images/khmer_man.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.localMen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncLocalMen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark >
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecLocalMen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                        <img
                                                                                class="img-1"
                                                                                src="@/assets/images/khmer_women.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.localWomen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncLocalWomen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecLocalWomen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col sm="6" cols="12" class="">
                                                        <v-card
                                                            class="mx-auto"
                                                            max-width="500"
                                                            outlined
                                                        >
                                                            <v-list-item three-line>
                                                                <v-list-item-content class="pk-3">
                                                                    <v-row>
                                                                        <v-col sm="12" cols="12" class="text-center">
                                                                            <h2 class="primary--text font_22">{{$t('foreigner')}}</h2>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                            <img
                                                                                class="img-1"
                                                                                src="@/assets/images/foreigner_men.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.forMen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncForMen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecForMen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                        <img
                                                                                class="img-1"
                                                                                src="@/assets/images/foreigner_women.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.forWomen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncForWomen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecForWomen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- item uom -->
                        <v-col md="6" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="uomDialog"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-toolbar-title>{{ $t("uom") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3" style="height: 97vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row>
                                                    <div v-for="d in itemUOMs" v-bind:key="d.uid" class="col-6 pa-0">
                                                        <v-col sm="12" cols="12" class="">
                                                            <v-card
                                                                class="mx-auto"
                                                                max-width="465"
                                                                outlined
                                                                @click="enterUOM(d)"
                                                            >
                                                                <v-list-item three-line>
                                                                    <v-list-item-content class="pk-3">
                                                                        <v-row>
                                                                            <v-col sm="12" cols="12" class="text-center">
                                                                                <h2 class="font_22">{{d.toUOM.name}}</h2>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                        </v-col>
                                                    </div>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- employee -->
                        <v-col md="6" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="employeeDialog"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                dark
                                                @click="skipEmp()"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("employee") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                text
                                                @click="skipEmp()"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                               
                                                <v-btn 
                                                dark
                                                text
                                                 @click="enterEmp()">
                                                
                                                {{ $t('enter') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row>
                                                        <v-col sm="12" cols="12" class="">
                                                            <v-card
                                                                class="mx-auto"
                                                                outlined
                                                            >
                                                                <v-list-item three-line>
                                                                    <v-list-item-content class="pk-3">
                                                                        <v-row>
                                                                            <v-col sm="12" cols="12" class="text-center" v-for="itm in employees" v-bind:key="itm.uid">
                                                                                <v-btn
                                                                                    :class="itm.id + '-de white--text'"
                                                                                    style="padding: 20px;background: red;display:none;float: left;" 
                                                                                    color="primary"
                                                                                    @click="deSelectEmp(itm.id)">
                                                                                    {{itm.name}}
                                                                                </v-btn>
                                                                                <v-btn 
                                                                                    :class="itm.id + ' black--text'"
                                                                                    style="padding: 20px;background: #eee;display:block;float: left;" 
                                                                                    @click="selectEmp(itm.id)">
                                                                                    {{itm.name}}
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                            
                                                        </v-col>
                                                </v-row>
                                            </v-card>
                                            
                                        </v-col>
                                        <v-card-actions>
                                            <div class="function_footer">
                                                <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                                    @click="skipEmp()">
                                                    {{ $t('skip') }}
                                                </v-btn> -->
                                                <v-btn v-if="isEditEmp" color="primary" class="float-right white--text text-capitalize"
                                                    @click="clearEmp()">
                                                    {{ $t('clear') }}
                                                </v-btn>

                                                <!-- <v-btn color="primary" class="float-right white--text text-capitalize"
                                                    @click="enterEmp()">
                                                    {{ $t('enter') }}
                                                </v-btn> -->
                                            </div>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- OrderType -->
                        <v-col md="6" v-show="func.orderType" sm="3" col="3" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="dialogOrderType"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                dark
                                                @click="skipOrderType"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("order_type") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                text
                                                @click="skipOrderType"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3 " style="height: 90vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row class="d-flex justify-center">
                                                    <div v-for="d in orderTypes" v-bind:key="d.id" class="col-6 pa-0">
                                                        <v-col sm="12" cols="12" class="">
                                                            <v-card
                                                                class="mx-auto"
                                                                max-width="465"
                                                                outlined
                                                                @click="enterOrderType(d)"
                                                            >
                                                                <v-list-item three-line>
                                                                    <v-list-item-content class="pk-3">
                                                                        <v-row>
                                                                            <v-col sm="12" cols="12" class="text-center">
                                                                                <h2 class="font_22">{{d.name}}</h2>
                                                                                <h2 color="primary" class="font_16">{{d.abbr}}</h2>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                        </v-col>

                                                    </div>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- modifier -->
                        <v-col md="6" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="modifierDialog"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                dark
                                                @click="skipModi"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("modifier") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                text
                                                @click="skipModi"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                               
                                                <v-btn 
                                                dark
                                                text
                                                @click="enterModi()">
                                                
                                                {{ $t('enter') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row >
                                                    <v-col sm="6" cols="12" class="" v-for="d in activeModi" v-bind:key="d.id">
                                                        <v-card
                                                            class="mx-auto"
                                                            outlined
                                                        >
                                                            <v-list-item three-line>
                                                                <v-list-item-content class="pk-3">
                                                                    <v-row>
                                                                        <v-col sm="12" cols="12" class="text-center">
                                                                            <h4 class="font_22 text-left mb-2">{{d.name}}</h4>
                                                                            <div class="v-divider" style="color: lavenderblush;"></div>
                                                                            <v-row>
                                                                                <v-col sm="4" cols="4" class="" v-for="itm in d.items" v-bind:key="itm.pk">
                                                                                    <v-btn
                                                                                        :class="itm.pk + '-de white--text'"
                                                                                        style="padding: 20px;background: red;display:none;float: left;" 
                                                                                        color="primary"
                                                                                        @click="deSelectModi(itm.pk)">
                                                                                        {{itm.name}}
                                                                                    </v-btn>
                                                                                    <v-btn 
                                                                                        :class="itm.pk + ' black--text'"
                                                                                        style="padding: 20px;background: #eee;display:block;float: left;" 
                                                                                        @click="selectModi(itm.pk)">
                                                                                        {{itm.name}}
                                                                                    </v-btn>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-card-actions>
                                            <div class="function_footer">
                                                <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                                    @click="skipModi()">
                                                    {{ $t('skip') }}
                                                </v-btn> -->
                                                <v-btn v-if="isEditModi" color="primary" class="float-right white--text text-capitalize"
                                                    @click="clearModi()">
                                                    {{ $t('clear') }}
                                                </v-btn>

                                                <!-- <v-btn color="primary" class="float-right white--text text-capitalize"
                                                    @click="enterModi()">
                                                    {{ $t('enter') }}
                                                </v-btn> -->
                                            </div>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- Session -->
                        <v-dialog
                            v-model="dialogCloseSession"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                        >
                            <v-card>
                                <v-toolbar
                                    dark
                                    color="secondary"
                                    >
                                    <v-btn
                                        icon
                                        dark
                                        @click="dialogCloseSession = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>{{ $t("session") }}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn
                                        dark
                                        text
                                        @click="dialogCloseSession = false"
                                        >
                                        {{ $t('skip') }}
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                    <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                        <v-row>
                                            <v-col sm="12" cols="12" class="py-0">
                                                <v-row >
                                                    <v-col sm="12" cols="12" class="">
                                                        <div class="d-flex flex-column" style="height: 100%;">
                                                            <v-simple-table fixed-header class="flex-1" >
                                                                <template v-slot:default>
                                                                   
                                                                    <tbody class="" v-for="d in activeSession" 
                                                                            v-bind:key="d.pk">
                                                                            <tr>
                                                                                <td>{{$t('number')}}</td>
                                                                                <td class="primary--text">{{ d.number }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{$t('start_date')}}</td>
                                                                                <td class="primary--text">{{ kendo.toString(new Date(d.startDate), 'yyyy-MM-dd h:m tt') }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{$t('status')}}</td>
                                                                                <td class="primary--text">{{ d.status }}</td>
                                                                            </tr>
                                   
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </div>
                                                    </v-col>
                                                    <v-col sm="12" cols="12" class="">
                                                        <div class="d-flex" style="justify-content: space-between;">
                                                            <v-btn color="primary" style="min-width: 0;" class="float-right white--text text-capitalize" @click="showEndShift(d)">
                                                                {{$t('end_shift')}}
                                                            </v-btn>
                                                            <v-btn color="primary" style="min-width: 0;" class="float-right white--text text-capitalize" @click="showEndDay()">
                                                            {{$t('end_day')}}
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogCashReconcile"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                        >
                            <v-card>
                                <v-toolbar
                                    dark
                                    color="secondary"
                                    >
                                    <v-btn
                                        id="closeCountCash"
                                        icon
                                        dark
                                        @click="dialogCashReconcile = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>{{ $t("cash_count") }}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <!-- <v-toolbar-items>
                                        <v-btn
                                        dark
                                        text
                                        @click="dialogCashReconcile = false"
                                        >
                                        {{ $t('skip') }}
                                        </v-btn>
                                    </v-toolbar-items> -->
                                </v-toolbar>
                                <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                    <v-card class="mx-auto my-auto" max-width="1200" style="box-shadow: none;">
                                        <CashCount :countObj="countObj" />
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-dialog>
                        <!-- loyalty pop up -->
                        <div sm="3" cols="12" class="ml-8" hidden>
                            <!-- end shift info -->
                            <template>
                                <v-row>
                                    <v-dialog
                                        v-model="dialogShiftInfo"
                                        persistent
                                        max-width="400px"
                                        >
                                        <v-card>
                                            <div class="modal_header">
                                                <v-card-title>{{ $t("end_shift") }}</v-card-title>
                                                <v-icon
                                                    @click="dialogShiftInfo = false"
                                                    style="cursor: pointer; font-size: 30px;"
                                                    color="grey"
                                                    class="float-right mt-n1">close
                                                </v-icon>
                                            </div>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <v-row>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('number')}}</h2>
                                                        <h2 class="font_18 primary--text">{{shiftObj.shiftNum}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('start')}}</h2>
                                                        <h2 class="font_12 primary--text">{{shiftObj.startDate}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('end')}}</h2>
                                                        <h2 class="font_12 primary--text">{{shiftObj.endDate}}</h2>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-card-actions>
                                                <div class="function_footer text-right">
                                                    <v-btn color="secondary" class="white--text text-capitalize mr-2"
                                                        @click="dialogShiftInfo = false">
                                                        {{ $t('cancel') }}
                                                    </v-btn>
                                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                                        @click="endShift">
                                                        {{ $t('enter') }}
                                                    </v-btn>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-row>
                            </template>
                            <!-- end session info -->
                            <template>
                                <v-row>
                                    <v-dialog
                                        v-model="dialogSessionInfo"
                                        persistent
                                        max-width="400px"
                                        >
                                        <v-card>
                                            <div class="modal_header">
                                                <v-card-title>{{ $t("end_session") }}</v-card-title>
                                                <v-icon
                                                    @click="dialogSessionInfo = false"
                                                    style="cursor: pointer; font-size: 30px;"
                                                    color="grey"
                                                    class="float-right mt-n1">close
                                                </v-icon>
                                            </div>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <v-row>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('number')}}</h2>
                                                        <h2 class="font_16 primary--text">{{session.number}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('start')}}</h2>
                                                        <h2 class="font_12 primary--text">{{dateFormat(session.startDate)}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('end')}}</h2>
                                                        <h2 class="font_12 primary--text">{{dateFormat(session.endDate)}}</h2>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-card-actions>
                                                <div class="function_footer text-right">
                                                    <v-btn style="float: left;" color="secondary" class="white--text text-capitalize mr-2"
                                                        @click="endDayCountCash">
                                                        {{ $t('cash_count') }}
                                                    </v-btn>
                                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                                        @click="endDay">
                                                        {{ $t('enter') }}
                                                    </v-btn>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-row>
                            </template>
                        </div>
                    </v-row>
                </v-card>
                <LoadingMe
                        :isLoading="loadPullData"
                        :fullPage="false"
                        :myLoading="true" />
                <LoadingMe
                    :isLoading="showLoading"
                    :fullPage="false"
                    :myLoading="true" />
                <LoadingMe
                    :isLoading="showBindItmLocal"
                    :fullPage="false"
                    :myLoading="true" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import {Drag,DropList} from "vue-easy-dnd";
import generalSettingModel from "@/scripts/commerce/model/GeneralSetting"
import { i18n } from "@/i18n";
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
const priceLevelHandler = require("@/scripts/priceLevelHandler")
// const categoryHandler = require("@/scripts/categoryHandler")
const groupHandler = require("@/scripts/groupHandler")
const subGroupHandler = require("@/scripts/subGroupHandler")
const cookieJS = require("@/cookie.js");
const { instituteId } = cookieJS.getCookie()
const cookie = cookieJS.getCookie();
import kendo from "@progress/kendo-ui"
const $ = kendo.jQuery
// item line
import ItemLineModel from "@/scripts/invoice/model/ItemLine"
import { uuid } from "vue-uuid"
// import { dataStore } from "@/observable/store"
import SaleFormContentModel from "@/scripts/model/SaleFormContent"
const saleFormContentModel = new SaleFormContentModel({})
const saleFormContentHandler = require("@/scripts/saleFormContentHandler")
import InvoiceModel from "@/scripts/invoice/model/Invoice"
import DatePickerComponent from '@/components/custom_templates/DatePickerComponent'
const invoiceModel = new InvoiceModel({})
const accountHandler = require("@/scripts/handler/accounting/account")
import CustomerModel from "@/scripts/model/Customer"
const discountItemHandler = require("@/scripts/discountItemHandler")
const saleUCHandler = require("@/scripts/saleUCHandler");
const saleUnitItemHandler = require("@/scripts/saleUnitItemHandler");
const employeeHandler = require("@/scripts/employeeHandler");
const itemModifierHandler = require("@/scripts/itemModifierHandler")
const loyaltyHandler = require("@/scripts/loyalty/handler/loyaltyHandler")
const currencyHandler = require("@/scripts/currency/handler/currencyHandler")
// transaction
import PosTransactionModel from "@/scripts/commerce/model/PosTransaction"
// institute
const intHandler = require("@/scripts/instituteHandler")
// import PointModel from "@/scripts/loyalty/model/Point"
//indexeddb
const console = window.console
// 1
const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

if (!indexedDB) {
  window.console.log("IndexedDB could not be found in this browser.");
}
// 2
const request = indexedDB.open(instituteId, 1);
request.onerror = function (event) {
  window.console.error("An error occurred with IndexedDB");
  window.console.log(event, 'error');
};
let db;
request.onupgradeneeded = function () {
  //1
  db = request.result;
  db.createObjectStore(instituteId + "items", { keyPath: "id" });
  db.createObjectStore(instituteId + "itemPrice", { keyPath: "id" });
  db.createObjectStore(instituteId + "employee", { keyPath: "id" });
  db.createObjectStore(instituteId + "modifier", { keyPath: "id" });
  db.createObjectStore(instituteId + "campaign", { keyPath: "id" });
  db.createObjectStore(instituteId + "serving", { keyPath: "pk" });
  db.createObjectStore(instituteId + "invoices", { keyPath: "pk" });
  db.createObjectStore(instituteId + "preorders", { keyPath: "pk" });
};
let isIndDB = false
let loadIndex = false
request.onsuccess = function () {
//   console.log("Database opened successfully");
    isIndDB = true
    loadIndex = true
    db = request.result;
};
// const productHandler = require("@/scripts/productHandler")
const uomConversionHandler = require("@/scripts/uomConversionHandler");
export default {
    name: "MobilePos",
    data: () => ({
        dialogSaleUnitList: false,
        countObj: {},
        kendo: kendo,
        t: new PosTransactionModel({}),
        loadModifier: false,
        showBindItmLocal: false,
        // add item flow
        employeeDialog: false,
        modifierDialog: false,
        uomDialog: false,
        addItmFlowAt: 0,
        // end
        activeStore: localStorage.getItem(instituteId + 'commStore') != null ? JSON.parse(localStorage.getItem(instituteId + 'commStore')) : {},
        register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
        disPriceLevel: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        isEditDiscount: false,
        year: new Date().getFullYear(),
        isAccountLogo: true,
        dialogLoyalty: false,
        dialogPartner: false,
        dialogReward: false,
        rewardForm: false,
        dialogPromotion:false,
        dialogOrder: false,
        dialogCard: false,
        dialogCardPayment: false,
        dialog: false,
        dialogCash: false,
        dialogBank: false,
        dialogQrcode: false,
        dialogNote: false,
        infoBank: false,
        dialogInvoice: false,
        dialogDelivery: false,
        dialogSplit: false,
        dialogMerge: false,
        dialogCountGuest: false,
        viewCard: false,
        localMen: {
            coun: 0
        },
        localWomen: {
            coun: 0
        },
        items: '',
        pinActivate: false,
        pin: '',
        valid: true,
        incorrect: false,
        showLoading: false,
        loadingAlert: false,
        loadingColorAlert: '',
        loadingTextAlert: '',
        isHide: false,
        fullscreen: false,
        s: {
            timeIn: false,
            timeOut: false,
            orderNumber: false,
            cashierName: false,
            modifier: false,
            employee: false,
            note: false,
            numberPeople: false,
            saleUnitItem: false,
            favorite: false,
            takeAway: false,
            booking: false,
            userPin: false,
            sessionPin: false,
            allowNFCCard: false,
            allowSplitItem: false,
            kitchenKitCategories: [],
            allowKitchenKit: false,
            orderListCategries: [],
            allowOrderList: false,
            cancelReasons: [],
            allowCancelReason: false,
            decimal: 0,
            appNature: 'Retail',
            screenDisplay: 'Surface',
            receiptTemplate: '80mm',
            servings: [],
        },
        g: new generalSettingModel({}),
        items1: [
            {
                name: "coffee",
                title: "Coffee"
            },
            {
                name: "hotCoffee",
                title: "Hot Coffee"
            },
            {
                name: "iceCream",
                title: "Ice Cream"
            }
        ],
        items2: [
        ],
        merge1: [
            {
                name: "table1",
                title: "Table 01"
            },
            {
                name: "table2",
                title: "Table 02"
            },
            {
                name: "table3",
                title: "Table 03"
            }
        ],
        merge2: [
        ],
        //
        imgUrl: "https://s3-ap-southeast-1.amazonaws.com/images.banhji/",
        priceLevels: [],
        priceLevel: '',
        // price product
        lastPPKey: {},
        priceProducts: [],
        // itme
        lastPKey: {},
        allItems: [],
        loadingSetting: true,
        loadPrice: true,
        loadPullData: false,
        startFlowAt: 0,
        activePin: {
            name: '',
            pinCode: ''
        },
        // other function
        func: {
            reward: false,
            promotion: false,
            parksale: false,
            invoice: false,
            note: false,
            delivery: false,
            resetOrder: false,
            splitInv: false,
            clearOrder: false,
            saleUnit: false,
            countGuest: false,
            mergeInv: false,
            orderList: false,
            orderType: false, 
        },
        // guest
        guestCount: {
            localMen: 0,
            localWomen: 0,
            forMen: 0,
            forWomen: 0
        },
        // order type
        dialogOrderType: false,
        activeOrderType: {},
        isCategoryPage: false,
        // category
        categories: [],
        cateGroup: [],
        cateGroupData: [],
        subGroup: [],
        subGroupData: [],
        showCatePage: false,
        showItem: false,
        showGroupPage: false,
        showSupGroupPage: false,
        // pull data
        pullAsOf: localStorage.getItem(instituteId + 'commRPullDataAt') != null ? kendo.toString(new Date(parseInt(localStorage.getItem(instituteId + 'commRPullDataAt'))), 'yyyy-MM-dd h:m tt') : '',
        // line 
        lineDS: [],
        selectItem: {},
        loadingItmBlock: false,
        // 
        itemLine: new ItemLineModel({}),
        saleFormContent: saleFormContentModel,
        invoice: invoiceModel,
        taxListTotal: [],
        customerOtherChargeItem: [],
        mOtherCharge: [],
        pointAmount: 0,
        jRaw: [],
        coa: [],
        receivableAcc: [],
        //customer
        customerPoint: 0,
        customer: new CustomerModel({}),
        // keypayd
        activeKeyPad: false,
        activeQty: false,
        keyPadAmount: 0,
        activePrice: false,
        activeDiscount: false,
        discountItems: [],
        //sale unit
        dialogSaleUnit: false,
        saleUnits: [],
        saleUnitCates: [],
        showSaleUnitCate: false,
        saleUnitData: [],
        saleUnit: {
            id: 0
        },
        //employee
        employees: [],
        isEditEmp: false,
        //modifier
        modifiers: [],
        isEditModi: false,
        // sidebar
        minWidth: false,
        miniVariant: false,
        handOver: false,
        isApp: true,
        isAbsolute: false,
        pins: [],
        session: localStorage.getItem(instituteId + 'commSession') != null ? JSON.parse(localStorage.getItem(instituteId + 'commSession')):{},
        operator: localStorage.getItem(instituteId + 'commSession') != null ? JSON.parse(localStorage.getItem(instituteId + 'commSession')).name :'',
        sessionStart: kendo.toString(new Date(JSON.parse(localStorage.getItem(instituteId + 'commSession')).startDate), 'yyyy-MM-dd h:m tt'),
        itemUOMs: [],
        activeLine: {},
        showItems: false,
        paging: {},
        params: {},
        loadSGroup: false,
        loadGroup: false,
        loadEmp: false,
        loadItem: false,
        itemDatas: [],
        decimal: 0,
        //loyalty
        lcardNumber: '',
        activeLoyaltyCard: {},
        rewardPrograms: [],
        activeRPrograms: [],
        loadReward: false,
        dialogRProgram: false,
        activeReward: {},
        //partner
        partners: [],
        isLoadPartner: false,
        activePartner: {},
        partnerRefNum: '',
        dialogPartnerRef: false,
        //order type
        orderTypes: [],
        //modifier
        activeModi: [],
        modiCategories: [],
        isLoadModiCate: false,
        activeSelectModi: [],
        // amount to pay
        amtReil: 0,
        //currency
        baseRate: {},
        secondRate: {},
        selectRate: {},
        rates: [],
        currencies: [],
        exchange: {
            base: 0,
            secondary: 0
        },
        amtReceipt: 0,
        amountToReciept: 0,
        hasKHR: false,
        hasUSD: false,
        orderNumber: '',
        //payment
        allowPayCash: false,
        allowPayCard: false,
        allowPayBank: false,
        allowPayKHQR: false,
        //txn
        showLoadingTxn: false,
        isSale: 1,
        //pay by bank
        cardPay: {
            name: '',
            type: '',
            expireDate: new Date().toISOString().substr(0, 10),
            digit: ''
        },
        banks: commerceHandler.banks(),
        bankWallets: [],
        activePayBank: {},
        payBankRef: '',
        keyFunction: '',
        oldqty: 0,
        olddis: 0,
        oldprice: 0,
        //campaign
        loadCam: false,
        campaigns: [],
        txnNumberFormat: {},
        orderNumberFormat: {},
        //session
        activeSession: [],
        dialogCloseSession: false,
        isEndShift: false,
        dialogCashReconcile: false,
        noteDS: [],
        servings: [],
        invoices: [],
        preorders: [],
        txnorders: [],
        hasInv: false,
        invs: [],
        hasServing: false,
        servs: [],
        hasPre: false,
        pres: [],
        dialogPrint: false,
        printObj: {},
        dialogServingLine: false,
        dialogPayment: false,
        servingLines: [],
        activeServing: {},
        isServing: false,
        shiftObj: {},
        dialogShiftInfo: false,
        isEndSession: false,
        dialogSessionInfo: false,
        dialogPrintSession: false,
        loyalFunc: '',
        chFStart: 0,
        dialogLoyalSearch: false,
        chFlows: [],
        dialogRewardInfo: false,
        loyaltyObj: {},
        lsearchName: 'card',
        rewardAmount: 0,
        rewardObj: {},
        promotions: [],
        dialogCheckOut: false,
        isPromoFlow: false,
        dailogPhone: false,
        phoneNumber: '',
        isLoadOrderType: false,
        isPartnerPay: false,
        // preorder
        dialogPreOrder: false,
        // institute
        logoUrl: '',
        institute: {},
        //
        hasLoyaltyCard: false,
        loyaltyCustomer: {},
        // invoice
        invSearchText: '',
        invoiceLines: [],
        payInvoice: false,
        activeInvoice: {},
        invLines: [],
        // order
        orderSearchText: '',
        orderLines: [],
        ordLines: [],
        dialogOrderTxn: false,
        // point card
        pointCardPay: false,
        paidPointLine: [],
        // cash count
        isCountCash: false,
        // order
        isProcessOrder: false,
    }),
    methods: {
        // pay by point card
        payPointCard(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.pointCardPay = false
                return
            }
            this.pointCardPay =  true
            this.isSale = 0
            this.t.issuedDate = new Date()
            this.t.paidBy = 'Point'
            this.dialogLoyalSearch = true
        },
        loadExpirePoint(){
            this.showLoading = true
            let data = {
                expValue: {
                    ':pk': this.activeLoyaltyCard.pk + '#expirepoint',
                    ':currentDate': new Date().getTime().toString()
                },
                condition: 'pk = :pk and sk > :currentDate'
            }
            loyaltyHandler.generalGets(data).then(res=>{
                window.console.log(res, 'point expire res')
                let data = res.data.data.Items
                let expPoint = []
                let amtPay = this.t.total
                if(data.length >  0){
                    data.sort(function (a, b) {
                        return parseFloat(a.sk) - parseFloat(b.sk)
                    })
                    for(let i = 0; i < data.length; i++){
                        let ex = data[i]
                        let amt = amtPay
                        amtPay -= ex.point
                        if(amtPay < 0){
                            if(i == 0){
                                amt = this.t.total
                            }
                            expPoint.push({
                                pk: ex.pk,
                                sk: ex.sk,
                                point: ex.point,
                                paid: amt,
                                order: i + 1
                            })
                            break;
                        }else{
                            expPoint.push({
                                pk: ex.pk,
                                sk: ex.sk,
                                point: ex.point,
                                paid: ex.point,
                                order: i + 1
                            })
                        }
                    }
                    if(amtPay > 0){
                        expPoint.push({
                            pk: '',
                            sk: '',
                            point: this.activeLoyaltyCard.totalPoint,
                            paid: amtPay,
                            order: 0
                        })
                    }
                    expPoint.sort(function (a, b) {
                        return parseFloat(a.order) - parseFloat(b.order)
                    })
                    window.console.log(expPoint, 'exp point')
                    this.t.paidBy = 'Point'
                    this.t.pointPaid.pointLines = expPoint
                    // bill date
                    this.t.billDate = new Date()
                    this.isSale = 0 
                    this.saveTxn()
                }
            })
        },
        async savePointPay(){
            await this.loadExpirePoint()
            let data = this.activeLoyaltyCard
            this.t.pointPaid = {
                cardId: data.pk,
                name: data.activateName,
                cardNum: data.cardNumber,
                phoneNum: data.phoneNum,
                telegram: data.activateTelegram,
                pointPaid: this.t.total,
                totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                pointLines: []
            }
        },
        // phone number
        enterPhone(){
            if(this.phoneNumber != ''){
                this.dailogPhone = false
                this.showCheckOut()
            }else{
                this.$snotify.error(i18n.t('please_input_phone_number'))
            }
        },
        //loyalty
        savePoint(txnId,txnNum,type){
            this.rewardObj.txnId = txnId
            let data = {
                ...this.t.pointEarn,
                isPoint: true,
                txnId: txnId,
                instituteId: instituteId,
                store: this.activeStore.name,
                storePhone: this.activeStore.phone,
                type: type,
                txnNum: txnNum
            }
            if(type == 'paid'){
                data = {
                    ...this.t.pointPaid,
                    isPoint: true,
                    txnId: txnId,
                    instituteId: instituteId,
                    store: this.activeStore.name,
                    storePhone: this.activeStore.phone,
                    type: type,
                    txnNum: txnNum
                }
            }
            loyaltyHandler.pointCreate(data)
        },
        loadRankPoint(key){
            // this.showLoading = true
            let a = this.activeReward
            let startDate = kendo.toString(new Date(), 'yyyy-MMM-dd')
            let sd = new Date( startDate + ' 00:00:00').getTime().toString();
            let endDate = kendo.toString(new Date(), 'yyyy-MMM-dd')
            let ed = new Date(endDate + ' 23:59:59').getTime().toString();
            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            // window.console.log(startDate, sd, endDate, ed, a)
            if(a.rankPromotionBy == 'month'){
                var firstDay = new Date(y, m, 1);
                var lastDay = new Date(y, m + 1, 0);
                sd = new Date(firstDay).getTime().toString()
                let edFull = kendo.toString(new Date(lastDay), 'yyyy-MMM-dd')
                ed = new Date(edFull + ' 23:59:59').getTime().toString();
            }else if(a.rankPromotionBy == 'year'){
                sd = new Date('01-01-' + y + ' 00:00:00').getTime().toString();
                ed = new Date('31-12-' + y + ' 00:00:00').getTime().toString();
            }
            // window.console.log(new Date(sd), sd, new Date(ed), ed);
            let d = {
                epsAtt: {
                    ':pk': this.t.pointEarn.cardId + '#earnpoint',
                    ':startDate': sd,
                    ':endDate': ed,
                },
                keyCondition: 'pk = :pk and sk between :startDate and :endDate',
                key: key
            }
            loyaltyHandler.pointGets(d).then(res=>{
                window.console.log(res, 'res from point gets')
                let data = res.data.data.Items
                if(data.length > 0){
                    let tpoint = 0
                    data.forEach(d=>{
                        tpoint += parseFloat(d.point)
                    })
                    if(tpoint > 0){
                        window.console.log(tpoint, 'total point')
                        this.lineDS.forEach(d=>{
                            let dis = 0
                            //check overise
                            d.discount = 0
                            d.amount = (d.price * d.qty) + d.modifierAmt + d.tax
                            for (let i = 0; i < a.rankLines.length; i++) {
                                let va = a.rankLines[i]
                                // window.console.log(va, totalcom, 'va')
                                if(va.conditionType == '>'){
                                    if(tpoint > va.conditionAmount){
                                        window.console.log(tpoint, va.conditionAmount, va.rewardAmount, 'in condition')
                                        if(va.rewardType == 'percentage'){
                                            dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(va.rewardAmount)
                                        }
                                        break;
                                    } 
                                }else{
                                    if(tpoint >= va.conditionAmount){
                                        window.console.log(tpoint, va.conditionAmount, va.rewardAmount, 'in condition')
                                        if(va.rewardType == 'percentage'){
                                            dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(va.rewardAmount)
                                        }
                                        break;
                                    } 
                                }
                                
                            }
                            if(dis > 0){
                                d.discount = dis
                                d.amount -= dis
                                if(d.amount < 0){
                                    d.discount = (d.price * d.qty) + d.modifierAmt + d.tax
                                    d.amount = 0
                                }
                                window.console.log(a, 'active reward')
                                // this.$snotify.success('Campaign ' + cam.name + ' applyed!')
                                d.campaign = d.campaign || []
                                d.campaign.push({
                                    id: a.pk,
                                    name: a.name,
                                    code: a.code,
                                    amount: dis
                                })
                            }
                        })
                    }
                }
            })
        },
        selectReward(item){
            this.activeReward = item
        },
        async enterReward(){
            // let rType = 'earn'
            let a = this.activeReward
            if (Object.keys(a).length == 0) {
                this.$snotify.error(i18n.t('please_select_a_reward'))
                return
            }
            // check rank promotion
            let hasRank = false
            if(a.rankLines.length > 0){
                let t = 0
                a.rankLines.forEach(ran=>{
                    t += parseFloat(ran.conditionAmount)
                })
                // window.console.log(t, 'total rank condition')
                if(t>0){
                    hasRank = true
                }
            }
            if(hasRank){
                await this.loadRankPoint({})
            }
            // reward amount
            // window.console.log(this.t, a, 'enter reward')
            let rewardamt = 0
            let txnamount = (this.t.total * parseFloat(a.percentageSale)) / 100
            if(txnamount <= 0){
                this.$snotify.error(i18n.t('amount_not_enough_for_a_reward'))
                this.chFlow()
                return
            }else{
                rewardamt = txnamount / parseFloat(a.amountPerPoint)
            }
            // window.console.log(rewardamt + ' = point earn', txnamount + ' = txn amount' )
            if(rewardamt <= 0){
                this.$snotify.error(i18n.t('no_reward_for_this_transaction_amount'))
                this.chFlow()
                return
            }
            // check point expire
            let expire = ''
            if(a.isPointExpire){
                if(a.expireBy == 'day'){
                    let damt = 1
                    let c = parseInt(a.expireAmount)
                    if(!isNaN(c)) damt = c
                    let ad = new Date()
                    let b = ad.setDate(ad.getDate() + damt)
                    expire = b
                }else if(a.expireBy == 'month'){
                    let damt = 1
                    let c = parseInt(a.expireAmount)
                    if(!isNaN(c)) damt = c
                    let ad = new Date()
                    let b = ad.setMonth(ad.getMonth() + damt)
                    expire = b
                }else{
                    let damt = 1
                    let c = parseInt(a.expireAmount)
                    if(!isNaN(c)) damt = c
                    let ad = new Date()
                    let b = ad.setFullYear(ad.getFullYear() + damt)
                    expire = b
                }
            }
            this.t.pointEarn.pointEarn = parseFloat(rewardamt.toFixed(2))
            this.t.pointEarn.pointExpire = expire
            this.dialogRewardInfo = true
            // window.console.log(this.t.pointEarn, 'point earn')
        },
        saveReward(){
            this.dialogRewardInfo = false
            this.dialogRProgram = false
            this.dialogLoyalSearch = false
            this.dialogLoyalty = false
            this.chFlow()
        },
        searchLoyaltyCard(){
            this.dialogRProgram = false
            this.activeReward = {}
            // make cambodia phone
            // if(this.lsearchName == 'phone'){
            //     let a = this.lcardNumber.split("")
            //     // window.console.log(a)
            //     if(a[0] == 0){
            //         let b = this.lcardNumber.substring(1);
            //         let c = '+855'+b
            //         this.lcardNumber = c
            //         // window.console.log(b, c)
            //     }
            // }
            let searchData = {
                type: this.lsearchName,
                searchNum: this.lcardNumber
            }
            this.showLoading = true
            loyaltyHandler.searchCard(searchData).then((res) => {
                this.showLoading = false
                let d = res.data.data 
                if(d.length > 0){
                    let data = d[0]
                    this.activeLoyaltyCard = data
                    this.hasLoyaltyCard = true
                    if(this.pointCardPay){
                        // check total card
                        window.console.log(this.activeLoyaltyCard, this.t.total, 'point and transction amt')
                        if(this.activeLoyaltyCard.totalPoint >= this.t.total){
                            this.savePointPay()
                        }else{
                            this.$snotify.error(i18n.t('point_balance_not_enough'))
                        }
                    }else{
                        this.loyaltyCustomer = {
                            id: data.customerId,
                            name: data.activateName,
                        }
                        this.customer.name = data.activateName
                        this.customer.id = data.customerId
                        this.t.pointEarn = {
                            cardId: data.pk,
                            name: data.activateName,
                            cardNum: data.cardNumber,
                            phoneNum: data.phoneNum,
                            loyaltyName: this.g.loyaltyProgram.name,
                            loyaltyId: this.g.loyaltyProgram.pk,
                            telegram: data.activateTelegram,
                            totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                            pointEarn: 0,
                            pointExpire: ''
                        }
                        this.dialogLoyalty = false 
                        this.loadLoyaltyProgam()
                    }
                }else{
                    this.lcardNumber = ''
                    this.$snotify.error(i18n.t('no_data_found'))
                }
            })
        },
        loadLoyaltyProgam(){
            this.showLoading = true
            loyaltyHandler.loyaltyGet(this.g.loyaltyProgram.pk).then(res=>{
                this.showLoading = false
                if(res.data.data.length > 0){
                    this.activeReward = res.data.data[0]
                    this.enterReward()
                }else{
                    this.$snotify.error(i18n.t('this_store_has_no_loyalty_program'))
                    this.chFlow()
                }
            })
        },
        searchLoyaltyCardBk(){
            this.dialogRProgram = false
            this.activeReward = {}
            // make cambodia phone
            // if(this.lsearchName == 'phone'){
            //     let a = this.lcardNumber.split("")
            //     // window.console.log(a)
            //     if(a[0] == 0){
            //         let b = this.lcardNumber.substring(1);
            //         let c = '+855'+b
            //         this.lcardNumber = c
            //         // window.console.log(b, c)
            //     }
            // }
            let searchData = {
                type: this.lsearchName,
                searchNum: this.lcardNumber
            }
            this.showLoading = true
            loyaltyHandler.searchCard(searchData).then((res) => {
                this.showLoading = false
                let d = res.data.data 
                if(d.length > 0){
                    let data = d[0]
                    this.activeLoyaltyCard = data
                    let valid = false
                    // check store
                    // data.stores.forEach(s=>{
                    //     if(s.pk == this.activeStore.pk){
                    //         valid = true
                    //     }
                    // })
                    // check status
                    // if (data.status != 'activate') {
                    //     valid = false
                    // }
                    // check program
                    if (data.rewardPrograms.length == 0) {
                        valid = false
                    }
                    if (valid) {
                        this.activeLoyaltyCard = data
                        this.customer.name = data.activateName
                        this.customer.id = data.customerId
                        this.t.loyaltyCard = {
                            id: data.pk,
                            name: data.activateName,
                            number: data.cardNumber,
                            phoneNum: data.phoneNum
                        }
                        let ar = []
                        data.rewardPrograms.forEach(p=>{
                            ar.push(p.pk)
                        })
                        // window.console.log(ar, 'pk array')
                        this.activeRPrograms = []
                        this.rewardPrograms.forEach(rp => {
                            if($.inArray(rp.pk, ar) != -1) {
                                let td = new Date().getTime()
                                // check end date
                                let venddate = true
                                if(rp.isEndDate){
                                    let ed = new Date(rp.endDate + ' 23:59:59').getTime()
                                    if(td > ed){
                                        venddate = false
                                    }
                                }
                                // check effective date
                                let ef = new Date(rp.effectiveDate + ' 00:00:00').getTime()
                                if(td < ef){
                                    venddate = false
                                }
                                // check store
                                let vstore = false
                                rp.stores.forEach(rs=>{
                                    if(rs.pk == this.activeStore.pk){
                                        vstore = true
                                    }
                                })
                                if(venddate && vstore){
                                    this.activeRPrograms.push(rp)
                                }
                            }
                        })
                        if(this.activeRPrograms.length > 0){
                            this.dialogLoyalty = false 
                            this.dialogRProgram = true
                        }else{
                            this.$snotify.error(i18n.t('no_reward_available'))
                        }
                    } else {
                        this.lcardNumber = ''
                        this.$snotify.error(i18n.t('no_data_found'))
                    }
                }else{
                    this.lcardNumber = ''
                    this.$snotify.error(i18n.t('no_data_found'))
                }
            })
        },
        lsearchBy(name){
            this.lsearchName = name
            this.lcardNumber = ''
            this.dialogLoyalty = true
        },
        //loyalty
        skipLoyalty(){
            // this.dialogLoyalty = false
            // this.dialogRProgram = false
            // this.checkOrderFlow()
            this.dialogLoyalty = false
            this.dialogLoyalSearch = false
            this.chFlow()
        },
        // skip promotion
        cancelPromotion(){
            this.dialogPromotion = false
            if(this.isPromoFlow){
                this.chFlow()
            }
        },
        //check out block
        checkOut(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.dialogCash = false
                return
            }
            this.chFlows = []
            if(this.g.checkOutFlow == 2){
                this.chFlows = ["loyalty", "promotion", "payment"]
                this.chFStart = 0
                this.chFlow()
            }else if(this.g.checkOutFlow == 1){
                this.chFlows = ["payment"]
                this.chFStart = 0
                this.chFlow()
            }else if(this.g.checkOutFlow == 3){
                this.chFlows = ["promotion", "loyalty", "payment"]
                this.chFStart = 0
                this.chFlow()
            }else if(this.g.checkOutFlow == 4){
                this.chFlows = ["loyalty", "payment"]
                this.chFStart = 0
                this.chFlow()
            }else if(this.g.checkOutFlow == 5){
                this.chFlows = ["promotion", "payment"]
                this.chFStart = 0
                this.chFlow()
            }
        },
        chFlow(){
            // window.console.log(this.startFlowAt, 'start order')
            if(this.chFlows.length > 0){
                if(this.chFStart < this.chFlows.length){
                    this.chFflow(this.chFlows[this.chFStart])
                    this.chFStart += 1
                }
            }
        },
        async chFflow(func){
            // window.console.log(func, 'check out flow')
            let self = this
            let pro = []
            if (func != '') {
                switch(func) {
                    case 'promotion':
                        // window.console.log(this.campaigns, '')
                        this.promotions = []
                        this.isPromoFlow = true
                        for(let i = 0; i < this.campaigns.length; i++){
                            let cam = self.campaigns[i]
                            // window.console.log(cam, 'prmotion')
                            let camValid = 1
                            // check type
                            if(cam.type != "manual"){
                                // window.console.log(i, 'wrong type')
                                camValid -= 1
                            }
                            // check store 
                            if(cam.stores.length > 0){
                                let hasStore = false
                                cam.stores.forEach(s=>{
                                    if(s.pk == self.activeStore.pk){
                                        hasStore = true
                                    }
                                })
                                if(!hasStore) break;
                            }else{
                                window.console.log(i, 'wrong store')
                                camValid -= 1
                            }
                            // check time
                            let validTime = true
                            if(cam.isTiming){
                                validTime = false
                                let curTime = new Date().getTime()
                                let timeF = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeFrom
                                let timeFrom = new Date(timeF).getTime()
                                let timeT = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeTo
                                let timeTo = new Date(timeT).getTime()
                                if(curTime >= timeFrom && curTime <= timeTo){
                                    validTime = true
                                }
                                window.console.log(timeTo, timeFrom, curTime, 'compare time')
                            }
                            if(!validTime){
                                window.console.log(i, 'wrong time')
                                camValid -= 1
                            }
                            // check price level
                            let validPriceLevel = false
                            if(cam.priceLevel.id == this.g.defaultPriceLevel){
                                validPriceLevel = true
                            }
                            if(!validPriceLevel){
                                window.console.log(i, 'wrong price level')
                                camValid -= 1
                            }
                            // check rule base
                            let validRule = false
                            // customer base
                            if(cam.ruleBase == 'customer'){
                                if(cam.ruleCustomerBaseType == 'specific'){
                                    if(cam.ruleCustomers.length > 0){
                                        cam.ruleCustomers.forEach(rcus=>{
                                            if(rcus.customer.id == self.g.defaultCustomer.id){
                                                validRule = true
                                            }
                                        })
                                    }
                                }else if(cam.ruleCustomerBaseType == 'customerType'){
                                    if(cam.ruleCustomerTypes.length > 0){
                                        cam.ruleCustomerTypes.forEach(rcus=>{
                                            if(rcus.id == self.g.defaultCustomer.customerType.id){
                                                validRule = true
                                            }
                                        })
                                    }
                                }else{
                                    validRule = true
                                }
                            // product base
                            }else{
                                if(cam.ruleProductBaseOn == 'specific'){
                                    if(cam.ruleProduct.length > 0){
                                        cam.ruleProduct.forEach(rcus=>{
                                            self.lineDS.forEach(lin=>{
                                                if(rcus.item.id == lin.itemId){
                                                    validRule = true
                                                }
                                            })
                                        })
                                    }
                                }else if(cam.ruleProductBaseOn == 'category'){
                                    if(cam.ruleProductCategory.length > 0){
                                        cam.ruleProductCategory.forEach(rcus=>{
                                            self.lineDS.forEach(lin=>{
                                                if(rcus.category.id == lin.categoryId){
                                                    validRule = true
                                                }
                                            })
                                        })
                                    }
                                }else if(cam.ruleProductBaseOn == 'group'){
                                    if(cam.ruleProductGroup.length > 0){
                                        cam.ruleProductGroup.forEach(rcus=>{
                                            self.lineDS.forEach(lin=>{
                                                if(rcus.group.id == lin.groupId){
                                                    validRule = true
                                                }
                                            })
                                        })
                                    }
                                }else{
                                    validRule = true
                                }
                            }
                            if(!validRule){
                                window.console.log(i, 'wrong invalid rule')
                                camValid -= 1
                            }
                            if(camValid >= 1){
                                pro.push(cam)
                            }
                            // self.promtions.push(cam)
                        }
                        this.promotions = pro
                       
                        if(this.promotions.length > 0){
                            this.dialogPromotion = true
                        }else{
                            this.chFlow()
                        }
                        // window.console.log(this.promotions, pro, this.promotions.length, this.dialogPromotion, 'promtoin blocl')
                        break;
                    case 'loyalty':
                        this.dialogLoyalSearch = true
                        break;
                    case 'payment': 
                        this.t.issuedDate = new Date()
                        this.t.paidBy = 'Cash'
                        this.t.itemLines = this.lineDS
                        this.t.customer = {
                            id: this.g.defaultCustomer.id,
                            name: this.g.defaultCustomer.name
                        }
                        this.createPrintReceipt(this.t)
                        await this.loadTxnNumber()
                        this.dialogPayment = true
                        break;
                    default:
                        // code block
                }
            }
        },
        loyaltySelect(func){
            this.loyalFunc = func
            this.dialogLoyalty = true
        },
        dateFormat(v){
            return kendo.toString(new Date(v), 'dd-MMM-yyyy hh:mm tt')
        },
        closePayment(){
            if(this.isServing){
                this.resetOrder()
                this.isServing = false
            }
            this.dialogPayment = false
        },
        createPrintReceipt(printObj){
            // window.console.log(printObj)
            printObj.secondRate = this.secondRate
            printObj.baseRate = this.baseRate
            this.printObj = printObj
            this.$emit("onUpdate", printObj);
        },
        // check out
        async showCheckOut(){
            await this.loadTxnNumber()
            this.dialogPayment = true
        },
        // serving line
        async serveCheckOut(data){
            let act = this.servings.filter((o)=>{return o.pk == data.pk});
            // window.console.log(act);
            if(act.length > 0){
                this.activeServing = act[0]
                this.activeServing.txnId = data.pk
                this.activeServing.id = ''
                let itmLine = act[0].itemLines
                if(act[0].itemLines.length == 0){
                    let d = {
                        sk: data.pk,
                        pk: 'txnline-',
                        key: {}
                    }
                    await commerceHandler.genGets(d).then(res => {
                        // window.console.log(res, 'response serving')
                        if(res.data.data.Items.length > 0){
                            itmLine = res.data.data.Items
                            this.updateLineIndxDB(data.pk, 'serving', itmLine)
                        }
                    })
                }
                itmLine.forEach(l=>{
                    l.uom.toUOM = l.uom
                })
                this.activeServing.itemLines = itmLine
                this.createPrintReceipt(new PosTransactionModel(this.activeServing))
                this.lineDS = itmLine
                this.t = new PosTransactionModel(this.activeServing)
                this.t.txnId = data.pk
                this.t.type = 'sale'
                this.isServing = true
            }
            // this.showCheckOut()
            this.checkOut()
        },
        delIndxdb(key, tbname, func){
            // window.console.log(key, tbname, func, 'del db')
            const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
            const request = objectStore.delete(key);
            let self = this
            request.onsuccess = ()=> {
                // window.console.log('db deleted!')
                if(func == 'serving'){
                    self.bindServing()
                }else if(func == 'invoice'){
                    self.bindInvoice()
                    setTimeout(()=>{
                        this.searchInv()
                    }, 500)
                }
            }
        },
        updateLineIndxDB(key, tbname, line){
            // window.console.log(key, tbname, line, 'update db')
            const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
            const request = objectStore.get(key);
            request.onsuccess = ()=> {
                const student = request.result;
                // Change the name property
                student.itemLines = line;
                // Create a request to update
                const updateRequest = objectStore.put(student);
                updateRequest.onsuccess = () => {
                    // window.console.log('db updated!')
                }
            }
        },
        showServingTxn(){
            this.servingLines = []
            if(this.servings.length > 0){
                this.servings.forEach(s=>{
                    let d = new Date(s.orderDate)
                    let t = kendo.toString(d, 'hh:ss:tt')
                    this.servingLines.push({
                        pk: s.pk,
                        total: s.total,
                        saleUnit: Object.keys(s.saleUnit).length > 0 ?  Object.keys(s.saleUnit.name).length > 0 ? s.saleUnit.name : '' : '',
                        user: Object.keys(s.pinUser).length > 0 ? s.pinUser.name: '',
                        hour: t
                    })
                })
                // window.console.log(this.servingLines, 'serve line')
                this.dialogServingLine = true
            }else{
                this.$snotify.error(i18n.t('no_serving'))
            }
        },
        showPreOrderTxn(){
            if(this.preorders.length > 0){
                window.console.log(this.preorders, 'preorders')
                this.$emit("onUpdate", this.preorders);
                this.dialogPreOrder = true
            }else{
                this.$snotify.error(i18n.t('no_serving'))
            }
        },
        // order
        showOrderTxn(){
            if(this.txnorders.length > 0){
                // this.$emit("onUpdate", this.invoices);
                this.orderSearchText = ''
                this.searchOrder()
                this.dialogOrderTxn = true
            }else{
                this.$snotify.error(i18n.t('no_order'))
            }
        },
        searchOrder(){
            if(this.orderSearchText != ''){
                // window.console.log(this.invoices)
                let pres = []
                this.ordLines.forEach(pr=>{
                    let phone = pr.namePhone.toLowerCase() || ''
                    if(phone != ''){
                        // window.console.log(phone, this.invSearchText)
                        if(phone.indexOf(this.orderSearchText.toLowerCase()) !== -1){
                            pres.push(pr)
                        }
                    }
                })
                this.orderLines = pres
            }else{
                let lines = []
                this.txnorders.forEach(inv=>{
                    lines.push({
                        pk: inv.pk,
                        issuedDate: inv.issuedDate,
                        customer: {
                            name: inv.customer.name
                        },
                        total: inv.total,
                        phoneNumber: inv.phoneNumber,
                        txnNumber: inv.txnNumber,
                        namePhone: inv.customer.name + ' (' + inv.phoneNumber + ')'
                    })
                })
                // window.console.log(lines, 'invlines')
                this.ordLines = lines
                this.orderLines = lines
            }
        },
        ordRowNumber(dataItem) {
            let ds = this.$refs.gridOrderTxn.kendoWidget(),
                index = ds.indexOf(dataItem);
            return index + 1;
        },
        // invoice
        async cashReceipt(e) {
            e.preventDefault();
            const grid = $("#gridInvoice").data("kendoGrid"),
                dataItema = grid.dataItem($(e.currentTarget).closest("tr"));
            // window.console.log(dataItem, 'inv line');
            let inv = this.invoices.filter((o) => {return o.pk == dataItema.pk})
            let dataItem = inv[0]
            let itmLine = dataItem.itemLines
            if(dataItem.itemLines.length == 0){
                let d = {
                    sk: dataItem.pk,
                    pk: 'txnline-',
                    key: {}
                }
                await commerceHandler.genGets(d).then(res => {
                    // window.console.log(res, 'response serving')
                    if(res.data.data.Items.length > 0){
                        itmLine = res.data.data.Items
                        this.updateLineIndxDB(dataItem.pk, 'invoices', itmLine)
                    }
                })
            }
            itmLine.forEach(l=>{
                l.uom.toUOM = l.uom
            })
            dataItem.itemLines = itmLine
            this.activeInvoice = dataItem
            this.createPrintReceipt(new PosTransactionModel(dataItem))
            this.lineDS = itmLine
            this.t = new PosTransactionModel(dataItem)
            this.t.txnId = dataItem.pk
            this.t.type = 'sale'
            // window.console.log(this.t, this.lineDS, 'inv cash receipt')
            this.payInvoice = true
            this.checkOut()
        },
        invRowNumber(dataItem) {
            let ds = this.$refs.gridInvoice.kendoWidget(),
                index = ds.indexOf(dataItem);
            return index + 1;
        },
        searchInv(){
            if(this.invSearchText != ''){
                // window.console.log(this.invoices)
                let pres = []
                this.invLines.forEach(pr=>{
                    let phone = pr.namePhone.toLowerCase() || ''
                    if(phone != ''){
                        // window.console.log(phone, this.invSearchText)
                        if(phone.indexOf(this.invSearchText.toLowerCase()) !== -1){
                            pres.push(pr)
                        }
                    }
                })
                this.invoiceLines = pres
            }else{
                let lines = []
                this.invoices.forEach(inv=>{
                    lines.push({
                        pk: inv.pk,
                        issuedDate: inv.issuedDate,
                        customer: {
                            name: inv.customer.name
                        },
                        total: inv.total,
                        phoneNumber: inv.phoneNumber,
                        txnNumber: inv.txnNumber,
                        namePhone: inv.customer.name + ' (' + inv.phoneNumber + ')'
                    })
                })
                // window.console.log(lines, 'invlines')
                this.invLines = lines
                this.invoiceLines = lines
            }
        },
        showInvoice(){
            if(this.invoices.length > 0){
                // this.$emit("onUpdate", this.invoices);
                this.invSearchText = ''
                this.searchInv()
                this.dialogInvoice = true
            }else{
                this.$snotify.error(i18n.t('no_invoice'))
            }
        },
        selectLine(d){
            // window.console.log(d, 'select line')
            this.activeLine = d
        },
        //indexeddb
        //end indexddb
        // sidebar
        onMouseOver() {
            if (this.miniVariant) {
                this.isAccountLogo = true;
                this.backHoverColor = "";
            }
            this.minWidth = false;
        },
        onMouseLeave: function () {
            if (this.miniVariant) {
                this.isAccountLogo = false;
                this.backHoverColor = "#F8F8F9 !important";
            }
            this.minWidth = false;
        },
        hideSidebar() {
            this.miniVariant = !this.miniVariant;
            this.miniVariant ? (this.handOver = true) : (this.handOver = false);
            if (this.miniVariant) {
                this.isAbsolute = true;
                this.isAccountLogo = true;
                this.isApp = false;

                // window.console.log(this.miniVariant,'abc')
            } else {
                this.isAbsolute = false;
                this.isAccountLogo = false;
                this.isApp = true;
            }
            this.isAccountLogo = true;
        },
        // guest count
        guestIncLocalMen(){
            this.guestCount.localMen += 1
        },
        guestDecLocalMen(){
            if(this.guestCount.localMen > 0){
                this.guestCount.localMen -= 1
            }
        },
        guestIncLocalWomen(){
            this.guestCount.localWomen += 1
        },
        guestDecLocalWomen(){
            if(this.guestCount.localWomen > 0){
                this.guestCount.localWomen -= 1
            }
        },
         guestIncForMen(){
            this.guestCount.forMen += 1
        },
        guestDecForMen(){
            if(this.guestCount.forMen > 0){
                this.guestCount.forMen -= 1
            }
        },
        guestIncForWomen(){
            this.guestCount.forWomen += 1
        },
        guestDecForWomen(){
            if(this.guestCount.forWomen > 0){
                this.guestCount.forWomen -= 1
            }
        },
        skipGuestCount(){
            this.dialogCountGuest = false
            this.resetGuest()
            this.checkOrderFlow()
        },
        enterGuestCount(){
            this.dialogCountGuest = false
            this.t.guestCount = this.guestCount
            this.checkOrderFlow()
        },
        // partner
        skipPartner(){
            this.dialogPartner = false
            this.dialogPartnerRef = false
            this.checkOrderFlow()
        },
        skipPartnerRef(){
            this.dialogPartner = false
            this.dialogPartnerRef = false
            this.checkOrderFlow()
        },
        async enterPartnerRef(){
            let p = this.activePartner
            this.t.partner = {
                id: p.pk,
                name: p.name,
                refNum: this.partnerRefNum
            }
            this.t.customer = {
                id: p.pk,
                name: p.name,
            }
            this.dialogPartner = false
            this.dialogPartnerRef = false
            this.isPartnerPay = true
            if(p.settlementMethod == 'credit'){
                this.saveInvoice()
            }else{
                await this.loadTxnNumber()
                this.dialogPayment = true
            }
        },
        // order type
        skipOrderType(){
            this.dialogOrderType = false
            this.checkOrderFlow()
        },
        enterOrderType(e){
            this.activeOrderType = e
            this.t.orderType = e
            this.dialogOrderType = false
            this.checkOrderFlow()
        },
        // partner
        skipSaleUnit(){
            this.dialogSaleUnit = false
            this.dialogOrder = false
            this.checkOrderFlow()
        },
        enterSaleUnit(){
            if(Object.keys(this.t.saleUnit).length == 0){
                this.$snotify.error(i18n.t('please_select_sale_unit'))
                return
            }
            this.dialogSaleUnit = false
            this.dialogOrder = false
            this.checkOrderFlow()
        },
        backSaleUnit(){
            this.showSaleUnitCate = true
        },
        hasHistory () { 
            return window.history.length > 2 
        },
        cancel() {
            this.$swal({
                title: i18n.t("msg_title_warning"),
                text: i18n.t("msg_discard"),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: i18n.t("cancel"),
                confirmButtonColor: "#4d4848",
                cancelButtonColor: "#eb0238",
                confirmButtonText: i18n.t("discard"),
            }).then((result) => {
                if (result.value) {
                    // this.clear();
                    this.$router.go(-1);
                }
            });
        },
        close() {
           this.$router.go(-1);
        },
        selectPartner(d){
            window.console.log(d, 'selected partner')
            if(d.campaigns.length > 0){
                d.campaigns.forEach(cam=>{
                    this.campaigns.forEach(c=>{
                        if(c.pk == cam.campaign.id){
                            // window.console.log(c, 'paartner campaign')
                            this.applyPromotion(c)
                        }
                    })
                })
            }
            this.activePartner = d
            this.dialogPartnerRef = true;
            this.dialogPartner = false;
        },
        ToPromotionForm(){
            this.dialogPromotion = false;
        },
        ToRewardForm(){
            this.rewardForm = true;
            this.dialogReward = false;
        },
        LoyaltyToSale(){
            this.dialogPartner = true;
            this.dialogLoyalty = false;
        },
        InfoBank(){
            this.infoBank = true;
        },
        PartnerToSale(){
            window.location.reload()
        },
        cardPayment(type){
            this.dialogCardPayment = true;
            this.cardPay.type = type
        },
        clickMe(data) {
            // alert(data.link)
            this.$router.push(`${data.link}`);
            //this.$event.target.classList.toggle(active)
            //eslint-disable-next-line no-console
            console.log(data.link)
            //eslint-disable-next-line no-console
            //console.log(data)
        }, 
        hideTabs(){
            this.isHide = !this.isHide;
        },
        toggle() {
            this.$fullscreen.toggle(document.getElementsByTagName("body")[0], {
                wrap: false,
                callback: this.fullscreenChange,
            });
        },
        fullscreenChange(fullscreen) {
            this.fullscreen = fullscreen;
        },
        // pin
        pinClick(num){
            this.incorrect = false
            if(num == 'clear'){
                this.pin = ''
            }else{
                this.pin = this.pin + num.toString()
            }
        },
        searchPin(){
            // window.console.log(this.pin, 'pin')
            let activePin = this.pins.filter((o) => {return o.pinCode == this.pin})
            // window.console.log(activePin, 'actvie pin')
            if(activePin.length > 0){
                this.activePin = activePin[0]
                this.t.pinUser = {
                    name: activePin[0].name,
                    id: activePin[0].pk
                }
                this.pin = ''
                this.pinActivate = false
                if(this.isEndShift){
                    this.saveEndShift()
                }else if(this.isEndSession){
                    this.saveSession()
                }else if(this.isCountCash){
                    this.loadCashCount()
                    this.isCountCash = false
                }else{
                    this.checkOrderFlow()
                }
            }else{
                this.pin = ''
                this.activePin = {
                    name: '',
                    pinCode: ''
                }
            }
        },
        // split
        insert1(event) {
            this.items1.splice(event.index, 0, event.data);
        },
        insert2(event) {
            this.items2.splice(event.index, 0, event.data);
        },
        remove(array, value) {
            let index = array.indexOf(value);
            array.splice(index, 1);
        },
        // marge
        insert3(event) {
            this.merge1.splice(event.index, 0, event.data);
        },
        insert4(event) {
            this.merge2.splice(event.index, 0, event.data);
        },
        // data
        async loadPriceLevel(num) {
            this.showLoading = true;
            const strFilter = "?nature=sale";
            priceLevelHandler.get(strFilter).then((res) => {
                this.showLoading = false;
                this.priceLevels = res;
                localStorage.setItem(instituteId + 'pricelevel', JSON.stringify(res));
                if(num == 1){
                    this.loadProductPriceLevel()
                }
                //chec setting
                if(this.g.allowSelectPriceLevel == false){
                    this.loadSPPLevel(this.g.defaultPriceLevel)
                }
            });
        },
        loadSPPLevel(id){
            this.priceProducts = []
            let d = {
                key: {},
                priceLevelId: id
            }
            let isLast = true
            commerceHandler.getPLItem(d).then((res) => {
                let data = res.data.data
                if(data.Items.length > 0){
                    data.Items.forEach(e => {
                        this.priceProducts.push(e)
                    })
                }
                if(data.hasOwnProperty('LastEvaluatedKey')){
                    this.lastPPKey = data.LastEvaluatedKey
                    this.getPriceProMore(this.lastPPKey, id, isLast)
                }else{
                    if(isLast){
                        this.bindPriceProduct()
                    }
                }
            });
        },
        loadProductPriceLevel(){
            this.priceProducts = []
            let i = 1
            let isLast = false
            this.priceLevels.forEach(e => {
                i++
                if(i == this.priceLevels.length){
                    isLast = true
                }
                let d = {
                    key: {},
                    priceLevelId: e.id
                }
                commerceHandler.getPLItem(d).then((res) => {
                    let data = res.data.data
                    // window.console.log(data, 'itm pp')
                    if(data.Items.length > 0){
                        data.Items.forEach(e => {
                            this.priceProducts.push(e)
                        })
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.lastPPKey = data.LastEvaluatedKey
                        this.getPriceProMore(this.lastPPKey, e.id, isLast)
                    }else{
                        if(isLast){
                            this.bindPriceProduct()
                        }
                    }
                });
            })
        },
        getPriceProMore(key, id, isLast){
            let d = {
                key: key,
                priceLevelId: id
            }
            commerceHandler.getPLItem(d).then((res) => {
                let data = res.data.data
                if(data.Items.length > 0){
                    // this.bindPriceProduct(data.Items)
                    data.Items.forEach(e => {
                        this.priceProducts.push(e)
                    })
                }
                if(data.hasOwnProperty('LastEvaluatedKey')){
                    this.lastPPKey = data.LastEvaluatedKey
                    this.getPriceProMore(this.lastPPKey, id, isLast)
                }else{
                    if(isLast){
                        this.bindPriceProduct()
                    }
                }
            })
        },
        bindPriceProduct(){
            if(isIndDB){
                let transaction = db.transaction(instituteId + "itemPrice", "readwrite");
                let inx = transaction.objectStore(instituteId + "itemPrice");
                let j = 0
                this.priceProducts.forEach(e => {
                    inx.put({
                        id: e.sk,
                        ...e
                    })
                    j++
                    if(j == this.priceProducts.length){
                        // window.console.log(inx, 'bind price')
                        this.loadPrice = true 
                        this.checkPullDataComplete()
                    }
                })
            }
        },
        // Setting
        async loadSetting(){
            commerceHandler.settingGet(this.register.pk).then((res) => {
                let d = res.data.data
                this.loadingSetting = true
                this.checkPullDataComplete()
                // for retail
                let set = d
                if(set.length > 0){
                    this.addSetting(d[0])
                    this.g = new generalSettingModel(d[0])
                    //check sale unit
                    if (this.g.saleUnitItem) {
                        this.loadSaleUnitCategory()
                        this.loadSaleUnitItems()
                    }
                    //check employee
                    this.loadEmp = false
                    if(this.g.employee){
                        this.loadEmployee()
                    }else{
                        this.loadEmp = true
                        this.checkPullDataComplete()
                    }
                    //check modifier
                    this.loadModifier = false
                    if(this.g.modifier){
                        this.loadModifiers()
                    }else{
                        this.loadModifier = true
                        this.checkPullDataComplete()
                    }
                    //check pricelevel
                    if(this.g.allowSelectPriceLevel){
                        this.loadPriceLevel(1)
                    }else{
                        this.loadPriceLevel(2)
                    }
                }else{
                    this.gotoSetting()
                }
            })
        },
        addSetting(data){
            localStorage.setItem(instituteId + 'commRSetting', JSON.stringify(data));
        },
        gotoSetting(){
            this.$swal({
                position: 'center',
                icon: 'warning',
                title: i18n.t('please_configure_setting'),
                showConfirmButton: true,
                confirmButtonColor: '#4d4848',
                cancelButtonColor: '#eb0238',
                confirmButtonText: i18n.t('back_to_setting')
            }).then((result) => {
                if (result.value) {
                    window.history.go(-1)
                    return false
                }
            })
        },
        isshowItem(){
            this.showCatePage = false
            this.showGroupPage = false
            this.showSupGroupPage = false
            this.showItem = true
        },
        // Category
        searchItemByCate(cateId){
            // window.console.log(cateId)
            // this.bindItems()
            this.items = this.itemDatas
            this.loadingItmBlock = true
            setTimeout(() => {
                let itm = this.items.filter((o) => { return o.categoryId == cateId })
                if(itm.length > 0){
                    this.items = itm
                    this.loadingItmBlock = false
                    if(this.g.itemFlow == 1){
                        this.goGroup(cateId)
                    }else{
                        this.isshowItem()
                    }
                }else{
                    this.loadingItmBlock = false
                    this.msgNoData()
                }
            }, 500)
            // window.console.log(this.g, this.categories, 'seting')
            // let cate = this.categories.filter((c) => {return c.id == cateId})
            // let type = cate[0].type.name
            // if(cate[0].type.name == 'Product' && this.g.productFlow != 1){
            //     type = 'Variant'
            // }   
            // this.params = {
            //     ctid: cateId,
            //     grid: '',
            //     sgid: '',
            //     key: this.paging,
            //     search: '',
            //     searchAs: 0,
            //     type: type,
            // };
            // this.showLoading = true;
            // productHandler.center(this.params).then((res) => {
            //     this.showLoading = false;
            //     if (res.data.statusCode === 200) {
            //         let d = res.data.data
            //         d.forEach(e=>{
            //             this.items.push({
            //                 id: e.id,
            //                 name: e.name,
            //                 sku: e.sku,
            //                 saleDescription: e.saleDescription,
            //                 price: e.price,
            //                 categoryId: e.categoryId,
            //                 category: cate[0],
            //                 borderColor: 'border: 3px solid ' + cate[0].color,
            //                 titleColor: 'background-color: ' + cate[0].color,
            //                 groupId: e.group.id,
            //                 subGroupId: e.subGroup.id,
            //                 img: e.thumb != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + e.thumb : './images/default.png',
            //                 uom: e.uom,
            //                 item: e,
            //                 priceLevel: e.priceLevel
            //             })
            //         })
            //         // this.items = d
            //         window.console.log(this.items, 'res')
            //         // if(this.g.itemFlow == 1){
            //         //     this.goGroup(cateId)
            //         // }else{
            //             this.isshowItem()
            //         // }
            //     }
            // });
        },
        async loadCategory() {
            const param = {
                pattern: "grp",
            };
            groupHandler.getAllv2(param).then((res) => {
                localStorage.setItem(instituteId + 'group', JSON.stringify(res.data.data));
                this.loadGroup = true
                this.checkPullDataComplete()
            });
            let p = {
                key: {},
                pattern: "sgr",
            }
            subGroupHandler.getv2(p).then((res) => {
                localStorage.setItem(instituteId + 'subGroup', JSON.stringify(res.data.data));
                this.loadSGroup = true
                this.checkPullDataComplete()
            })
            this.loadAllProduct()
        },
        goCategory(){
            this.categories = this.g.usedCategory
            // window.console.log(this.categories, 'go category')
            $.each(this.categories, function(i,v){
                // window.console.log(v, 'border: 3px solid ' + v.color)
                v.borderColor = 'border: 3px solid ' + v.color
                v.titleColor = 'background-color: ' + v.color
            })
            this.showCatePage = true
            this.showGroupPage = false
            this.showSupGroupPage = false
            this.showItem = false
        },
        searchItemByGroup(id){
            // window.console.log(id)
            // this.bindItems()
            this.items = this.itemDatas
            this.loadingItmBlock = true
            setTimeout(() => {
                let itm = this.items.filter((o) => { return o.groupId == id })
                if(itm.length > 0){
                    this.items = itm
                    this.loadingItmBlock = false
                    this.isshowItem()
                }else{
                    this.loadingItmBlock = false
                    this.msgNoData()
                }
            }, 500)
        },
        goGroup(cateId){
            // window.console.log(this.cateGroup, 'go group')
            if(cateId != ''){
                this.cateGroup = this.cateGroupData.filter((o) => {return o.category.id == cateId})
            }
            $.each(this.cateGroup, function(i,v){
                v.borderColor = 'border: 3px solid ' + v.color
                v.titleColor = 'background-color: ' + v.color
            })
            if(this.cateGroup.length == 0){
                this.showCatePage = false
                this.showGroupPage = false
                this.showSupGroupPage = false
                this.showItem = true
            }else{
                this.showCatePage = false
                this.showGroupPage = true
                this.showSupGroupPage = false
                this.showItem = false
            }
        },
        searchItemBySubGroup(id){
            // window.console.log(id)
            // this.bindItems()
            this.items = this.itemDatas
            this.loadingItmBlock = true
            setTimeout(() => {
                let itm = this.items.filter((o) => { return o.subGroupId == id })
                if(itm.length > 0){
                    this.items = itm
                    this.loadingItmBlock = false
                    this.isshowItem()
                }else{
                    this.loadingItmBlock = false
                    this.msgNoData()
                }
            }, 500)
        },
        goSubGroup(){
            // window.console.log(this.subGroup, 'go group')
            $.each(this.subGroup, function(i,v){
                v.borderColor = 'border: 3px solid ' + v.color
                v.titleColor = 'background-color: ' + v.color
            })
            this.showCatePage = false
            this.showGroupPage = false
            this.showSupGroupPage = true
            this.showItem = false
        },
        itemFavorite(){
            // this.bindItems()
            this.items = this.itemDatas
            this.loadingItmBlock = true
            setTimeout(() => {
                let itm = this.items.filter((o) => { return o.isFavorite == true })
                if(itm.length > 0){
                    this.items = itm
                    this.loadingItmBlock = false
                    this.isshowItem()
                }else{
                    this.loadingItmBlock = false
                    this.msgNoData()
                }
            }, 500)
        },
        msgNoData(){
            this.$snotify.error(i18n.t('no_item_found'))
        },
        // Items
        async loadAllProduct(){
            let data = {
                key: {}
            }
            //load products
            this.showLoadingP = true
            this.allItems = []
            commerceHandler.getAllItem(data).then((res) => {
                let data = res.data.data
                if(data.Items.length > 0){
                    data.Items.forEach(e => {
                        this.allItems.push(e)
                    })
                }
                if(data.hasOwnProperty('LastEvaluatedKey')){
                    this.lastPKey = data.LastEvaluatedKey
                    this.getMoreItem(this.lastPKey)
                }else{
                    this.showLoadingP = false
                    this.bindAllItem()
                }
            });
        },
        bindAllItem(){
            if(isIndDB){
                let transaction = db.transaction(instituteId + "items", "readwrite");
                let inx = transaction.objectStore(instituteId + "items");
                let j = 0
                this.allItems.forEach(e => {
                    inx.put({
                        id: e.pk,
                        ...e
                    })
                    j++
                    if(j == this.allItems.length){
                        // window.console.log(inx, 'bind item')
                        this.loadItem = true
                        this.checkPullDataComplete()
                    }
                })
            }
        },
        getMoreItem(key){
            let data = {
                key: key
            }
            commerceHandler.getAllItem(data).then((res) => {
                let data = res.data.data
                if(data.Items.length > 0){
                    data.Items.forEach(e => {
                        this.allItems.push(e)
                    })
                }
                if(data.hasOwnProperty('LastEvaluatedKey')){
                    this.lastPKey = data.LastEvaluatedKey
                    this.getMoreItem(this.lastPKey)
                }else{
                    this.showLoadingP = false
                    this.bindAllItem()
                }
            })
        },
        numberFormat(value) {
            return kendo.toString(parseFloat(value), `n${this.decimal}`);
        },
        dateTimeFormat(date){
            return kendo.toString(new Date(date), 'dd/MMM/yyyy HH:mm:ss');
        },
        loadDiscountItem(){
            this.discountItems = []
            let d = localStorage.getItem(instituteId + 'disItem');
            if(d == null){
                this.showLoading = true;
                discountItemHandler.list("?type=Sale").then((res) => {
                    this.showLoading = false;
                    if (res.data.statusCode === 200){ 
                        this.discountItems = res.data.data;
                        localStorage.setItem(instituteId + 'disItem', JSON.stringify(res.data.data));
                    }
                });
            }else{
                this.discountItems = JSON.parse(localStorage.getItem(instituteId + 'disItem'))
            }
        },
        priceChange(){
            this.lineDS = []
            this.bindItems()
        },
        //pull data
        async pullData(){
            this.loadPullData = true
            this.loadingSetting = false
            this.loadPrice = false
            this.loadSGroup = false
            this.loadGroup = false
            this.loadItem = false
            this.loadReward = false
            this.isLoadPartner = false
            this.isLoadOrderType = false
            this.isLoadModiCate = false
            //clear indexdb
            await this.clearItemInd()
            await this.clearItemPriceInd()
            await this.clearModiInd()
            await this.clearEmpInd()
            await this.clearCamInd()
            //clear txn
            await this.clearInvInd()
            await this.clearServingInd()
            await this.clearPreOrderInd()
            //
            await this.loadSetting()
            await this.loadCategory()
            await this.loadRewardProgram()
            await this.loadPartner()
            await this.loadOrderType()
            await this.loadModiCategory()
            await this.loadCampaign()
            this.hasInv = false
            this.invs = []
            this.loadInv({})
            this.hasServing = false
            this.servs = []
            this.loadServe({})
            this.hasPre = false
            this.pres = []
            this.loadPres({})
            localStorage.setItem(instituteId + 'pulldate', new Date().getTime())
        },
        // invoice
        async clearInvInd(){
            const t = db.transaction(instituteId + "invoices", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "invoices");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear invoices')
            };
        },
        bindInv(d){
            // window.console.log(d, 'bind inv')
            d.forEach(data => {
                this.invs.push(data)
            })
        },
        loadInv(key){
            let data = {
                gsi2: this.register.pk + '#invoice#1#',
                key: key
            }
            commerceHandler.getGsi2(data).then(res=>{
                if(res.data.data.Items.length > 0){
                    this.bindInv(res.data.data.Items)
                    if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                        this.loadInv(res.data.data.LastEvaluatedKey)
                    }else{
                        this.saveLocalInv()
                    }
                }else{
                    this.hasInv = true
                    this.checkPullDataComplete()
                }
            })
        },
        saveLocalInv(){
            if(isIndDB){
                let t = db.transaction(instituteId + "invoices", "readwrite");
                let i = t.objectStore(instituteId + "invoices");
                // let j = 0
                // window.console.log(this.invs, 'set inv')
                this.invs.forEach(e => {
                    i.put({
                        id: e.pk,
                        ...e
                    })
                    // j++
                    // if(j == this.invs.length){
                    //     window.console.log(i, 'bind invoice')
                    // }
                })
                this.hasInv = true
                this.checkPullDataComplete()
            }
        },
        // serving
        async clearServingInd(){
            const t = db.transaction(instituteId + "serving", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "serving");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear serving')
            };
        },
        bindServe(d){
            // window.console.log(d, 'bind inv')
            d.forEach(data => {
                this.servs.push(data)
            })
        },
        loadServe(key){
            let data = {
                gsi2: this.register.pk + '#serving#1#',
                key: key
            }
            commerceHandler.getGsi2(data).then(res=>{
                window.console.log(res, 'seve res')
                if(res.data.data.Items.length > 0){
                    this.bindServe(res.data.data.Items)
                    if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                        this.loadServe(res.data.data.LastEvaluatedKey)
                    }else{
                        this.saveLocalServe()
                    }
                }else{
                    this.hasServing = true
                    this.checkPullDataComplete()
                }
            })
        },
        saveLocalServe(){
            if(isIndDB){
                let t = db.transaction(instituteId + "serving", "readwrite");
                let i = t.objectStore(instituteId + "serving");
                let j = 0
                window.console.log(this.servs, 'set serving')
                this.servs.forEach(e => {
                    i.put({
                        id: e.pk,
                        ...e
                    })
                    j++
                    if(j == this.servs.length){
                        window.console.log(i, 'bind invoice')
                    }
                })
                this.hasServing = true
                this.checkPullDataComplete()
            }
        },
        // pre order
        async clearPreOrderInd(){
            const t = db.transaction(instituteId + "preorders", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "preorders");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear pre order')
            };
        },
        bindPreo(d){
            // window.console.log(d, 'bind inv')
            d.forEach(data => {
                this.pres.push(data)
            })
        },
        loadPres(key){
            let data = {
                gsi2: this.register.pk + '#preorder#1#',
                key: key
            }
            commerceHandler.getGsi2(data).then(res=>{
                window.console.log(res, 'seve res')
                if(res.data.data.Items.length > 0){
                    this.bindPreo(res.data.data.Items)
                    if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                        this.loadPres(res.data.data.LastEvaluatedKey)
                    }else{
                        this.saveLocalPres()
                    }
                }else{
                    this.hasPre = true
                    this.checkPullDataComplete()
                }
            })
        },
        saveLocalPres(){
            if(isIndDB){
                let t = db.transaction(instituteId + "preorders", "readwrite");
                let i = t.objectStore(instituteId + "preorders");
                let j = 0
                // window.console.log(this.invs, 'set inv')
                this.pres.forEach(e => {
                    i.put({
                        id: e.pk,
                        ...e
                    })
                    j++
                    if(j == this.pres.length){
                        window.console.log(i, 'bind pre order')
                    }
                })
                this.hasPre = true
                this.checkPullDataComplete()
            }
        },
        // modifer
        async loadModiCategory() {
            this.showLoading = true;
            itemModifierHandler.cateGetAll().then((res) => {
                this.showLoading = false;
                if (res.data.statusCode === 200) {
                    this.modiCategories = res.data.data
                    localStorage.setItem(instituteId + 'modiCategory', JSON.stringify(this.modiCategories))
                    this.isLoadModiCate = true
                    this.checkPullDataComplete()
                }
            })
        },
        async clearItemInd(){
            const t = db.transaction(instituteId + "items", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "items");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear item')
            };
        },
        async clearItemPriceInd(){
            const t = db.transaction(instituteId + "itemPrice", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "itemPrice");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear itemPrice')
            };
        },
        async clearModiInd(){
            const t = db.transaction(instituteId + "modifier", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "modifier");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear modifier')
            };
        },
        async clearEmpInd(){
            const t = db.transaction(instituteId + "employee", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "employee");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear employee')
            };
        },
        async clearCamInd(){
            const t = db.transaction(instituteId + "campaign", "readwrite");
            // create an object store on the transaction
            const objectStore = t.objectStore(instituteId + "campaign");
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = () => {
                // report the success of our request
                window.console.log('clear campaign')
            };
        },
        checkPullDataComplete(){
            window.console.log(this.loadingSetting,
                this.loadSGroup, 
                this.loadGroup,
                this.loadPrice, 
                this.loadModifier,
                this.loadItem,
                this.loadReward,
                this.isLoadPartner,
                this.isLoadOrderType,
                this.isLoadModiCate,
                this.loadCam,
                this.loadEmp,
                this.hasInv,
                this.hasServing
            )
            if(
                this.loadingSetting && 
                this.loadSGroup && 
                this.loadGroup && 
                this.loadPrice && 
                this.loadModifier && 
                this.loadItem &&
                this.loadReward &&
                this.isLoadPartner &&
                this.isLoadOrderType &&
                this.isLoadModiCate &&
                this.loadCam &&
                this.hasInv &&
                this.hasServing &&
                this.loadEmp){
                setTimeout(() => {
                    this.loadPullData = false
                    window.location.reload()
                }, 5000)
            }
        },
        // startOrder(){
        //     this.resetOrder()
        //     this.checkOrderFlow()
        // },
        startOrderFlow(){
            window.console.log(this.startFlowAt, this.g.orderFlow, 'start order')
            if(this.startFlowAt < this.g.orderFlow.length){
                this.checkOrderShow(this.g.orderFlow[this.startFlowAt].name)
                this.startOrderAdd()
            }
        },
        checkOrderShow(func){
            window.console.log(func, 'chck order flow')
            if (func != '') {
                switch(func) {
                    case 'pin':
                        this.pinActivate = true
                        break;
                    case 'guestCount':
                        window.console.log('guestcount')
                        this.dialogCountGuest = true
                        break;
                    case 'loyalty':
                        window.console.log('loyalty')
                        this.lcardNumber = ''
                        this.dialogLoyalty = true
                        break;
                    case 'orderType':
                        window.console.log(this.orderTypes, 'orderType')
                        if (this.orderTypes.length > 0) {
                            this.dialogOrderType = true
                        }else{
                            this.dialogOrderType = false
                            this.startFlowAt += 1
                            this.checkOrderFlow()
                        }
                        break;
                    case 'partner':
                        window.console.log(this.partners)
                        this.dialogPartner = true
                        break;
                    case 'saleUnit':
                        // window.console.log('sale unit flow')
                        this.checkFlowSaleUnit()
                        this.dialogSaleUnit = true
                        break;
                    default:
                        // code block
                }
            }
        },
        startOrderAdd(){
            this.startFlowAt += 1
        },
        async bindData(){
            this.g = new generalSettingModel(JSON.parse(localStorage.getItem(instituteId + 'commRSetting')))
            if(this.g.userPin == true){
                this.loadPin()
            }
            this.setItemFlow()
            this.customer = new CustomerModel(this.g.defaultCustomer)
            // window.console.log(this.customer, this.g.defaultCustomer, 'customer')
            this.checkOtherFunction()
            this.bindItems()
            this.bindCategory()
            this.bindPriceLevel()
            this.bindDiscountItem()
            this.bindEmployee()
            this.bindCampaign()
            this.bindModifier()
            this.bindSaleUnit()
            this.bindRewardProgram()
            this.bindPartner()
            this.bindOrderType()
            this.bindModiCate()
            this.resetOrder()
            this.bindServing()
            this.bindInvoice()
            this.bindPreOrder()
        },
        bindPreOrder(){
            // items
            let txn = db.transaction(instituteId + "preorders", "readwrite");
            let i = txn.objectStore(instituteId + "preorders");
            let item = []
            let self = this
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                self.preorders = item
            }
        },
        bindInvoice(){
            // items
            let txn = db.transaction(instituteId + "invoices", "readwrite");
            let i = txn.objectStore(instituteId + "invoices");
            let item = []
            let self = this
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                self.invoices = item
            }
        },
        bindServing(){
            // items
            let txn = db.transaction(instituteId + "serving", "readwrite");
            let i = txn.objectStore(instituteId + "serving");
            let item = []
            let self = this
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                self.servings = item
                self.bindSaleUnitServing()
            }
        },
        bindModiCate(){
            let d = JSON.parse(localStorage.getItem(instituteId + 'modiCategory'))
            this.modiCategories = d
        },
        bindModifier(){
            // items
            let txn = db.transaction(instituteId + "modifier", "readwrite");
            let i = txn.objectStore(instituteId + "modifier");
            let item = []
            let self = this
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                self.modifiers = item
                // window.console.log(item, 'bind local modifier')
            }
        },
        bindEmployee(){
            // items
            let txn = db.transaction(instituteId + "employee", "readwrite");
            let i = txn.objectStore(instituteId + "employee");
            let item = []
            let self = this
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                self.employees = item
                // window.console.log(item, 'bind local employee')
            }
        },
        bindCampaign(){
            // items
            let txn = db.transaction(instituteId + "campaign", "readwrite");
            let i = txn.objectStore(instituteId + "campaign");
            let item = []
            let self = this
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                self.campaigns = item
                // window.console.log(item, 'bind local employee')
            }
        },
        bindSaleUnit(){
            let d = JSON.parse(localStorage.getItem(instituteId + 'saleUnit'))
            d.forEach(dd=>{
                dd.status = dd.status || 0
            })
            this.saleUnits = d
            this.saleUnitData = d
            // sale unit category
            this.saleUnitCates = JSON.parse(localStorage.getItem(instituteId + 'saleUnitCategory'))
            this.bindSaleUnitServing()
        },
        bindOrderType(){
            //order type
            let d = JSON.parse(localStorage.getItem(instituteId + 'orderType'))
            this.orderTypes = d
            //bank wallet
            let w = JSON.parse(localStorage.getItem(instituteId + 'bankWallet'))
            w.forEach(b=>{
                b.uid = uuid.v1()
            })
            this.bankWallets = w
            //txn number
            let t = JSON.parse(localStorage.getItem(instituteId + 'txnNumberFormat'))
            // window.console.log(t, 'of')
            this.txnNumberFormat = t
            //ordernumber
            let o = JSON.parse(localStorage.getItem(instituteId + 'orderNumberFormat'))
            this.orderNumberFormat = o
        },
        bindRewardProgram(){
            let d = JSON.parse(localStorage.getItem(instituteId + 'rewardProgram'))
            this.rewardPrograms = d
        },
        bindPartner(){
            let d = JSON.parse(localStorage.getItem(instituteId + 'partner'))
            this.partners = d
        },
        bindPriceLevel(){
            this.priceLevels = JSON.parse(localStorage.getItem(instituteId + 'pricelevel'))
        },
        bindDiscountItem(){
            this.discountItems = JSON.parse(localStorage.getItem(instituteId + 'disItem'))
        },
        checkOtherFunction(){
            this.g.otherFunction.forEach(e => {
                switch(e.id) {
                    case 'reward':
                        this.func.reward = true
                        break;
                    case 'promotion':
                        this.func.promotion = true
                        break;
                    case 'parksale':
                        this.func.parksale = true
                        break;
                    case 'invoice':
                        this.func.invoice = true
                        break;
                    case 'note':
                        this.func.note = true
                        break;
                    case 'delivery':
                        this.func.delivery = true
                        break;
                    case 'resetOrder':
                        this.func.resetOrder = true
                        break;
                    case 'splitInv':
                        this.func.splitInv = true
                        break;
                    case 'clearOrder':
                        this.func.clearOrder = true
                        break;
                    case 'saleUnit':
                        this.func.saleUnit = true
                        break;
                    case 'countGuest':
                        this.func.countGuest = true
                        break;
                    case 'mergeInv':
                        this.func.mergeInv = true
                        break;
                    case 'orderList':
                        this.func.orderList = true
                        break;
                    case 'orderType':
                        this.func.orderType = true
                        break;
                    default:
                        break;
                }
            })
        },
        bindCategory(){
            this.categories = this.g.usedCategory
            this.cateGroup = JSON.parse(localStorage.getItem(instituteId + 'group'))
            this.cateGroupData = JSON.parse(localStorage.getItem(instituteId + 'group'))
            this.subGroup = JSON.parse(localStorage.getItem(instituteId + 'subGroup'))
            this.subGroupData = JSON.parse(localStorage.getItem(instituteId + 'subGroup'))
        },
        bindItems(){
            // items
            let txn = db.transaction(instituteId + "items", "readwrite");
            let i = txn.objectStore(instituteId + "items");
            let item = []
            let self = this
            this.showBindItmLocal = true
            i.getAll().onsuccess = function(event) {
                item = event.target.result
                // window.console.log(item, item.length, 'ittt')
                if(item.length > 0){
                    self.bindItemsPrice(item)
                }else{
                    self.showBindItmLocal = false
                }
            }
            i.onerror = function(e){
                window.console.log(e)
                self.showBindItmLocal = false
            }
        },
        bindItemsPrice(item){
            // item price
            let self = this
            let itemPrice = []
            let ptxn = db.transaction(instituteId + "itemPrice", "readwrite");
            let ip = ptxn.objectStore(instituteId + "itemPrice");
            ip.getAll().onsuccess = function(event) {
                itemPrice = event.target.result
                self.items = []
                self.itemDatas = []
                if(item.length > 0){
                    let myarray = []
                    self.g.usedCategory.forEach(e => {
                        myarray.push(e.id)
                    })
                    let items = []
                    item.forEach(e => {
                        if($.inArray(e.categoryId, myarray) != -1) {
                            let tod = new Date().getTime()
                            let itp = itemPrice.filter((o) => {
                                return o.itemId == e.pk && self.g.defaultPriceLevel == o.priceLevel.id && new Date(o.appliedDate).getTime() <= tod
                            })
                            let cate = self.g.usedCategory.filter((a) => {
                                return a.id == e.categoryId
                            })
                            // window.console.log(itp, self.g.defaultPriceLevel , 'bind price uom')
                            if(itp.length > 0){
                                let uoms = []
                                itp.forEach(u=>{
                                    uoms.push({
                                        price: parseFloat(u.price),
                                        ...u.uom
                                    })
                                })
                                items.push({
                                    id: e.pk,
                                    name: e.name,
                                    sku: e.sku,
                                    saleDescription: e.saleDescription,
                                    price: e.price,
                                    categoryId: e.categoryId,
                                    category: cate[0],
                                    borderColor: 'border: 3px solid ' + cate[0].color,
                                    titleColor: 'background-color: ' + cate[0].color,
                                    groupId: e.group.id,
                                    subGroupId: e.subGroup.id,
                                    img: e.thumb != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + e.thumb : './images/default.png',
                                    uom: uoms,
                                    buom: e.uom,
                                })
                            }
                        }
                    })
                    self.items = items
                    self.itemDatas = items
                    self.showBindItmLocal = false
                    // window.console.log(self.items, 'bind local item')
                    
                }
                // self.isshowItem()
                // window.console.log(item, itemPrice, 'bind item')
            };
        },
        checkOrderFlow(){
            // check order flow
            // window.console.log(this.g, 'setting')
            if(this.g.allowOrderFlow == true){
                this.startOrderFlow()
            }
        },
        //sale uni
        checkFlowSaleUnit(){
            this.showSaleUnitCate = false
            if(this.g.saleUnitFlow == 1){
                this.showSaleUnitCate = true
            }
            window.console.log(this.g.saleUnitFlow, 'ch3ck sale unit flow')
        },
        selectCateSaleUnit(d){
            this.saleUnits = this.saleUnitData.filter((o) => { return o.category.id == d.id && o.status == 1})
            window.console.log(d.id, 'select sale uint ate')
            this.showSaleUnitCate = false
        },
        selectSaleUnit(d){
            this.saleUnit = d
            this.t.saleUnit = {
                id: d.id,
                name: d.name,
                code: d.code
            }
        },
        //promotion
        applyPromotion(cam){
            if(this.isEditDiscount){
                return
            }
            // let dis = parseFloat(cam.rewardAmount)
            // check store 
            if(cam.stores.length > 0){
                let hasStore = false
                cam.stores.forEach(s=>{
                    if(s.pk == this.activeStore.pk){
                        hasStore = true
                    }
                })
                if(!hasStore) return
            }else{
                return
            }
            // check time
            let validTime = true
            if(cam.isTiming){
                validTime = false
                let curTime = new Date().getTime()
                let timeF = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeFrom
                let timeFrom = new Date(timeF).getTime()
                let timeT = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeTo
                let timeTo = new Date(timeT).getTime()
                if(curTime >= timeFrom && curTime <= timeTo){
                    validTime = true
                }
                // window.console.log(timeTo, timeFrom, curTime, 'compare time')
            }
            if(!validTime){
                return
            }
            // check price level
            let validPriceLevel = false
            if(cam.priceLevel.id == this.g.defaultPriceLevel){
                validPriceLevel = true
            }
            if(!validPriceLevel){
                return
            }
            // check rule base
            let validRule = false
            // customer base
            if(cam.ruleBase == 'customer'){
                if(cam.ruleCustomerBaseType == 'specific'){
                    if(cam.ruleCustomers.length > 0){
                        cam.ruleCustomers.forEach(rcus=>{
                            if(rcus.customer.id == this.g.defaultCustomer.id){
                                validRule = true
                            }
                        })
                    }
                }else if(cam.ruleCustomerBaseType == 'customerType'){
                    if(cam.ruleCustomerTypes.length > 0){
                        cam.ruleCustomerTypes.forEach(rcus=>{
                            if(rcus.id == this.g.defaultCustomer.customerType.id){
                                validRule = true
                            }
                        })
                    }
                }else{
                    validRule = true
                }
            // product base
            }else{
                if(cam.ruleProductBaseOn == 'specific'){
                    if(cam.ruleProduct.length > 0){
                        cam.ruleProduct.forEach(rcus=>{
                            this.lineDS.forEach(lin=>{
                                if(rcus.item.id == lin.itemId){
                                    validRule = true
                                }
                            })
                        })
                    }
                }else if(cam.ruleProductBaseOn == 'category'){
                    if(cam.ruleProductCategory.length > 0){
                        cam.ruleProductCategory.forEach(rcus=>{
                            this.lineDS.forEach(lin=>{
                                if(rcus.category.id == lin.categoryId){
                                    validRule = true
                                }
                            })
                        })
                    }
                }else if(cam.ruleProductBaseOn == 'group'){
                    if(cam.ruleProductGroup.length > 0){
                        cam.ruleProductGroup.forEach(rcus=>{
                            this.lineDS.forEach(lin=>{
                                if(rcus.group.id == lin.groupId){
                                    validRule = true
                                }
                            })
                        })
                    }
                }else{
                    validRule = true
                }
            }
            if(!validRule){
                return
            }
            // window.console.log(this.lineDS, dis, this.g, 'line ds')
            // window.console.log(
            //     validPriceLevel,
            //     validTime,  
            //     validRule,
            // 'check condition')
            this.lineDS.forEach(d=>{
                //check overise
                if(cam.isOverwrite){
                    d.discount = 0
                    d.amount = (d.price * d.qty) + d.modifierAmt + d.tax
                }
                // check reward
                let dis = 0
                // window.console.log(cam, 'campaign')
                if(cam.rewardBase == 'amountBase'){
                    if(cam.rewardType == 'fixed'){
                        if(cam.rewardAmountType == 'percentage'){
                            dis = (d.amount * parseFloat(cam.rewardAmount)) / 100
                        }else{
                            dis = parseFloat(cam.rewardAmount)
                        }
                    }else if(cam.rewardType == 'varian'){
                        let totalcom = 0
                        this.lineDS.forEach(lcom=>{
                            totalcom += (lcom.price * lcom.qty) + lcom.modifierAmt
                        })
                        for (let i = 0; i < cam.rewardVarian.length; i++) {
                            let va = cam.rewardVarian[i]
                            // window.console.log(va, totalcom, 'va')
                            if(va.conditionType == '>'){
                                if(totalcom > va.conditionAmount){
                                    // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                    if(va.rewardType == 'percentage'){
                                        dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                    }else{
                                        dis = parseFloat(va.rewardAmount)
                                    }
                                    break;
                                } 
                            }else{
                                if(totalcom >= va.conditionAmount){
                                    // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                    if(va.rewardType == 'percentage'){
                                        dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                    }else{
                                        dis = parseFloat(va.rewardAmount)
                                    }
                                    break;
                                } 
                            }
                            
                        }
                    }
                }else{
                    //production bae
                    window.console.log(cam.rewardBase)
                }
                if(dis > 0){
                    d.discount = dis
                    d.amount -= dis
                    if(d.amount < 0){
                        d.discount = (d.price * d.qty) + d.modifierAmt + d.tax
                        d.amount = 0
                    }
                    // this.$snotify.success('Campaign ' + cam.name + ' applyed!')
                    d.campaign.push({
                        id: cam.pk,
                        name: cam.name,
                        code: cam.code,
                        amount: d.discount
                    })
                }
            })
            if(this.isPromoFlow ==  true){
                this.dialogPromotion = false
                this.chFlow()
            }
            // window.console.log(this.lineDS, 'after discount')
        },
        //product
        addItem(item){
            window.console.log(item, 'itm')
            let u = item.uom[0]
            let line = {
                id: uuid.v1(),
                name: item.name,
                otherName: item.otherName ? item.otherName : '',
                number: item.number,
                description: item.saleDescription != '' ? item.saleDescription : item.name,
                saleDes: item.saleDescription != '' ? item.saleDescription : item.name,
                amount: parseFloat(u.price) * 1,
                price: parseFloat(u.price) * 1,
                qty: 1,
                uom: u,
                buom: u,
                uoms: item.uom,
                itemId: item.id,
                discount: 0,
                tax: 0,
                categoryId: item.categoryId,
                groupId: item.groupId,
                modifierAmt: 0,
                modifiers: [],
                employees: [],
                campaign: [],
            }
            this.activeLine = line
            this.lineDS.push(line)
            // window.console.log(line, 'add line')
            this.addItmFlowAt = 0
            window.console.log(this.addItmFlowAt, 'add item')
            this.checkAddFlow()
        },
        checkAddFlow(){
            window.console.log(this.addItmFlowAt, 'add item flow')
            if(this.addItmFlowAt < this.g.addItemFlow.length){
                this.checkAddItemShow(this.g.addItemFlow[this.addItmFlowAt].name)
                // window.console.log(this.addItmFlowAt, 'add check flow')
            }
            // this.addItmFlowAt += 1
            if (this.addItmFlowAt == this.g.addItemFlow.length) {
                this.calculateTotal()
            }
        },
        checkAutoPromotion(){
            this.campaigns.forEach(c=>{
                if(c.isEndDate){
                    let edFull = kendo.toString(new Date(c.endDate), 'yyyy-MMM-dd')
                    let ed = new Date(edFull + ' 23:59:59').getTime()
                    let tdFull = kendo.toString(new Date(), 'yyyy-MMM-dd')
                    let td = new Date(tdFull + ' 00:00:00').getTime()
                    if(c.type == 'automatic' && ed > td ){
                        this.applyPromotion(c)
                    }
                }else{
                    this.applyPromotion(c)
                }
            })
        },
        async calculateTotal(){
            await this.checkAutoPromotion()
            let stotal = 0, dis = 0, modi = 0, tax = 0
            this.lineDS.forEach(d=>{
                stotal += parseFloat(d.price) * parseFloat(d.qty)
                dis += parseFloat(d.discount)
                modi += parseFloat(d.modifierAmt)
                tax += parseFloat(d.tax)
            })
            this.t.subTotal = stotal + modi
            this.t.discount = dis
            this.t.tax = tax 
            this.t.modiAmt = modi
            this.t.total = (stotal + modi - dis) + tax
        },
        async checkAddItemShow(func){
            if (func != '') {
                switch(func) {
                    case 'uom':
                        this.uomDialog = false
                        if(this.activeLine.uoms.length > 1){
                            this.activeLine.uoms.forEach(a=>{
                                a.uid = uuid.v1()
                            })
                            this.itemUOMs = this.activeLine.uoms
                            this.uomDialog = true
                        }else{
                            this.addItmFlowAt += 1
                            this.checkAddFlow()
                        }
                        break;
                    case 'modifier':
                        this.modifierDialog = false
                        this.activeSelectModi = []
                        if(this.modifiers.length > 0){
                            this.activeModi = []
                            let cat = []
                            this.modifiers.forEach(m=>{
                                m.selected = false
                                if(m.applyTo == 'item'){
                                    m.itemLines.forEach(itm=>{
                                        if (itm.itemId == this.activeLine.itemId) {
                                            cat.push(m)
                                        }
                                    })
                                }else if (m.applyTo == 'category') {
                                    m.categories.forEach(itm=>{
                                        if (itm.id == this.activeLine.categoryId) {
                                            cat.push(m)
                                        }
                                    })
                                }else{
                                    m.groups.forEach(itm=>{
                                        if (itm.id == this.activeLine.groupId) {
                                            cat.push(m)
                                        }
                                    })
                                }
                            })
                            if(cat.length > 0){
                                this.createModi(cat)
                            }else{
                                this.addItmFlowAt += 1
                                this.checkAddFlow()
                            }
                            
                        }else{
                            this.addItmFlowAt += 1
                            this.checkAddFlow()
                        }
                        break;
                    case 'employee':
                        if(this.employees.length > 0){
                            let emp = []
                            this.employees.forEach(e=>{
                                if(e.employeeId != ''){
                                    e.selected = false
                                    e.uid = uuid.v1()
                                    emp.push(e)
                                }
                            })
                            this.employees = emp
                            this.employeeDialog = true
                        }else{
                            this.addItmFlowAt += 1
                            this.checkAddFlow() 
                        }
                        break;
                    default:
                        // code block
                }
            }
        },
        deSelectModi(itm){
            this.activeModi.forEach(a=>{
                a.items.forEach(b=>{
                    if (b.pk == itm) {
                        b.selected = false
                    }
                })
            })
            let a = $('.' + itm + '-de')
            let b = $('.' + itm)
            a.css('display', 'none')
            b.css('display', 'block')
        },
        selectModi(itm){
            window.console.log(itm, 'select modi')
            this.activeModi.forEach(a=>{
                a.items.forEach(b=>{
                    if (b.pk == itm) {
                        b.selected = true
                    }
                })
            })
            let a = $('.' + itm + '-de')
            let b = $('.' + itm)
            a.css('display', 'block')
            b.css('display', 'none')
        },
        enterModi(){
            let selectModi = []
            let totalAmt = 0
            let text = ''
            let modiDes = ''
            this.activeModi.forEach(a=>{
                a.items.forEach(b=>{
                    if (b.selected == true) {
                        selectModi.push({
                            id: b.id,
                            price: b.price,
                            isCount: b.isCount,
                            name: b.name
                        })
                        if(parseFloat(b.price) > 0){
                            totalAmt += parseFloat(b.price)
                        }
                        text += ',' + b.name
                    }
                })
            })
            text = text.substring(1)
            if(selectModi.length > 0){
                if(!this.isEditModi){
                    modiDes = this.activeLine.description + '[M:' + text + ']'
                }else{
                    modiDes = this.activeLine.saleDes + '[M:' + text + ']'
                    if(this.activeLine.employees.length > 0){
                        let texta = ''
                        this.activeLine.employees.forEach(b=>{
                            texta += ',' + b.employeeId
                        })
                        texta = texta.substring(1)
                        modiDes = modiDes + '[E:' + texta + ']'
                    }
                }
                this.lineDS.forEach(l=>{
                    if (l.id == this.activeLine.id) {
                        l.modifierAmt += totalAmt
                        l.amount += totalAmt
                        l.modifiers = selectModi
                        l.description = modiDes
                    }
                })
                this.addItmFlowAt += 1
                this.checkAddFlow()
                this.modifierDialog = false
            }else{
                this.$snotify.error(i18n.t('please_select_a_modifier'))
            }
        },
        clearModi(){
            if(this.activeLine.hasOwnProperty('id')){
                this.lineDS.forEach(l=>{
                    if (l.id == this.activeLine.id) {
                        l.modifierAmt = 0
                        l.amount = parseFloat(l.price) * parseFloat(l.qty)
                        l.modifiers = []
                        l.description = l.saleDes
                        if(l.employees.length > 0){
                            let texta = ''
                            l.employees.forEach(b=>{
                                texta += ',' + b.employeeId
                            })
                            texta = texta.substring(1)
                            l.description = l.saleDes + '[E:' + texta + ']'
                        }
                    }
                })
                this.modifierDialog = false
            }
        },
        skipModi(){
            this.modifierDialog = false
            this.addItmFlowAt += 1
            this.checkAddFlow()
        },
        createModi(ct){
            let cate = []
            this.modiCategories.forEach(c=>{
                let m = ct.filter((a) => {return a.modifierCategory == c.pk})
                if(m.length > 0){
                    cate.push({
                        id: c.pk,
                        name: c.name,
                        items: m
                    })
                }
            })
            if(cate.length > 0){
                this.activeModi = cate
                this.modifierDialog = true
            }
        },
        editModi(){
            if(this.activeLine.hasOwnProperty('id')){
                window.console.log(this.activeLine, 'modi edit')
                this.modifierDialog = false
                this.isEditModi = false
                this.activeSelectModi = []
                if(this.modifiers.length > 0){
                    this.activeModi = []
                    let cat = []
                    this.modifiers.forEach(m=>{
                        m.selected = false
                        if(m.applyTo == 'item'){
                            m.itemLines.forEach(itm=>{
                                if (itm.itemId == this.activeLine.itemId) {
                                    cat.push(m)
                                }
                            })
                        }else if (m.applyTo == 'category') {
                            m.categories.forEach(itm=>{
                                if (itm.id == this.activeLine.categoryId) {
                                    cat.push(m)
                                }
                            })
                        }else{
                            m.groups.forEach(itm=>{
                                if (itm.id == this.activeLine.groupId) {
                                    cat.push(m)
                                }
                            })
                        }
                    })
                    // window.console.log(cat, 'modifier')
                    if(cat.length > 0){
                        this.isEditModi = true
                        this.createModi(cat)
                        if(this.activeLine.modifiers.length > 0){
                            setTimeout(() => {
                                window.console.log(this.activeLine.modifiers, 'modi on line')
                                this.activeLine.modifiers.forEach(m=>{
                                    this.selectModi(m.id)
                                })
                            }, 500);
                        }
                    }else{
                        this.$snotify.error(i18n.t('there_is_no_modifier_for_this_item'))
                    }
                }
            }
        },
        async loadUOM(id){
            this.showLoading = true
            uomConversionHandler.get(id).then(() => {
                this.showLoading = false;
            });
        },
        enterUOM(itm){
            this.lineDS.forEach(l=>{
                if (l.id == this.activeLine.id) {
                    l.amount = parseFloat(itm.price) * 1,
                    l.price = parseFloat(itm.price) * 1,
                    l.uom = itm
                }
            })
            this.uomDialog = false
            this.addItmFlowAt += 1
            this.checkAddFlow()
        },
        editUOM(){
            if(this.activeLine.hasOwnProperty('id')){
                this.uomDialog = false
                if(this.activeLine.uoms.length > 1){
                    this.activeLine.uoms.forEach(a=>{
                        a.uid = uuid.v1()
                    })
                    this.itemUOMs = this.activeLine.uoms
                    this.uomDialog = true
                }else{
                    this.$snotify.error(i18n.t('there_is_only_one_uom_on_this_item'))
                }
            }
        },
        //employee
        deSelectEmp(itm){
            this.employees.forEach(b=>{
                if (b.id == itm) {
                    b.selected = false
                }
            })
            // window.console.log(this.activeModi, itm, 'de select modi')
            let a = $('.' + itm + '-de')
            let b = $('.' + itm)
            // window.console.log(a, itm, 'select modi')
            a.css('display', 'none')
            b.css('display', 'block')
        },
        selectEmp(itm){
            this.employees.forEach(b=>{
                if (b.id == itm) {
                    b.selected = true
                }
            })
            let a = $('.' + itm + '-de')
            let b = $('.' + itm)
            // window.console.log(a, itm, 'select modi')
            a.css('display', 'block')
            b.css('display', 'none')
            // a.style.display = 'block'
        },
        enterEmp(){
            let selectModi = []
            let text = ''
            let desEmp = ''
            this.employees.forEach(b=>{
                if (b.selected == true) {
                    selectModi.push({
                        id: b.id,
                        employeeId: b.employeeId,
                        name: b.name
                    })
                    text += ',' + b.employeeId
                }
            })
            text = text.substring(1)
            if(selectModi.length > 0){
                if(!this.isEditEmp){
                    desEmp = this.activeLine.description + '[E:' + text + ']'
                }else{
                    desEmp = this.activeLine.saleDes + '[E:' + text + ']'
                    if(this.activeLine.modifiers.length > 0){
                        let texta = ''
                        this.activeLine.modifiers.forEach(b=>{
                            texta += ',' + b.name
                        })
                        texta = texta.substring(1)
                        desEmp = desEmp + '[M:' + texta + ']'
                    }
                }
                this.lineDS.forEach(l=>{
                    if (l.id == this.activeLine.id) {
                        l.description = desEmp
                        l.employees = selectModi
                    }
                })
                this.skipEmp()
            }else{
                this.$snotify.error(i18n.t('please_select_a_employee'))
            }
        },
        skipEmp(){
            this.employeeDialog = false
            window.console.log('skip emp')
            this.addItmFlowAt += 1
            this.checkAddFlow()
        },
        editEmp(){
            if(this.activeLine.hasOwnProperty('id')){
                if(this.employees.length > 0){
                    let emp = []
                    this.employees.forEach(e=>{
                        if(e.employeeId != ''){
                            e.selected = false
                            e.uid = uuid.v1()
                            emp.push(e)
                        }
                    })
                    this.employees = emp
                    this.isEditEmp = true
                    this.employeeDialog = true
                    if(this.activeLine.employees.length > 0){
                        setTimeout(() => {
                            // window.console.log(this.activeLine.employees, 'emp')
                            this.activeLine.employees.forEach(m=>{
                                this.selectEmp(m.id)
                            })
                        }, 500);
                    }
                }else{
                    this.$snotify.error(i18n.t('there_is_no_employee'))
                }
            }
        },
        clearEmp(){
            if(this.activeLine.hasOwnProperty('id')){
                this.lineDS.forEach(l=>{
                    if (l.id == this.activeLine.id) {
                        l.employees = []
                        l.description = l.saleDes
                        if(l.modifiers.length > 0){
                            let texta = ''
                            l.modifiers.forEach(b=>{
                                texta += ',' + b.name
                            })
                            texta = texta.substring(1)
                            l.description = l.saleDes + '[M:' + texta + ']'
                        }
                    }
                })
                this.employeeDialog = false
            }
        },
        // line
        lineChange(){
            let grid = kendo.jQuery("#lineDS").data("kendoGrid")
            let selectedItem = grid.dataItem(grid.select())
            this.selectItem = selectedItem
        },
        // sale form content
        async loadSaleFormContent() {
            saleFormContentHandler.list().then((res) => {
                if (res.data.statusCode === 200) {
                    const data = res.data.data;
                    if (data.length > 0) {
                        this.saleFormContent = data[0]
                    }
                }
            })
        },
        async loadAccount() {
            accountHandler.getAll().then((res) => {
                this.showLoading = false;
                //Receivable Account
                this.coa = res.data || [];
                this.receivableAcc = this.coa
                .filter((m) => m.account_type.number === 7)
                .map((itm) => {
                    return {
                    id: itm.uuid,
                    uuid: itm.uuid,
                    name: itm.name,
                    local_name: itm.local_name,
                    number: itm.number,
                    is_taxable: itm.is_taxable,
                    banhjiAccCode: itm.banhjiAccCode,
                    group_code: itm.group_code,
                    parent_account: itm.parent_account,
                    type_code: itm.type_code,
                    account_type: itm.account_type,
                    };
                });
                if (this.receivableAcc.length > 0) {
                    this.invoice.receivableAcc = this.receivableAcc[0];
                }
            });
        },
        viewRow(item){
            window.console.log(item, 'view row')
        },
        removeRow() {
            // window.console.log(e, 'item')
            if(Object.keys(this.activeLine).length == 0){
                return
            }
            let e = this.activeLine
            const index = this.lineDS.indexOf(e);
            this.lineDS.splice(index,1);
            this.calculateTotal()
        },
        removeLine(ritm) {
            let index = 0, i = 0
            this.lineDS.forEach(e => {
                if(e.id == ritm){
                    index = i
                }
                i++
            })
            window.console.log(index, ritm)
            this.lineDS.splice(index, 1);
        },
        // keynote function
        enterKeyPad(){
            this.activeKeyPad = false
            this.activeQty = false
            // window.console.log(this.keyPadAmount)
            this.lineDS.forEach(itemLine => {
                if(itemLine.id == this.activeLine.id){
                    if(this.keyFunction == 'qty'){
                        if(itemLine.qty == '' || itemLine.qty == 0){
                            itemLine.qty = 1
                        }
                        let amount = parseFloat(itemLine.price) * parseFloat(itemLine.qty)
                        itemLine.amount = amount
                    }else if(this.keyFunction == 'dis'){
                        if(itemLine.discount == '' || parseFloat(itemLine.discount) > itemLine.amount){
                            itemLine.discount = 0
                        }
                        let amount = (parseFloat(itemLine.price) * parseFloat(itemLine.qty)) - parseFloat(itemLine.discount)
                        itemLine.amount = amount
                    }else if(this.keyFunction == 'price'){
                        if(itemLine.price == ''){
                            itemLine.price = 0
                        }
                        let amount = parseFloat(itemLine.price) * parseFloat(itemLine.qty)
                        itemLine.amount = amount
                    }
                }
            })
            this.calculateTotal()
            this.isEditDiscount = false
        },
        setKeyFunction(func){
            if(this.activeLine.hasOwnProperty('id')){
                this.activeKeyPad = true
                this.activeQty = true
                this.activePrice = false
                this.activeDiscount = false
                this.isEditDiscount = false
                this.keyFunction = func
                this.oldqty = this.activeLine.qty
                this.olddis = this.activeLine.discount
                this.oldprice = this.activeLine.price
                if(func == 'qty'){
                    this.keyPadAmount = this.activeLine.qty.toString()
                }else if(func == 'dis'){
                    this.isEditDiscount = true
                    this.keyPadAmount = this.activeLine.discount.toString()
                }else if(func == 'price'){
                    this.keyPadAmount = this.activeLine.price.toString()
                }
            }
        },
        cancelKeyFunction(){
            this.activeKeyPad = false
            this.activeQty = false
            window.console.log(this.keyPadAmount)
            this.lineDS.forEach(itemLine => {
                if(itemLine.id == this.activeLine.id){
                    itemLine.qty = this.oldqty
                    itemLine.price = this.oldprice
                    itemLine.discount = this.olddis
                }
            })
            this.calculateTotal()
        },
        qtyOnItem(){
            window.console.log(this.activeLine)
            if(this.activeLine.hasOwnProperty('id')){
                this.activeKeyPad = true
                this.activeQty = true
                this.activePrice = false
                this.activeDiscount = false
                this.keyPadAmount = this.activeLine.qty.toString()
            }
        },
        enterPrice(){
            this.activeKeyPad = false
            this.activePrice = false
            window.console.log(this.keyPadAmount)
            this.lineDS.forEach(itemLine => {
                if(itemLine.id == this.selectItem.id){
                    if(itemLine.price == ''){
                        itemLine.price = 0
                    }
                    let amount = parseFloat(itemLine.uom.price) * parseFloat(itemLine.qty)
                    let xAmount = amount * parseFloat(this.invoice.txnRate)
                    itemLine.price = parseFloat(itemLine.uom.price)
                    itemLine.amount = amount
                    itemLine.exchangeAmount = xAmount
                }
            })
            setTimeout(()=>{
                this.autoCalculate()
            }, 300)
        },
        priceOnItem(){
            window.console.log(this.selectItem)
            if(this.selectItem.hasOwnProperty('id')){
                this.activeKeyPad = true
                this.activeQty = false
                this.activePrice = true
                this.activeDiscount = false
                this.keyPadAmount = this.selectItem.price.toString()
            }
        },
        enterDis(){
            this.activeKeyPad = false
            this.activePrice = false
            window.console.log(this.keyPadAmount)
            this.lineDS.forEach(itemLine => {
                if(itemLine.id == this.activeLine.id){
                    if(itemLine.discount == '' || parseFloat(itemLine.discount) > itemLine.amount){
                        itemLine.discount = 0
                    }
                    let amount = (parseFloat(itemLine.price) * parseFloat(itemLine.qty)) - parseFloat(itemLine.discount)
                    itemLine.amount = amount
                }
            })
            this.calculateTotal()
        },
        disOnItem(){
            window.console.log(this.activeLine)
            if(this.activeLine.hasOwnProperty('id')){
                this.activeKeyPad = true
                this.activeQty = false
                this.activePrice = false
                this.activeDiscount = true
                this.keyPadAmount = this.activeLine.discount.toString()
            }
        },
        keyPad(value){
            if(value == 'c'){
                this.keyPadAmount = 0
            }else{
                var amt = "";
                if(this.keyPadAmount != "") amt = this.keyPadAmount;
                this.keyPadAmount = amt + value.toString()
            }
            window.console.log(this.keyPadAmount, 'key amount')
            this.lineDS.forEach(e => {
                if(e.id == this.activeLine.id){
                    if(this.keyFunction == 'qty'){
                        e.qty = parseFloat(this.keyPadAmount)
                    } else if(this.keyFunction == 'dis'){
                        e.discount = parseFloat(this.keyPadAmount)
                    }else if(this.keyFunction == 'price'){
                        e.price = parseFloat(this.keyPadAmount)
                    }
                }
            })
        },
        inactiveKeyPad(){
            this.selectItem = {}
            this.activeKeyPad = false
            this.activeQty = false
        },
        async loadPin() {
            this.showLoading = true
            commerceHandler.pinGets().then(res => {
                this.showLoading = false
                if (res.data.statusCode === 200) {
                    const data = res.data.data.filter((o) => {return o.register == this.register.pk})
                    if (data.length > 0) {
                        this.pins = data
                    }else{
                        this.$snotify.error(i18n.t('no_pin_user'))
                        this.gotoSetting()
                    }
                }
            })
        },
        async loadSaleUnitItems() {
            this.showLoading = true;
            saleUnitItemHandler.list().then((res) => {
                this.showLoading = false;
                if (res.data.statusCode === 200) {
                    this.saleUnits = res.data.data;
                    this.saleUnitData = res.data.data
                    localStorage.setItem(instituteId + 'saleUnit', JSON.stringify(this.saleUnitData))
                }
            });
        },
        async loadSaleUnitCategory() {
            this.showLoading = true;
            saleUCHandler.get().then((res) => {
                this.showLoading = false;
                this.saleUnitCates = res;
                localStorage.setItem(instituteId + 'saleUnitCategory', JSON.stringify(res))
            });
        },
        async loadRewardProgram() {
            this.showLoading = true;
            loyaltyHandler.rewardGets().then((res) => {
                this.showLoading = false;
                if (res.data.statusCode === 200) {
                    this.rewardPrograms = res.data.data
                    localStorage.setItem(instituteId + 'rewardProgram', JSON.stringify(this.rewardPrograms))
                    this.loadReward = true
                    this.checkPullDataComplete()
                }
            });
        },
        async loadPartner() {
            this.showLoading = true;
            commerceHandler.partnerGets().then((res) => {
                this.showLoading = false;
                if (res.data.statusCode === 200) {
                    this.partners = res.data.data
                    localStorage.setItem(instituteId + 'partner', JSON.stringify(this.partners))
                    this.isLoadPartner = true
                    this.checkPullDataComplete()
                }
            });
        },
        async loadOrderType() {
            this.showLoading = true;
            commerceHandler.settingGet(this.activeStore.pk).then(res => {
                this.showLoading = false
                if (res.data.statusCode === 200) {
                    const data = res.data.data
                    let d = data.filter((o) => {return o.type == 'retail'})
                    if (d.length > 0) {
                        this.orderTypes = d[0].orderTypes
                        this.bankWallets = d[0].bankWallet
                        this.txnNumberFormat = d[0].txnNumber
                        this.orderNumberFormat = d[0].orderNum
                        this.decimal = d[0].decimal
                        localStorage.setItem(instituteId + 'orderType', JSON.stringify(this.orderTypes))
                        localStorage.setItem(instituteId + 'bankWallet', JSON.stringify(this.bankWallets))
                        localStorage.setItem(instituteId + 'txnNumberFormat', JSON.stringify(this.txnNumberFormat))
                        localStorage.setItem(instituteId + 'orderNumberFormat', JSON.stringify(this.orderNumberFormat))
                        this.isLoadOrderType = true
                        this.checkPullDataComplete()
                    }
                }
            });
        },
        // item
        setItemFlow(){
            this.showCatePage = false
            this.showGroupPage = false
            this.showSupGroupPage = false
            this.showItem = true
            // window.console.log(this.g.itemFlow, 'item flow')
            if(this.g.itemFlow != 3){
                this.goCategory()
            }
        },
        //employee
        loadEmployee(){
            this.employees = [];
            this.showLoading = true;
            employeeHandler.center(undefined).then((res) => {
                this.loadEmp = true
                this.checkPullDataComplete()
                if (res.data.statusCode === 200) {
                    this.showLoading = false;
                    this.employees = res.data.data;
                    this.addEmp()
                }
            }).catch((e) => {
                this.showLoading = false
                window.console.error(e);
            })
        },
        addEmp(){
            if(isIndDB){
                let t = db.transaction(instituteId + "employee", "readwrite");
                let i = t.objectStore(instituteId + "employee");
                let j = 0
                this.employees.forEach(e => {
                    i.put({
                        id: e.id,
                        ...e
                    })
                    j++
                    if(j == this.employees.length){
                        window.console.log(i, 'bind employee')
                    }
                })
            }
        },
        //modifier
        async loadModifiers() {
            this.modifiers = [];
            this.showLoading = true;
            itemModifierHandler.list().then((res) => {
                this.showLoading = false;
                if(res.data.data.length > 0){
                    if (res.data.statusCode === 200) {
                        this.modifiers = res.data.data;
                        this.addModi()
                    }
                }else{
                    this.loadModifier = true
                    this.checkPullDataComplete()
                }
            });
        },
        addModi(){
            if(isIndDB){
                let t = db.transaction(instituteId + "modifier", "readwrite");
                let idx = t.objectStore(instituteId + "modifier");
                let j = 0
                this.modifiers.forEach(e => {
                    idx.put({
                        id: e.id,
                        ...e
                    })
                    j++
                    if(j == this.modifiers.length){
                        window.console.log(idx, 'bind modifier')
                        this.loadModifier = true
                        this.checkPullDataComplete()
                    }
                })
            }
        },
        // check indexdb
        checkIndexDB(){
            if(loadIndex){
                let d = localStorage.getItem(instituteId + 'pulldate');
                let dd = new Date(parseInt(d))
                // window.console.log(dd, 'ssss')
                if(d != null){
                    if(new Date(dd).toISOString().substr(0, 10) != new Date().toISOString().substr(0, 10)){
                        this.pullData()
                    }else{
                        this.bindData()
                    }
                }else{
                    this.pullData()
                }
            }else{
                setTimeout(() => {
                    this.checkIndexDB()
                }, 300);
            }
        },
        //currency
        async loadCurrency(){
            this.currencies = []
            this.rates = []
            this.baseRate = {}
            this.secondRate = {}
            this.selectRate = {}
            this.isSecondRate = false
            this.showLoading = true
            this.hasKHR = false
            this.hasUSD = false
            currencyHandler.list('fun-c').then(response => {
                this.showLoading = false
                if (response.data.statusCode === 200) {
                    // window.console.log(response, 'function currency')
                    this.currencies = response.data.data
                    if (this.currencies.length > 0) {
                        setTimeout(()=>{
                            this.loadRate()
                        }, 1000)
                    }else{
                        alert('Please set up functional currency on setting');
                        window.top.close();
                    }
                }
            })
        },
        loadRate(){
            const date = new Date().toISOString().substring(0, 10)
            let base = this.institute.baseCurrency.code;
            let j = 1;
            this.currencies.forEach(c=>{
                currencyHandler.getLastExchangeRateByDate(date, c.code).then(r => {
                    if(r.data.data.length > 0){
                        let d = r.data.data[0]
                        d.isBase = 0
                        if(c.code == base){
                            d.isBase = 1
                            this.baseRate = d
                            this.selectRate = d
                        }
                        d.uid = uuid.v1()
                        this.rates.push(d)
                        if(d.code == 'KHR'){
                            this.hasKHR = true
                        }
                        if(d.code == 'USD'){
                            this.hasUSD = true
                        }
                    }
                    if(j == this.currencies.length){
                        if(this.rates.length == 0){
                            alert('Please set up exchange rate on setting');
                            window.top.close();
                        }
                        if(Object.keys(this.baseRate).length == 0){
                            alert('Please set up base exchange rate on setting');
                            window.top.close();
                        }
                        this.rates.sort(function (a, b) {
                            return parseFloat(b.isBase) - parseFloat(a.isBase)
                        })
                        if(this.rates.length > 1){
                            this.isSecondRate = true
                            this.secondRate = this.rates[1]
                        }
                        // window.console.log(this.rates, 'c rates')
                    }
                    j++
                })
            })
        },
        //exchange
        resetReceipt(){
            this.selectRate = this.baseRate
            this.amtReceipt = 0
            this.t.amtReceipt = 0
            this.exchange.base = 0
            this.exchange.secondary = 0
        },
        changeClick(data){
            let amt;
            let receipt
            switch(data) {
                case 'Clear':
                    this.amtReceipt = 0
                    break;
                case '5$' :
                    amt = 5;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '10$' :
                    amt = 10;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '15$' :
                    amt = 15;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '20$' :
                    amt = 20;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '25$' :
                    amt = 25;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '30$' :
                    amt = 30;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '35$' :
                    amt = 35;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '40$' :
                    amt = 40;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '45$' :
                    amt = 45;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '50$' :
                    amt = 50;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '55$' :
                    amt = 55;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '60$' :
                    amt = 60;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '70$' :
                    amt = 70;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '80$' :
                    amt = 80;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '90$' :
                    amt = 90;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '100$' :
                    amt = 100;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.usdPaid()
                    break;
                case '100r' :
                    amt = 100;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '500r' :
                    amt = 500;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '1000r' :
                    amt = 1000;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '5000r' :
                    amt = 5000;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '10000r' :
                    amt = 10000;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '20000r' :
                    amt = 20000;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '50000r' :
                    amt = 50000;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '100000r' :
                    amt = 100000;
                    receipt = parseFloat(this.amtReceipt);
                    this.amtReceipt = amt + receipt;
                    this.khrPaid()
                    break;
                case '.' :
                    if(this.amtReceipt.toString().length > 0){
                        this.amtReceipt = this.amtReceipt.toString() + ".";
                    }
                    break;
                default:
                    amt = "";
                    if(this.amtReceipt.toString() === "0"){
                        amt = "";
                    }else{
                        amt = this.amtReceipt;
                    }
                    this.amtReceipt = amt.toString() + data;
                    break;
            }
        },
        khrPaid(){
            let amt = parseFloat(this.amtReceipt)
            let khRate = 1
            if(this.baseRate.code == 'KHR'){
                khRate = parseFloat(this.baseRate.rate)
                this.selectRate = this.baseRate
            }
            if(this.secondRate.code == 'KHR'){
                khRate = parseFloat(this.secondRate.rate)
                this.selectRate = this.secondRate
            }
            let total = khRate > 0 ? (amt / khRate) : (amt * khRate)
            
            if(parseFloat(this.t.total) < total){
                let t = total - parseFloat(this.t.total)
                this.exchange.base = t
                if(this.isSecondRate){
                    let st = t * parseFloat(this.secondRate.rate)
                    this.exchange.secondary = st
                }
            }
            // window.console.log(total, amt, khRate, this.t.total, this.exchange, 'khrPaid')
            this.t.amtReceipt = this.amtReceipt
        },
        usdPaid(){
            let amt = parseFloat(this.amtReceipt)
            let r = 1
            if(this.baseRate.code == 'USD'){
                r = parseFloat(this.baseRate.rate)
                this.selectRate = this.baseRate
            }
            if(this.secondRate.code == 'USD'){
                r = parseFloat(this.secondRate.rate)
                this.selectRate = this.secondRate
            }
            let total = r > 0 ? (amt / r) : (amt * r)
            
            if(parseFloat(this.t.total) < total){
                let t = total - parseFloat(this.t.total)
                this.exchange.base = t
                if(this.isSecondRate){
                    let st = t * parseFloat(this.secondRate.rate)
                    this.exchange.secondary = st
                }
            }
            this.t.amtReceipt = this.amtReceipt
        },
        createOrderNumber(){
            let local = localStorage.getItem(instituteId + this.register.pk + 'ordernumber')
            // window.console.log(local, 'order number')
            if(local == null || local == ''){
                this.loadOrderNumber()
            }else{
                this.setOrderNumber(local)
            }
        },
        setOrderNumber(d){
            let num = JSON.parse(d)
            // window.console.log(this.orderNumberFormat, num, 'order number format')
            let o = this.orderNumberFormat
            if(parseInt(num.number) >= parseInt(o.resetOn)){
                num = 1
            }
            let isReset = false
            if(o.resetType == 'Day'){
                // window.console.log('Day')
                let sd = new Date().toISOString().substr(0, 10)
                if(sd != num.date){
                    num = 1
                    isReset = true
                }
            }else if(o.resetType == 'Month'){
                let md = new Date(num.date).getMonth()
                let mtd = new Date().getMonth()
                if(md != mtd){
                    num = 1
                    isReset = true
                }
            }else if(o.resetType == 'Year'){
                let yd = new Date(num.date).getFullYear()
                let ytd = new Date().getFullYear()
                if(yd != ytd){
                    num = 1
                    isReset = true
                }
            }
            if(isReset){
                let d = {
                    number: 1,
                    date: new Date().toISOString().substr(0, 10)
                }
                localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                num = d
            }
            let len = parseInt(o.format)
            if(num.number == null) num.number = 1
            let n = this.zeroPad(num.number, len)
            this.t.lastOrderNum = num.number
            this.orderNumber = o.abbr + o.numberSeparator + n
            this.t.orderNumber = this.orderNumber
        },
        loadOrderNumber(){
            this.showLoading = true
            commerceHandler.orderNumberGet(this.register.pk).then(res => {
                this.showLoading = false 
                window.console.log(res, 'ordernumber load from server')
                let d = {
                    number: res.data.data,
                    date: new Date().toISOString().substr(0, 10)
                }
                localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                this.setOrderNumber(JSON.stringify(d))
            })
        },
        //txn number
        async loadTxnNumber(){
            this.showLoading = true
            commerceHandler.txnNumberGet(this.activeStore.pk).then(res => {
                this.showLoading = false 
                // window.console.log(res, 'res')
                this.setTxnNumber(res.data.data)
            })
        },
        setTxnNumber(num){
            let dataItem = this.txnNumberFormat
            const year = new Date().getFullYear();
            const shortMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
            if (dataItem.sequence === 0) {
                dataItem.suffix = year;
            } else if (dataItem.sequence === 1) {
                dataItem.suffix = year.toString().substr(2);
            } else if (dataItem.sequence === 2) {
                dataItem.suffix = year + shortMonth;
            } else if (dataItem.sequence === 3) {
                dataItem.suffix = year.toString().substr(2) + shortMonth;
            } else if (dataItem.sequence === 4) {
                dataItem.suffix = "";
            }
            const preview = dataItem.abbr + dataItem.prefixSeparator + dataItem.suffix +  dataItem.numberSeparator + "0".repeat(dataItem.format - dataItem.number.toString().length) + num;
            this.t.txnNumber = preview 
            this.t.lastNumber = num
            // window.console.log(this.t, 'number')
        },
        zeroPad(num, places) {
            return String(num).padStart(places, "0");
        },
        printHtml(){
            let styleTemplate = `<style>
                * {
                    padding: 0;
                    margin: 0;
                    font-size: .98em;
                    font-family: 'Hanuman', Arial;
                    color: #000!important;
                }
                body {
                    background-color: #fff!important;
                    background: #fff!important;
                }
                #invoiceContent {
                    padding: 2.5%;
                    width: 95%;
                }
                .col-4{
                    float: left;
                    width: 33%;
                    min-height: 12em;
                }
                .col-8{
                    float: left;
                    width: 66%;
                    min-height: 12em;
                }
                h2.mt-4 {
                    margin-top: 16px;
                    font-size: 2em;
                    margin-bottom: 16px;
                }
                .col-6{
                    float: left;
                    width: 48%;
                    margin-top: 20px;
                }
                .itemlist {
                    min-height: 550px;
                }
                .col-6-right {
                    float: right;
                }
                .col-12{
                    float: left;
                    width: 100%;
                    margin-top: 20px;
                }
                th, td.tb_receipt{
                    border: 1px solid #000;
                    padding: 20px;
                    background-color: #eee!important;
                    background: #eee!important;
                    font-size: 1em;
                }
                td.tb_receipt{
                    font-size: .98em;
                    background-color: #fff!important;
                    background: #fff!important;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                .text-right {
                    text-align: right;
                }
                .covertotal {
                    padding-top: 20px;
                    border-top: 1px solid;
                }
                .covertotal td {
                    font-size: 1.2em;
                    padding: 3px;
                }
                .powerbanhji {
                    margin: 10px;
                    font-size: 1.5em;
                    text-align: center;
                }
            </style>`
            window.console.log(this.g.receiptTemplate, 'receipt template on sale page')
            if(this.g.receiptTemplate == '80mm'){
                styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 12px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    img.logo {
                        width: 150px;
                        height: auto;
                    }
                    .vat{
                        width: 50%;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 100%;
                        min-height: 160px;
                        text-align: center;
                        border: none!important;
                    }
                    .col-8{
                        float: left;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                    h2.mt-4 {
                        font-size: 16px;
                        text-align: center;
                        background: #eee!important;
                        padding: 5px;
                    }
                    .col-6{
                        float: left;
                        width: 100%;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 5px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 11px;
                    }
                    td.tb_receipt{
                        font-size: 12px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 12px;
                    }
                    .powerbanhji {
                        margin: 5px;
                        font-size: 16px;
                        text-align: center;
                    }
                </style>`
            }else if(this.g.receiptTemplate == '58mm'){
                styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    img.logo {
                        width: 80px;
                        height: auto;
                    }
                    .vat{
                        width: 50%;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 1%;
                        width: 98%;
                    }
                    .col-4{
                        float: left;
                        width: 100%;
                        min-height: 160px;
                        text-align: center;
                        border: none!important;
                    }
                    .col-8{
                        float: left;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                    h2.mt-4 {
                        font-size: 14px;
                        text-align: center;
                        background: #eee!important;
                        padding: 2px;
                    }
                    .col-6{
                        float: left;
                        width: 100%;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 2px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 10px;
                    }
                    td.tb_receipt{
                        font-size: 11px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 5px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 10px;
                    }
                    .powerbanhji {
                        margin: 5px;
                        font-size: 14px;
                        text-align: center;
                    }
                </style>`
            }else if(this.g.receiptTemplate == 'A5'){
                styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 11px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    img.logo {
                        width: 100px;
                        height: auto;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    .vat {
                        width: 40%;
                    }
                    #invoiceContent {
                        padding: 1%;
                        width: 98%;
                    }
                    .col-4{
                        float: left;
                        width: 25%;
                        min-height: 130px;
                    }
                    .col-8{
                        float: left;
                        width: 75%;
                        min-height: 130px;
                    }
                    h2.mt-4 {
                        font-size: 18px;
                        margin-bottom: 14px;
                    }
                    .col-6{
                        float: left;
                        width: 48%;
                        margin-top: 15px;
                    }
                    .itemlist {
                        min-height: 280px;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                        margin-top: 15px;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 10px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 13px;
                    }
                    td.tb_receipt{
                        font-size: 12px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 10px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 12px;
                    }
                    .powerbanhji {
                        margin: 10px;
                        font-size: 16px;
                        text-align: center;
                    }
                </style>`
            }
            var  Win;
            Win = window.open('', '', 'width=1048, height=900');
            var printableContent = '',
                win = Win,
                doc = win.document.open();
            var htmlStart = 
            '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
            '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
            '<link href="https://fonts.googleapis.com/css2?family=Hanuman:wght@100;400;900&display=swap" rel="stylesheet">' +
            '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
            styleTemplate +
            '</head><body><div class="row-fluid" >' +
            '<div id="invoicecontent" style="background: none!important;color: #000!important;" class="k-content document-body">';
            var htmlEnd = '</div></div></body></html>';
            printableContent = $('#invoiceContent').html();
            // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
            doc.write(htmlStart + printableContent + htmlEnd);
            doc.close();
            setTimeout(function(){
                // win.onafterprint = function(){win.close();}
                win.print();
                setTimeout(function(){
                    win.close();
                },6000)
                // win.close();
                // Win.onafterprint = function(){
                //     alert('printed');
                //     Win.close();
                // }
            },1000);
        },
        payByCash(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.dialogCash = false
                return
            }
            this.isSale = 1
            if(!this.isServing){
                this.t.issuedDate = new Date()
            }
            this.t.paidBy = 'Cash'
            this.t.itemLines = this.lineDS
            this.t.customer = {
                id: this.g.defaultCustomer.id,
                name: this.g.defaultCustomer.name
            }
            this.createPrintReceipt(this.t)
            this.dialogCash = true
        },
        enterCardPay(){
            if(this.cardPay.digit == ''){
                this.$snotify.error(i18n.t('please_input_4_digits'))
                return
            }
            if(this.cardPay.type == ''){
                this.$snotify.error(i18n.t('please_select_type_of_card'))
                return
            }
            if(this.cardPay.name == ''){
                this.$snotify.error(i18n.t('please_select_a_bank'))
                return
            }
            this.t.cardPay = this.cardPay
            // bill date
            this.t.billDate = new Date()
            this.dialogCardPayment = false
            this.saveTxn()
        },
        payByCard(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.dialogCard = false
                return
            }
            this.isSale = 1
            this.t.amtReceipt = this.t.total
            this.t.issuedDate = new Date()
            this.t.paidBy = 'Card'
            this.t.itemLines = this.lineDS
            this.t.customer = {
                id: this.g.defaultCustomer.id,
                name: this.g.defaultCustomer.name
            }
            this.createPrintReceipt(this.t)
            this.dialogCard = true
        },
        payByBank(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.dialogBank = false
                return
            }
            this.isSale = 1
            this.t.amtReceipt = this.t.total
            this.t.issuedDate = new Date()
            this.t.paidBy = 'Bank'
            this.activePayBank = {}
            this.t.itemLines = this.lineDS
            this.t.customer = {
                id: this.g.defaultCustomer.id,
                name: this.g.defaultCustomer.name
            }
            this.createPrintReceipt(this.t)
            this.dialogBank = true
        },
        selectBank(itm){
            this.activePayBank = {
                bankName: itm.bank.name,
                accountName: itm.accountName,
                accountNumber: itm.accountNumber,
                refNum: ''
            }
            window.console.log(this.activePayBank)
            this.infoBank = true
        },
        enterBank(){
            if(this.activePayBank.refNum == ''){
                this.$snotify.error(i18n.t('please_fill_reference_number'))
                return;
            }
            this.t.bankPay = this.activePayBank
            this.dialogBank = false
            this.infoBank = false
            // bill date
            this.t.billDate = new Date()
            this.saveTxn()
        },
        payByKHQR(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.dialogQrcode = false
                return
            }
            this.isSale = 1
            this.t.amtReceipt = this.t.total
            this.t.issuedDate = new Date()
            this.t.paidBy = 'KHQR'
            this.t.itemLines = this.lineDS
            this.t.customer = {
                id: this.g.defaultCustomer.id,
                name: this.g.defaultCustomer.name
            }
            this.createPrintReceipt(this.t)
            this.dialogQrcode = true
        },
        payByPartner(){
            if(this.lineDS.length == 0){
                this.$snotify.error(i18n.t('item_required'))
                this.dialogQrcode = false
                return
            }
            if(this.partners.length > 0){
                this.dialogPartner = true
            }else{
                this.$snotify.error(i18n.t('no_partner'))
            }
        },
        resetPaymentModule(){
            this.allowPayCash = false
            this.allowPayCard = false
            this.allowPayBank = false 
            this.allowPayKHQR = false
            // check setting
            this.g.paymentOption.forEach(p=> {
                if(p.name == 'Cash'){
                    this.allowPayCash = true
                }
                if(p.name == 'Card'){
                    this.allowPayCard = true
                }
                if(p.name == 'Bank'){
                    this.allowPayBank = true
                }
                if(p.name == 'KHQR'){
                    this.allowPayKHQR = true
                }
            })
        },
        resetOrder(){
            this.dialogCashReconcile = false
            this.dialogSaleUnitList = false
            this.isProcessOrder = false
            this.dialogLoyalSearch = false
            this.dialogRewardInfo = false
            this.dialogLoyalty = false
            this.pointCardPay = false
            this.payInvoice = false
            // partner
            this.isPartnerPay = false
            // loyalty
            this.hasLoyaltyCard = false
            this.loyaltyCustomer = {}
            //session
            this.isEndShift = false
            this.resetPaymentModule()
            this.dialogCash = false
            this.dialogCard = false
            this.dialogBank = false 
            this.dialogQrcode = false 
            this.lineDS = []
            this.calculateTotal()
            this.saleUnit = {}
            this.activePin = {}
            this.activePartner = {}
            this.partnerRefNum = ''
            this.activeReward = {}
            this.g = new generalSettingModel(JSON.parse(localStorage.getItem(instituteId + 'commRSetting')))
            this.setItemFlow()
            this.customer = new CustomerModel(this.g.defaultCustomer)
            // this.startFlowAt = 0
            // this.checkOrderFlow()
            this.t = new PosTransactionModel({})
            this.resetReceipt()
            this.resetGuest()
            this.createOrderNumber()
            this.resetCardPay()
            // this.loadTxnNumber()
            this.isServing = false
            this.activeOrderType = {}
            // this.dialogPrint = false
            this.dialogPayment = false
            this.dialogServingLine = false
        },
        startOrder(){
            this.startFlowAt = 0
            this.checkOrderFlow()
            this.isProcessOrder = true
            $('#tabProduct').click()
        },
        resetCardPay(){
            this.cardPay = {
                name: '',
                type: '',
                expireDate: new Date().toISOString().substr(0, 10),
                digit: ''
            }
        },
        resetGuest(){
            this.guestCount.localMen = 0
            this.guestCount.localWomen = 0
            this.guestCount.forMen = 0
            this.guestCount.forWomen = 0
        },
        setLocalInvoice(data){
            //  window.console.log(data, 'local serving')
            // window.console.log(data, 'add idxdb')
            if(loadIndex){
                const t = db.transaction(instituteId + "invoices", "readwrite");
                const objectStore = t.objectStore(instituteId + "invoices");
                objectStore.add(data);
                setTimeout(() => {
                    this.bindInvoice()
                }, 300);
            }else{
                setTimeout(() => {
                    this.setLocalInvoice(data)
                }, 300);
            }
        },
        setLocalServing(data){
            window.console.log(data, 'local serving')
            // window.console.log(data, 'add idxdb')
            if(loadIndex){
                const t = db.transaction(instituteId + "serving", "readwrite");
                const objectStore = t.objectStore(instituteId + "serving");
                objectStore.add(data);
                setTimeout(() => {
                    this.bindServing()
                }, 300);
            }else{
                setTimeout(() => {
                    this.setLocalServing(data)
                }, 300);
            }
        },
        setLocalPreOrder(data){
            if(loadIndex){
                const t = db.transaction(instituteId + "preorders", "readwrite");
                const objectStore = t.objectStore(instituteId + "preorders");
                objectStore.add(data);
                setTimeout(() => {
                    this.bindPreOrder()
                }, 300);
            }else{
                setTimeout(() => {
                    this.setLocalPreOrder(data)
                }, 300);
            }
        },
        saveInternal(){
            this.isSale = 0
            this.t.issuedDate = new Date()
            // bill date
            this.t.billDate = new Date()
            this.t.type = 'internal'
            this.saveTxn()
        },
        saveInvoice(){
            this.isSale = 0 
            this.t.issuedDate = new Date()
            this.t.type = 'invoice'
            this.saveTxn()
        },
        saveServing(){
            this.isSale = 0 
            this.t.orderDate = new Date()
            this.t.issuedDate = new Date()
            this.t.type = 'serving'
            this.saveTxn()
        },
        savePreOrder(){
            this.isSale = 1 
            this.t.amtReceipt = this.t.total
            this.t.billDate = new Date()
            this.t.orderDate = new Date()
            this.t.issuedDate = new Date()
            this.t.type = 'preorder'
            this.t.itemLines = this.lineDS
            this.t.customer = {
                id: this.g.defaultCustomer.id,
                name: this.g.defaultCustomer.name
            }
            this.createPrintReceipt(this.t)
            this.phoneNumber = ''
            this.dailogPhone = true
        },
        //campaign
        loadCampaign(){
            this.campaigns = [];
            this.showLoading = true;
            commerceHandler.campaignGets().then((res) => {
                if (res.data.statusCode === 200) {
                    this.showLoading = false;
                    this.campaigns = res.data.data;
                    this.addCampaign()
                }
                this.loadCam = true
                this.checkPullDataComplete()
            }).catch((e) => {
                this.showLoading = false
                window.console.error(e);
            })
        },
        addCampaign(){
            if(isIndDB){
                let t = db.transaction(instituteId + "campaign", "readwrite");
                let i = t.objectStore(instituteId + "campaign");
                let j = 0
                this.campaigns.forEach(e => {
                    i.put({
                        id: e.pk,
                        ...e
                    })
                    j++
                    if(j == this.campaigns.length){
                        window.console.log(i, 'bind campaign')
                    }
                })
            }
        },
        // session
        showEndSession(){
            this.isCountCash = false
            this.showLoading = true 
            this.dialogCloseSession = false
            commerceHandler.sessionGet(this.session.pk).then(res=>{
                this.showLoading = false 
                if(res.data.data.length > 0){
                    this.activeSession = res.data.data 
                    this.dialogCloseSession = true
                }
            })
        },
        printShift(d){
            window.console.log(d, 'print shift')
        },
        closeEndShift(){
            this.dialogShiftInfo = false
            this.pinActivate = false
            this.isEndShift = false
            this.dialogCashReconcile = false
            this.showEndSession()
        },
        showEndShift(d){
            window.console.log(this.g, d, 'end shfit')
            if(d.shifts.length >= this.register.shifts){
                this.$snotify.error(i18n.t('no_shift'))
                return
            }
            if(this.servings.length > 0){
                if(this.g.sessionForm.clearServe == 1 || this.g.sessionForm.clearServe == true){
                    this.$snotify.error(i18n.t('please_clear_serving'))
                    return
                }
            }
            let st = d.startDate
            if(d.shifts.length > 0){
                st = d.shifts[d.shifts.length - 1].date
            }
            this.shiftObj = {
                id: 'comm-shift-' + uuid.v1(),
                shiftNum: d.shifts.length + 1,
                startDate: kendo.toString(new Date(st), 'dd/MMM/yyyy hh:mm: tt'),
                endDate: kendo.toString(new Date(), 'dd/MMM/yyyy hh:mm: tt'),
            }
            this.dialogShiftInfo = true
        },
        endShift(){
            this.isEndShift = true
            // if(this.g.shiftBy == 1 || this.g.shiftBy == true){
                this.pinActivate = true
            // }else{
            //     // this.loadTxnByDate()
            //     let countObj = this.shiftObj 
            //     countObj.secondRate = this.secondRate
            //     countObj.baseRate = this.baseRate
            //     countObj.currencies = this.currencies
            //     countObj.hasKHR = this.hasKHR
            //     countObj.hasUSD = this.hasUSD
            //     countObj.isShift = true
            //     this.countObj = countObj
            //     this.$emit("onUpdate", countObj);
            //     this.dialogCashReconcile = true
            // }
        },
        saveEndShift(){
            this.showLoading = true
            if(this.session.hasOwnProperty('shifts')){
                this.session.shifts.push({
                    endBy: this.activePin.name,
                    date: new Date().getTime()
                })
            }else{
                this.session.shifts = []
                this.session.shifts.push({
                    endBy: this.activePin.name,
                    date: new Date().getTime()
                })
            }
            commerceHandler.sessionCreate(this.session).then(() => {
                this.showLoading = false
                // window.console.log(response, 'shift res')
                this.$snotify.success(i18n.t('successfull'))
                this.closeEndShift()
                localStorage.setItem(instituteId + 'commSession', JSON.stringify(this.session))
            })
        },
        showEndDay(){
            this.dialogPrintSession = false
            if(this.servings.length > 0){
                if(this.g.sessionForm.clearServe == 1 || this.g.sessionForm.clearServe == true){
                    this.$snotify.error(i18n.t('please_clear_serving'))
                    return
                }
            }
            this.session.endDate = new Date().getTime()
            this.session.isEnd = 1
            this.session.closeBy = this.activePin.name
            this.session.status = 'close'
            // window.console.log(this.session, 'session')
            this.dialogSessionInfo = true
        },
        endDay(){
            this.isEndSession = true
            this.pinActivate = true
        },
        endDayCountCash(){
            this.isCountCash = true
            this.pinActivate = true
            // this.dialogCashReconcile = true
        },
        loadCashCount(){
            this.showLoading = true
            let data = {
                sk: this.session.pk,
                pk: 'ses-cashcount-'
            }
            commerceHandler.genGets(data).then(res=>{
                this.showLoading = false
                window.console.log(res, 'cash count res')
                let notes = []
                let info = {}
                let d = res.data.data.Items
                if(d.length > 0){
                    notes = d[0].notes
                    info = d[0].info
                }
                let countObj = this.session
                    countObj.secondRate = this.secondRate
                    countObj.baseRate = this.baseRate
                    countObj.currencies = this.currencies
                    countObj.hasKHR = this.hasKHR
                    countObj.hasUSD = this.hasUSD
                    countObj.isShift = true
                    countObj.countBy = this.activePin.name
                    countObj.notes = notes
                    countObj.info = info
                this.countObj = countObj
                this.$emit("onUpdate", this.countObj);
                this.dialogCashReconcile = true
            })
        },
        saveSession(){
            this.showLoading = true
            this.session.endDate = new Date().getTime()
            this.session.isEnd = 1
            this.session.closeBy = this.activePin.name
            this.session.status = 'close'
            commerceHandler.sessionCreate(this.session).then(() => {
                this.showLoading = false
                // window.console.log(response, 'shift res')
                this.$snotify.success(i18n.t('successfull'))
                this.closeEndSes()
                localStorage.setItem(instituteId + 'commSession', JSON.stringify(this.session))
                setTimeout(()=>{
                    this.$emit("onUpdate", this.session);
                    this.dialogPrintSession = true
                }, 300)
                // this.$router.push({
                //     name: 'Print Session',
                //     params: { id: this.session.pk }
                // })
                // window.console.log(this.session)
            })
        },
        closeEndSes(){
            this.pinActivate = false
            this.dialogSessionInfo = false
        },
        // update sale unit
        updateSaleUnit(id, status){
            let salunits = []
            this.saleUnitData.forEach(d=>{
                if(d.id == id){
                    d.status = status
                }
                salunits.push(d)
            })
            window.console.log(salunits, 'update sale unit')
            localStorage.setItem(instituteId + 'saleUnit', JSON.stringify(salunits))
        },
        bindSaleUnitServing(){
            if(this.servings.length > 0 && this.saleUnitData.length > 0){
                // this.saleUnitData.forEach(sd=>{
                //     sd.status = sd.status || 1
                // })
                window.console.log(this.servings, this.saleUnitData, 'bind serving')
                this.servings.forEach(s=>{
                    if(Object.keys(s.saleUnit).length > 0){
                        this.updateSaleUnit(s.saleUnit.id, 0)
                    }
                })
            }
        },
        // get order
        loadOrder(key){
            this.txnorders = []
            let data = {
                gsi2: this.register.pk + '#order#1#',
                key: key
            }
            commerceHandler.getGsi2(data).then(res=>{
                if(res.data.data.Items.length > 0){
                    this.bindOrder(res.data.data.Items)
                    if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                        this.loadOrder(res.data.data.LastEvaluatedKey)
                    }
                }
            })
        },
        bindOrder(data){
            data.forEach(d=>{
                this.txnorders.push(d)
            })
        },
        // get data server
        loadServerData(){
            // load order
            this.loadOrder()
        },
        // save 
        async saveTxn(){
            // condition is sale
            if(this.isSale == 1){
                if(parseFloat(this.t.amtReceipt) < parseFloat(this.t.total)){
                    this.$snotify.error(i18n.t('amount_receipt_must_over_amount_pay'))
                    return
                }
            }
            // set customer
            this.t.customer = {
                id: this.g.defaultCustomer.id,
                name: this.g.defaultCustomer.name
            }
            // order number
            this.t.orderNumber = this.orderNumber
            // item line
            let line = []
            this.lineDS.forEach(itm=>{
                line.push({
                    id: itm.id,
                    name: itm.name,
                    otherName: itm.otherName ? itm.otherName : '',
                    number: itm.number,
                    description: itm.description,
                    amount: itm.amount,
                    price: itm.price,
                    qty: itm.qty,
                    uom: {
                        id: itm.uom.id,
                        price: itm.uom.price,
                        conversionRate: itm.uom.conversionRate,
                        name: itm.uom.toUOM.name
                    },
                    itemId: itm.itemId,
                    discount: itm.discount,
                    tax: itm.tax,
                    categoryId: itm.categoryId,
                    groupId: itm.groupId,
                    modifierAmt: itm.modifierAmt,
                    modifiers: itm.modifiers,
                    employees: itm.employees,
                })
            })
            if(line.length <= 0){
                this.$snotify.error(i18n.t('item_required'))
                return
            }
            this.t.itemLines = line //JSON.stringify(line)
            // price level
            this.t.priceLevel = this.g.defaultPriceLevel
            // register
            this.t.register = this.register.pk
            // session
            this.t.session = {
                id: this.session.pk,
                name: this.session.name,
                number: this.session.number
            }
            // store
            this.t.store =  this.activeStore.pk
            // exchange
            this.t.exchange = this.exchange
            // exchange rate
            this.t.exchangeRate = this.secondRate.rate + ' ' + this.secondRate.code
            this.t.isSale = this.isSale
            // warehoue
            this.t.warehouseId = this.activeStore.warehouseId
            // phone number
            if(this.phoneNumber != ''){
                this.t.phoneNumber = this.phoneNumber
            }
            // partner
            if(this.isPartnerPay){
                let pBy = this.t.paidBy
                if(this.activePartner.settlementMethod == 'credit'){
                    pBy = this.activePartner.settlementMethod
                }
                this.t.paidBy = this.activePartner.name + ' (' + pBy + ')'
                let p = this.activePartner
                this.t.customer = {
                    id: p.pk,
                    name: p.name,
                }
            }
            // loyalty
            if(this.hasLoyaltyCard){
                this.t.customer = this.loyaltyCustomer
            }
            //
            if(this.t.type == 'sale'){
                this.t.billDate = new Date()
            }
            if(this.t.type != 'serving'){
                await this.loadTxnNumber()
            }
            this.showLoadingTxn = true
            commerceHandler.txnCreate(new PosTransactionModel(this.t)).then(res=>{
                this.showLoadingTxn = false
                let printInv = true
                this.printObj = res.data.data
                this.createPrintReceipt(this.printObj)
                // set point
                if(Object.keys(this.t.pointEarn).length > 0){
                    this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'earn')
                }
                if(Object.keys(this.t.pointPaid).length > 0){
                    this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'paid')
                }
                // set local invoice
                if(this.t.type == 'invoice'){
                    if(this.isPartnerPay){
                        this.setLocalInvoice(res.data.data)
                    }
                    printInv = false
                    this.dialogPrint = true
                }else if(this.t.type == 'serving'){
                    this.setLocalServing(res.data.data)
                    printInv = false
                }else if(this.t.type == 'preorder'){
                    this.setLocalPreOrder(res.data.data)
                    printInv = false
                    this.dialogPrint = true
                }else if(this.t.type == 'internal'){
                    // this.setLocalPreOrder(res.data.data)
                    printInv = false
                    this.dialogPrint = true
                }
                // set sale unit
                if(Object.keys(this.t.saleUnit).length > 0 && this.t.type == 'serving'){
                    window.console.log('in serving process')
                    this.updateSaleUnit(this.t.saleUnit.id, 0)
                }
                if(this.isServing){
                    this.delIndxdb(this.activeServing.pk, 'serving', 'serving')
                    const sdstatus = this.g.saleUnitStatus || 1
                    this.updateSaleUnit(this.t.saleUnit.id, sdstatus)
                }
                if(this.payInvoice){
                    this.delIndxdb(this.activeInvoice.pk, 'invoices', 'invoice')
                }
                let d = {
                    number: parseInt(this.t.lastOrderNum) + 1,
                    date: new Date().toISOString().substr(0, 10)
                }
                localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                if(printInv) this.printHtml()
                this.$snotify.success(i18n.t('successfull'))
                this.resetOrder()
                this.loadServerData()
            })
            // window.console.log(this.t)
        },
        viewCard(){
            this.viewCard = false
        }
    },
    components: {
        LoadingMe: () => import(`@/components/Loading`),
        // Drag,
        // DropList,
        'app-datepicker': DatePickerComponent,
        PrintReceipt: () => import("./PrintReceipt"),
        CashCount: () => import("./CashCount"),
        PrintSession: () => import("./PrintSession"),
        PreOrder: () => import("./PreOrder"),
        // Invoice: () => import("./Invoice"),
    },
    computed:{
        lang() {
            return "/" + i18n.locale;
        },
        cashReceiptText(){
            return i18n.t('cash_receipt')
        }
    },
    created: async function () {
        this.checkIndexDB()
        await intHandler.init().then(res=>{
            this.institute = res.data.data
            window.console.log(this.institute, 'inst res')
        })
        await this.loadSaleFormContent()
        await this.loadPin()
        await this.loadCurrency()
        // this.loadInv()
        //  get image
        intHandler.getImage(cookie.creator).then(res=>{
            // window.console.log(res, 'inst image res')
            if(res.length > 0){
                this.logoUrl = res[0].url || ''
            }
        })
    }
};
</script>
<style scoped>
    .theme--light.v-application{
        background: #fff!important;
    }
    #pin > .theme--light.v-input input {
        max-height: 32px;
        font-size: 2rem !important;
        text-align: center;
    }
    .v-image__image--cover {
        background-size: contain;
        margin-top: 5px;
    }
    .v-image__image {
        background-position: top center !important;
    }
    .b-cash:before {
        color: #ffffff;
    } 
    .btn-right .v-btn__content i{
       font-size: 40px;
    }
    .btn-right .v-btn__content{
       display: block !important;
    }
    h6{
       color: #2a2b2b;
       font-size: 0.6rem !important; 
       font-family: "Niradei-bold", serif !important;
       padding-top: 5px;
    }
    
    .btn-right{
        background-color: #ffffff !important;
        border-bottom: 1px solid;
        border-color: #e5e5e6 !important;
        width: 100%;
        height: 12% !important;
        text-align: center;
        padding: 0 3px !important;
        border-radius: 0px !important;  
    }
    .v-tab {
        justify-content: right;
        font-size: 20px;
    }

    .v-tab--active {
        background-color: rgb(255, 255, 255);
    }

    .tab_setting .v-tab--active {
        font-weight: 700;
        color: #000;
    }

    .v-tab--active {
        background-color: #ffffff !important;
        border-bottom: 4px solid #92d050;
        border-left: none;
    }

    p {
        color: rgba(0, 0, 0, 0.87);
    }
    .theme--light.v-tabs-items {
        background-color: #FFFFFF;
        height: 550px !important;
        overflow: scroll !important;
    }
    
    .sale-container{
        max-width: 1870px !important;
    }
    .theme--light.v-tabs-items {
        background-color: #ffffff00 !important;
    }
    .card-item{
        background-color: #ffffff00;
        border: 0.2px solid #dcdcdc33;
        border-radius: 0;
    }
    .catagory-btn{
        width: 100%;
        background-color: #ffffff !important;
        border: 0.2px solid #e4e4e6;
    }
    .v-btn__content{
        font-family: "Niradei-Bold", serif !important;
        display: block !important;
    }
    .btn_h2{
        font-size: 22px;
    }
    .sidebar-left{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.666667%;
        max-width: 12.666667%;
    }
    .sidebar-left2{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38.666667%;
        flex: 0 0 38.666667%;
        max-width: 38.666667%;
    }
    .sidebar-left3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7.000000%;
    flex: 0 0 7.000000%;
    max-width: 7.000000%;
    }
    .container h2 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .btn-sidebar{
        justify-content: space-between !important;
        background-color: #fff !important;
        font-family: "Niradei-bold", serif !important;
    }

    .b-payment:before {
        content: "\e91f";
        color: #ffffff;
    }
    .b-banking:before {
        content: "\e90e";
        color: #ffffff;
    }
    .btn-money {
        background-color: #969696 !important;
        color: white;
    }
    
    .calculator{
        border: 1px solid gainsboro;
    }
    .receipt-btn{
        min-width: 22% !important;
        height: 50px !important;
        padding: 10px !important;
        margin: 5px;
    }
    .apply-btn{
        min-width: 33% !important;
        height: 50px !important;
        padding: 5px !important;
        margin: 5px;
    }
    .calculator-btn{
       min-width: 18% !important;
        margin: 4px;
        padding: 0 10px !important;
    }
    .calculator-btn-1{
         min-width: 24% !important;
        height: 50px !important;
        margin: 1px;
        padding: 0 2px !important;
        font-size: 17px !important;
        letter-spacing: inherit;
        font-weight: bold;
    }
    .pay-btn{
        width: 100%;
        border: 1px solid #e0e0e0;
        height: 100% !important;
        border-radius: 0px !important;
        border-color: white !important;
    }
    .v-text-field__detail .field-pos{
        display:none !important;
    }
    .btn-funtion{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }
    .btn-funtion1{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }
    .function_content {
        padding: 0px;
        border-bottom: none !important;
        background-color: #f8f8f9;
    }
    .notification{
        background-color: #ed263a;
        width: auto;
        margin-left: 2px;
        color: #fff;

    }
    .v-btn.v-size--default {
        font-size: 1.2rem;
    }
    .b-search.b:before {
        color: #d7d3d3 !important;
    }
    /* pin */
    body {
      height: 95vh;
      background-color: #181c26 !important;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Open Sans;
    }

    body.wrong {
      -webkit-animation: bg-red 1s ease-in;
      animation: bg-red 1s ease-in;
    }

    body.correct {
      -webkit-animation: bg-green 1s ease-in;
      animation: bg-green 1s ease-in;
    }

    #inspiration {
      position: fixed;
      right: 1em;
      bottom: 1em;
    }

    #inspiration a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      color: white;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }

    #inspiration a:hover { color: #212121; }

    #inspiration p {
      margin: 0;
      padding-left: .4em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
    }
    #pin {
        background-color: #ffffff !important;
        width: 90%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: 1em; */
        border-radius: .3em;
        /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
        margin: auto;
        color: rgb(155 27 46);;
        }

    .dots {
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1em;
      padding-top: 3em;
    }

    .dot {
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.8em;
      width: 0.8em;
      height: 0.8em;
      -webkit-transform: scale3d(0.7, 0.7, 0.7);
      transform: scale3d(0.7, 0.7, 0.7);
    }

    .dot.active {
      -webkit-animation: growDot .5s ease;
      animation: growDot .5s ease;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .dot.wrong {
      -webkit-animation: wrong .9s ease;
      animation: wrong .9s ease;
    }

    .dot.correct {
      -webkit-animation: correct .9s ease;
      animation: correct .9s ease;
    }

    .cancelPin {
      width: 25%;
      margin-left: 10%;
      margin-top: 10%;
    }
    .letter_spacing{
        letter-spacing:initial;
        font-size: 14px !important;
    }

    #pin p { font-size: 1.2em; }

    .numbers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      margin: 1em 0;
    }

    .number {
      position: relative;
      width: 2.5em;
      height: 2.5em;
      margin: 0.5em;
      border-radius: 2.5em;
      border: 2px solid rgb(154 27 46);
      text-align: center;
      line-height: 2.5em;
      font-weight: 400;
      font-size: 1.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      cursor: pointer;
    }

    .number:hover { color: rgba(243, 134, 134, 0.5); }

    .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

    .number:before {
      content: "";
      position: absolute;
      left: -2px;
      width: 2.5em;
      height: 2.5em;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 2.5em;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }
    .number.grow:before {
        -webkit-animation: grow .6s ease;
        animation: grow .6s ease;
        }
    @-webkit-keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @-webkit-keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @-webkit-keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @-webkit-keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @-webkit-keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @-webkit-keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    @keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    #pin >.v-input input {
        text-align: center !important;
        font-size: 35px !important;
    }
    .td-invioce td{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .tb-pong td{
        border-bottom: none !important;
        height: 25px !important;
    }
    .th-invoice th{
      border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
    }
    .v-list-item-left {
        padding: 0 10px !important;
    }
    .theme--light.v-divider{
        border-color: rgb(0 0 0 / 0%);
    }


    /* Surface pro */
    @media only screen and (min-width : 1400px){
        .btn-funtion{
            font-size: 14px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 8px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }
        .calculator-btn{
            font-size: 14px;
        }
        
    }
   @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
    
        /** Surface Pro styles here **/
        .btn-funtion{
            font-size: 12px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 5px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 16px;
            height: 10px;
            width: 10px;
        }
        .calculator-btn{
            font-size: 12px;
        }
    }
    .angle_action {
        right: 0;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .angle_action_small {
        right: 12px;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .b-reward_s:before {
        content: "\e937";
        color: #ffffff;
        font-size: 40px;
    }
    .b-promotion:before {
        content: "\e936";
        color: #ffffff;
        font-size: 40px;
    }
    .b-count_guest:before {
        content: "\e935";
        color: #ffffff;
        font-size: 40px;
    }
    .b-note_s:before {
        content: "\e932";
        color: #ffffff;
        font-size: 40px;
    }
    .b-delivery_s:before {
        content: "\e931";
        color: #ffffff;
        font-size: 40px;
    }
    .b-parksale:before {
        content: "\e934";
        color: #ffffff;
        font-size: 35px;
    }
    .b-invoice_s:before {
        content: "\e930";
        color: #ffffff;
        font-size: 35px;
    }
    .b-refresh:before {
        content: "\e92e";
        color: #ffffff;
        font-size: 40px;
    }
    .b-loyalty:before {
        content: "\e91b";
        color: #ffffff;
    }
    .b-service:before {
        content: "\e927";
        color: #ffffff;
    }
    .v-tabs--vertical > .v-tabs-bar .v-tab {
        height: auto !important;
        -webkit-box-pack: left;
        -ms-flex-pack: left;
        justify-content: space-evenly;
        text-align: center !important;
        margin-top: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: 1px solid gainsboro;
        font-size: 14px;
    }
    .v-tabs-items {
        background-color:  #f8f8f9 !important;
    }
    .elevation-2 {
        box-shadow: none !important;
    }
</style>